import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CmsService } from '../../services/cms.service';

@Component({
  selector: 'app-refund-policy',
  templateUrl: './refund-policy.component.html',
  styleUrls: ['./refund-policy.component.scss']
})
export class RefundPolicyComponent implements OnInit {
  pageDetails: any;
  bannerpath: string;

  constructor(private cmsservices:CmsService) { }

  ngOnInit(): void {
    this.cmsservices.getRefundPage().subscribe((data:any) =>{  
      this.pageDetails =data.responsedata[0];
      this.bannerpath = `${environment.api_base_url}upload/cms_image/${this.pageDetails.page_image}`;
      });
  }

}
