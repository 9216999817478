import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HotelService {
  baseurl =environment.localapi;
  constructor(private http:HttpClient) { }

  getHotelList(roomdetails) {
    roomdetails.CheckInDate = moment(roomdetails.CheckInDate).format('DD/MM/yyyy')
    console.log(roomdetails);
    let api_url =`${this.baseurl}hotel-search/getHotelList`
    return this.http.post(api_url,roomdetails);
  }
  
  getHotelRoomList(roomdata) {
    let api_url =`${this.baseurl}hotel-search/getHotelRoomList`
    return this.http.post(api_url,roomdata);
  }
  
  getHotelRoomDetails(roomdata) {
    let api_url =`${this.baseurl}hotel-search/getHotelRoomDetails`
    return this.http.post(api_url,roomdata);
  }

  hotelBooking(bookingDetails) {
    let api_url =`${this.baseurl}hotel-search/getHotelBooking`
    return this.http.post(api_url,bookingDetails);
  }

  holdHotelBooking(bookingDetails) {
    let api_url =`${this.baseurl}hotel-search/holdHotelBooking`
    return this.http.post(api_url,bookingDetails);
  }

  generateVoucher(voucherDetails) {
    let api_url =`${this.baseurl}hotel-search/generateVoucher`
    return this.http.post(api_url,voucherDetails);
  }

  getCityList() {
    let api_url =`${this.baseurl}hotel-search/getCityList`
    return this.http.get(api_url);
  } 
 
}
