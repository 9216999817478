import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { Router } from '@angular/router';
import { ContactService } from '../../services/contact.service';
import { HotelService } from '../../services/hotel.service';

@Component({
  selector: 'app-traveller-details',
  templateUrl: './traveller-details.component.html',
  styleUrls: ['./traveller-details.component.scss']
})
export class TravellerDetailsComponent implements OnInit {
  roomDataObj: any;
  hotelRoomDetails: any;
  searchDataObj: any;
  hotelDataObj: any;
  passengerDetailForm:FormGroup;
  ipAddress: any;
  hotelListData: any;
  constructor(private fb: FormBuilder, private contactService:ContactService, private hotelService : HotelService, private router:Router) { 
    this.passengerDetailForm = fb.group({
      passengerData: fb.array([])
    });
  }

  ngOnInit(): void {
   this.hotelListData = JSON.parse(localStorage.getItem('hotelListData'));
    this.contactService.getIPAddress().subscribe((res:any) =>{
      this.ipAddress = res.ip
    });
    this.searchDataObj = JSON.parse(localStorage.getItem('userSearchData'));
    this.hotelDataObj = JSON.parse(localStorage.getItem('hotelData'));
    this.roomDataObj =  JSON.parse(localStorage.getItem('roomData'));
    this.hotelRoomDetails =  JSON.parse(localStorage.getItem('roomData'));
    this.insertPassenger(this.searchDataObj);
  }

  showStarRating(rating) {
    return Array(rating).fill(0).map((x,i)=>i);
  }

  insertPassenger(searchDataObj) {
    let travellerCount =0;
    const passengerForm = this.passengerDetailForm.get('passengerData') as FormArray;
    searchDataObj.RoomGuests.forEach(roomGuest => {
      travellerCount +=roomGuest.NoOfAdults;
      travellerCount +=roomGuest.NoOfChild;
    });
    for(let i=0; i<travellerCount;i++) {
      passengerForm.push(this.fb.group({
        Title: [],
        FirstName: [],
        Middlename:[],
        LastName:[],
        PAN:[],
        Age:[],
        PaxType:[],
        PassportNo:[],
        LeadPassenger:[],
      }))
    }
  }

  onProcessBooking() {
    console.log(this.passengerDetailForm.value.passengerData);
    this.passengerDetailForm.value.passengerData.map(passenger =>{
      if(passenger.Age >12) {
        passenger.PaxType =1;
      } else {
        passenger.PaxType =0;
      }
    });
    localStorage.setItem('passengerDetails',JSON.stringify(this.passengerDetailForm.value.passengerData));
    let holdHotelRoomDetails = {
      "ResultIndex":this.hotelDataObj.ResultIndex,
      "HotelCode": this.hotelDataObj.HotelCode,
      "HotelName":this.hotelDataObj.HotelName,
      "GuestNationality":this.searchDataObj.GuestNationality,
      "NoOfRooms": this.searchDataObj.NoOfRooms,
      "ClientReferenceNo": "0",
      "IsVoucherBooking": "true",
      "HotelRoomsDetails": [
        {
          "RoomIndex": this.roomDataObj.RoomIndex,
          "RoomTypeCode":this.roomDataObj.RoomTypeCode,
          "RoomTypeName":this.roomDataObj.RoomTypeName,
          "RatePlanCode":this.roomDataObj.RatePlanCode,
          "BedTypeCode": null,
          "Supplements": this.roomDataObj.HotelSupplements,
          "Price": {
            "CurrencyCode": this.roomDataObj.Price.CurrencyCode,
            "RoomPrice": this.roomDataObj.Price.RoomPrice,
            "Tax":this.roomDataObj.Price.Tax,
            "ExtraGuestCharge": this.roomDataObj.Price.ExtraGuestCharge,
            "ChildCharge": this.roomDataObj.Price.ChildCharge,
            "OtherCharges": this.roomDataObj.Price.OtherCharges,
            "Discount": this.roomDataObj.Price.Discount,
            "PublishedPrice": this.roomDataObj.Price.PublishedPrice,
            "PublishedPriceRoundedOff": this.roomDataObj.Price.PublishedPriceRoundedOff,
            "OfferedPrice": this.roomDataObj.Price.OfferedPrice,
            "OfferedPriceRoundedOff":this.roomDataObj.Price.OfferedPriceRoundedOff,
            "AgentCommission": this.roomDataObj.Price.AgentCommission,
            "AgentMarkUp": this.roomDataObj.Price.AgentMarkUp,
            "ServiceTax": this.roomDataObj.Price.ServiceTax,
            "TDS":this.roomDataObj.Price.TDS
          }
        }
      ],
      "EndUserIp":this.ipAddress,
      "TraceId": this.hotelListData.TraceId
    }
     
    this.hotelService.holdHotelBooking(holdHotelRoomDetails).subscribe((data:any) =>{
      console.log(data);
      if(data.responsedata.data.AvailabilityType ==="Confirm") {
        this.router.navigate(['booking-review']);
      } else {
        alert('room is not Available')
      }
    })
  }

}
