<section id="main-slider" style="padding:0 0;">
    <div class="">
               
    <div class="container">
                            <div class="slide-margin">
                            <div class="col-sm-8">
                                 <div class="search-tabs search-tabs-bg search-tabs-nobox search-tabs-lift-top">
                <div class="tabbable">
                    <ul class="nav nav-tabs" id="myTab">
                       
                       <li ><a href="#tab-5" data-toggle="tab">
                       <span>Agent Registration Form</span></a>
                        </li> 
                       
                                              
                    </ul>
                    
                    
                    <div class="tab-content">
<label>
 Please fill out the following form and press the Submit Request. We will contact you as soon as possible for inclusion in the Regencytours Pvt Ltd database. Thank You!
</label>                       
                        
                        <div class="tab-pane fade in active" id="tab-3">
                          
                            <form [formGroup] ="registerForm">
                                <div class="row">
                                <div></div>
                                    <div class="col-md-12">
                                    	<div class="col-sm-12 padding0">
                                        <div class="form-group form-group-lg form-group-icon-left">
                                        <h2>Company Details</h2>
                                           <label class="col-sm-3">Business Type: </label>
                                           <div class="col-sm-9">
                                             <div class="select-style">
															<select formControlName ="bussinesstype" class="form-control">
															<option selected="selected">Travel Agent</option>
                                                            <option>Master Partner</option>
                                                            
															</select>
														</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                     <div class="col-sm-12 padding0 margin5">
                                        <div class="form-group form-group-lg form-group-icon-left">
                                        
                                           <label class="col-sm-3">Full Name:</label>
                                           <div class="col-sm-2">
                                             <div class="select-style">
															<select formControlName ="nametitle" class="form-control">
															<option selected="selected">Title</option>
                                                            <option>Mr</option>
                                                            <option>Ms</option>
                                                            
															</select>
														</div>
                                            </div>
                                            
                                            <div class="col-sm-4">
                                             <div class="select-style">
															<input type="text"formControlName ="firstname"class="form-control" placeholder="First Name">
														</div>
                                            </div>
                                            
                                            <div class="col-sm-3">
                                             <div class="select-style">
															<input formControlName ="lastname" type="text" class="form-control" placeholder="Last Name">
														</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                      <div class="col-sm-12 padding0">
                                        <div class="form-group form-group-lg form-group-icon-left">
                                       
                                           <label class="col-sm-3">Company Name:</label>
                                           <div class="col-sm-9">
                                             <div class="select-style">
															<input type="text" formControlName ="companyname"  class="form-control" placeholder="Company Name">
														</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 padding0 margin5">
                                        <div class="form-group form-group-lg form-group-icon-left">
                                       
                                           <label class="col-sm-3">PAN Number: </label>
                                           <div class="col-sm-9">
                                             <div class="select-style">
															<input type="text" formControlName ="pannumber" class="form-control" placeholder="Pan Number">
														</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 padding0">
                                        <div class="form-group form-group-lg form-group-icon-left">
                                        <h2>Communication Details</h2>
                                           <label class="col-sm-3">State: </label>
                                           <div class="col-sm-9">
                                             <div class="select-style">
															<select formControlName ="state" class="form-control">
															<option selected="selected">Selecat State</option>
                                                            
                                                            
															</select>
														</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 padding0 margin5">
                                        <div class="form-group form-group-lg form-group-icon-left">
                                       
                                           <label class="col-sm-3">City: </label>
                                           <div class="col-sm-9">
                                             <div class="select-style">
															<select formControlName ="city" class="form-control">
															<option selected="selected">Selecat City</option>
                                                            
                                                            
															</select>
														</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 padding0">
                                        <div class="form-group form-group-lg form-group-icon-left">
                                         <label class="col-sm-3">Address: </label>
                                           <div class="col-sm-9">
                                             <div class="select-style">
															<textarea formControlName ="address" class="form-control"></textarea>
														</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 padding0 margin5">
                                        <div class="form-group form-group-lg form-group-icon-left">
                                         <label class="col-sm-3">Pin/Zip Code: </label>
                                           <div class="col-sm-9">
                                             <div class="select-style">
															<input type="text" formControlName ="zipcode"  class="form-control">
														</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 padding0">
                                        <div class="form-group form-group-lg form-group-icon-left">
                                         <label class="col-sm-3">Phone: </label>
                                           <div class="col-sm-2">
                                             <div class="select-style">
															<input type="text" formControlName ="phonecode" class="form-control" placeholder="+91">
														</div>
                                            </div>
                                            
                                            <div class="col-sm-2">
                                             <div class="select-style">
															<input type="text" formControlName ="phonesdtcode" class="form-control" placeholder="SDT Code">
														</div>
                                            </div>
                                            
                                            <div class="col-sm-5">
                                             <div class="select-style">
															<input type="text" formControlName ="phonenumber" class="form-control">
														</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 padding0 margin5">
                                        <div class="form-group form-group-lg form-group-icon-left">
                                         <label class="col-sm-3">Mobile: </label>
                                           <div class="col-sm-2">
                                             <div class="select-style">
															<input type="text" formControlName ="mobilecode" class="form-control" placeholder="+91">
														</div>
                                            </div>
                                            
                                            
                                            
                                            <div class="col-sm-7">
                                             <div class="select-style">
															<input type="text" formControlName ="mobilenumber" class="form-control">
														</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 padding0">
                                        <div class="form-group form-group-lg form-group-icon-left">
                                         <label class="col-sm-3">Mobile: </label>
                                           <div class="col-sm-2">
                                             <div class="select-style">
															<input type="text" formControlName ="altmobilecode" class="form-control" placeholder="+91">
														</div>
                                            </div>
                                            
                                            
                                            
                                            <div class="col-sm-7">
                                             <div class="select-style">
															<input formControlName ="altmobilenumber" type="text" class="form-control">
														</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 padding0 margin5">
                                        <div class="form-group form-group-lg form-group-icon-left">
                                         <label class="col-sm-3">Email: </label>
                                           
                                            <div class="col-sm-9">
                                             <div class="select-style">
															<input formControlName ="email" type="text" class="form-control">
														</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 padding0">
                                        <div class="form-group form-group-lg form-group-icon-left">
                                         <label class="col-sm-3">Confirm Email: </label>
                                           
                                            <div class="col-sm-9">
                                             <div class="select-style">
															<input formControlName ="confirmemail" type="text" class="form-control">
														</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 padding0 margin5">
                                        <div class="form-group form-group-lg form-group-icon-left">
                                         <label class="col-sm-3">Fax: </label>
                                           <div class="col-sm-2">
                                             <div class="select-style">
															<input type="text" formControlName ="faxcode" class="form-control" placeholder="+91">
														</div>
                                            </div>
                                            
                                            <div class="col-sm-2">
                                             <div class="select-style">
															<input type="text" formControlName ="faxareacode" class="form-control" placeholder="Area Code">
														</div>
                                            </div>
                                            
                                            <div class="col-sm-5">
                                             <div class="select-style">
															<input type="text" formControlName ="faxnumber" lass="form-control">
														</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 padding0 ">
                                        <div class="form-group form-group-lg form-group-icon-left">
                                         <label class="col-sm-3">URL: </label>
                                           <div class="col-sm-9">
                                             <div class="select-style">
															<input type="text" formControlName ="url" class="form-control">
														</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 padding0">
                                        <div class="form-group form-group-lg form-group-icon-left">
                                        <h2>Login Details</h2>
                                           <label class="col-sm-3">User ID: </label>
                                           <div class="col-sm-9">
                                             <div class="select-style">
															<input formControlName ="userid" type="text" class="form-control">
														</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 padding0 margin5">
                                        <div class="form-group form-group-lg form-group-icon-left">
                                       
                                           <label class="col-sm-3">Password: </label>
                                           <div class="col-sm-9">
                                             <div class="select-style">
															<input type="text" formControlName ="password" class="form-control">
														</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 padding0">
                                        <div class="form-group form-group-lg form-group-icon-left">
                                       
                                           <label class="col-sm-3">Confirm Password: </label>
                                           <div class="col-sm-9">
                                             <div class="select-style">
															<input type="text" formControlName ="confirmpassword" class="form-control">
														</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 margin5">
                                    	<button class="btn btn-primary btn-lg pull-right " (click)="onRegister()" type="submit" style="margin-top:0;">Registration</button>
                                    </div>
                                    </div>
                                    
                                   
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
                            </div>
 						<div class="col-sm-4 pull-right">
                                
                                <div id="myCarousel" class="carousel slide" data-ride="carousel">
    <!-- Indicators -->
    <ol class="carousel-indicators">
      <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
      <li data-target="#myCarousel" data-slide-to="1"></li>
      <li data-target="#myCarousel" data-slide-to="2"></li>
      <li data-target="#myCarousel" data-slide-to="3"></li>
    </ol>

    <!-- Wrapper for slides -->
    <div class="carousel-inner" role="listbox">
      <div class="item active">
        <img src="assets/images/ads/ad1.png" alt="" width="100%" >
      </div>

      <div class="item">
        <img src="assets/images/ads/ad3.png" alt="" width="100%" >
      </div>
    
      <div class="item">
        <img src="assets/images/ads/ad4.png" alt="" width="100%" >
      </div>

      <div class="item">
        <img src="assets/images/ads/ad2.png" alt="" width="100%">
      </div>
    </div>

    <!-- Left and right controls -->
    <a class="left carousel-control" href="#myCarousel" role="button" data-slide="prev">
      <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="right carousel-control" href="#myCarousel" role="button" data-slide="next">
      <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
            
                            </div>
                            

                        </div>
                    </div>

    </div>
    </section>