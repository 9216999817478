import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WalletService } from '../../services/wallet.service';

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss']
})
export class MakePaymentComponent implements OnInit {
  makepaymentform:FormGroup;

  constructor(private formBuilder:FormBuilder, private walletService:WalletService) { }

  ngOnInit(): void {
    this.makepaymentform = this.formBuilder.group({
      bookingid:['',Validators.required],
      mobilenumber:['',Validators.required]
    })
  }
  get f() { return this.makepaymentform.controls; }

  
  onSubmit() {
    debugger;
    let bookingDetails ={
        "booking_id":this.makepaymentform.value.bookingid,
        "user_mobile": this.makepaymentform.value.mobilenumber
    }
    this.walletService.getTheBookingList(bookingDetails).subscribe(data =>{
      console.log(data);
    })
  }
}
