import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { setTheme } from 'ngx-bootstrap/utils';
import { VirtualTimeScheduler } from 'rxjs';
import * as moment from 'moment';
import { HotelService } from '../../services/hotel.service';
import { ContactService } from '../../services/contact.service';
import { DataService } from '../../services/data.service';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Component({
  selector: 'app-hotel-search',
  templateUrl: './hotel-search.component.html',
  styleUrls: ['./hotel-search.component.scss']
})
export class HotelSearchComponent implements OnInit {
  @ViewChild('filterInput') public userInput: ElementRef;
  @ViewChild('filterList') public filterList: ElementRef;
  isSearchClick:boolean= false;
  countryName:string = "India";
  cityName:string;
  checkInDate:string;
  firstsearchclick:boolean =false;
  hotelSearchForm:FormGroup;
  newVar:any;
  minDate: Date;
  minDateCheckOut:Date;
  newVar1:any;
  isShowGuestList: boolean =false;
  totalperson : number =2;
  groups:any = [1,2,3,4,5];
  selectedRoom = 1;

  roommodel = {
    roomnumber:1,
    selectedAdult:2,
    selectedChild:0,
    adultmaxcount:4,
    childmaxcount:3,
    childAge:[]
    }
  Rooms:any = [this.roommodel];
  selectedAdultCount: any;
  selectedChildCount: any;
  selectedRoomCount: number =1;
  isEditRoom: any;
  isAdvanceSearch: boolean =false;
  checkOutDate: any;
  numbeOfNights: any;
  searchHotelListData: any="";
  isHotelListData: boolean;
  ipAddress: any;
  resultDataList: any[];
  cityList: any;
  selectedCityCode: any;
  selectedCountryCode: any;
  selectedCity: any;
  isLoggedin: boolean;
  dateConfig :any;
  constructor(private formBuilder:FormBuilder,private hotelservice:HotelService, private cdr:ChangeDetectorRef,private contactService:ContactService, private dataService:DataService, private router:Router) { 
    this.contactService.getIPAddress().subscribe((res:any) =>{
      this.ipAddress = res.ip;
    });
    this.minDate = new Date();
    this.minDateCheckOut = new Date(Date.now() + (3600 * 1000 * 24));
    this.hotelSearchForm = this.formBuilder.group({
      destination:['',Validators.required],
      fromDate:['',Validators.required],
      toDate:['',Validators.required],
      nightsnumber:['',Validators.required],
      propertytype:[''],
      currency:[''],
      starcategroy:['']
    });
    this.hotelSearchForm.get("fromDate").valueChanges.subscribe(x => {
      console.log(new Date(x.getTime() + (3600 * 1000 * 24)));
     this.minDateCheckOut = new Date(x.getTime() + (3600 * 1000 * 24));
     if(this.hotelSearchForm.value.toDate) {
      let numberofnights = this.hotelSearchForm.value.toDate - x;
      let days = Math.ceil(numberofnights/(1000 * 3600 * 24));
      if(days>= 0) {
        this.hotelSearchForm.get("nightsnumber").setValue(days, { onlySelf: true });
      } else {
        this.hotelSearchForm.get("toDate").setValue('', { onlySelf: true });
        this.hotelSearchForm.get("nightsnumber").setValue('', { onlySelf: true });
      }
      
     }
    });

   this.hotelSearchForm.get("toDate").valueChanges.subscribe(x => {
    if(this.hotelSearchForm.value.fromDate) {
      let numberofnights =  x - this.hotelSearchForm.value.fromDate;
      let days = Math.ceil(numberofnights/(1000 * 3600 * 24));   
        this.hotelSearchForm.get("nightsnumber").setValue(days, { onlySelf: true });
     }
    });

  //  this.hotelSearchForm.get("nightsnumber").valueChanges.subscribe(x => {
  //   if(this.hotelSearchForm.value.fromDate) {
  //     let fromDate1 = new Date(this.hotelSearchForm.value.fromDate);
  //     fromDate1.setDate(fromDate1.getDate() + (Number(x)-1));
  //     this.hotelSearchForm.get("toDate").setValue(fromDate1, { onlySelf: true });
  //    }
  //  });

  }

  onShowGuest(){
    this.isShowGuestList = true;
  }

  onNightsNumberChange(value) {
    setTimeout(() =>{
      if(this.hotelSearchForm.value.fromDate && value) {
        let fromDate1 = new Date(this.hotelSearchForm.value.fromDate);
        fromDate1.setDate(fromDate1.getDate() + (Number(value)));
        this.hotelSearchForm.get("toDate").setValue(fromDate1, { onlySelf: true });
      }
    },500);    
    }
 
  onRoomSelection(room:number) {
    this.selectedRoomCount =room;
    this.Rooms = Array(Number(room)).fill('').map((_,i)=>{
      let roomdetails ={
        roomnumber:i+1,
        selectedAdult:2,
        selectedChild:0,
        adultmaxcount:4,
        childmaxcount:3,
        childAge:[]
        };
      return roomdetails;
    });
    let temperson =0;
    for(let i=0; i<this.Rooms.length; i++) {
      temperson = temperson+this.Rooms[i].selectedAdult; 
      temperson = temperson+this.Rooms[i].selectedChild;
    }
    this.totalperson = temperson;
  }
  ngOnInit(): void {
   this.dateConfig = { isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }
    this.isLoggedin = Boolean(sessionStorage.getItem('isLoggedIn'));
    this.hotelservice.getCityList().subscribe((data:any) =>{
      console.log(data.responsedata.data);
      this.cityList = data.responsedata.data;
    });
  }

  numSequenceAdult(n: number =4): Array<number> {
    return Array(n);
  }

  numSequenceChild(n: number =4): Array<number> {
    return Array(n);
  }

  numSequenceChildAge(n: number =4): Array<number> {
    return Array(n);
  }

  onAdultSelection(roomindex,adultindex) {
   this.selectedAdultCount = adultindex;
   this.Rooms[roomindex].childmaxcount=4-(adultindex+1);
   this.Rooms[roomindex].selectedAdult =adultindex+1;
   let temperson =0;
   for(let i=0; i<this.Rooms.length; i++) {
     temperson = temperson+this.Rooms[i].selectedAdult; 
     temperson = temperson+this.Rooms[i].selectedChild;
   }
   this.totalperson = temperson;
  }

  onChildSelection(roomindex, childindex) {
    this.selectedChildCount = childindex;
    this.Rooms[roomindex].adultmaxcount=4-(childindex+1);
    this.Rooms[roomindex].selectedChild =childindex+1;
    let temperson =0;
    for(let i=0; i<this.Rooms.length; i++) {
      temperson = temperson+this.Rooms[i].selectedAdult; 
      temperson = temperson+this.Rooms[i].selectedChild;
    }
    this.totalperson = temperson;
  }

  onClickChildAge(roomindex,childage,childcountindex) {
    this.Rooms[roomindex].childAge[childcountindex] =childage;
  }

  addRoom() {
    this.selectedRoomCount = Number(this.selectedRoomCount)+1;
    this.Rooms.push({
      roomnumber:this.selectedRoomCount,
      selectedAdult:2,
      selectedChild:0,
      adultmaxcount:4,
      childmaxcount:3,
      childAge:[]
      })

      let temperson =0;
    for(let i=0; i<this.Rooms.length; i++) {
      temperson = temperson+this.Rooms[i].selectedAdult; 
      temperson = temperson+this.Rooms[i].selectedChild;
    }
    this.totalperson = temperson;
    this.selectedRoom = this.Rooms.length;
  }

  removeRoom(roomindex) {
    this.selectedRoomCount = this.selectedRoomCount -1;
    this.Rooms.splice(roomindex,1);
    let temperson =0;
    for(let i=0; i<this.Rooms.length; i++) {
      temperson = temperson+this.Rooms[i].selectedAdult; 
      temperson = temperson+this.Rooms[i].selectedChild;
    }
    this.totalperson = temperson;
    this.selectedRoom = this.Rooms.length;
  }

  roomSelectionDone() {
    let temperson =0;
    for(let i=0; i<this.Rooms.length; i++) {
      temperson = temperson+this.Rooms[i].selectedAdult; 
      temperson = temperson+this.Rooms[i].selectedChild;
    }
    this.totalperson = temperson;
   this.isShowGuestList = false; 
   this.selectedRoom = this.Rooms.length;
  }

  onRoomEdit(roomindex) {
    this.isEditRoom = roomindex;
  }

  onAdvanceSearch() {
    this.isAdvanceSearch = !this.isAdvanceSearch;
  }

  searchHotelList() {
   if(!this.isLoggedin) {
     alert('first logged in before hotel search');
     return false;
   }
    this.isHotelListData = false;
    let checkInDateFormat =this.hotelSearchForm.value.fromDate;
    let checkOutDateFormat = this.hotelSearchForm.value.toDate;
    this.cityName = this.hotelSearchForm.value.destination;
    this.checkInDate = checkInDateFormat;
    // this.checkOutDate = moment(checkOutDateFormat).format('DD/MM/yyyy');
    this.checkOutDate = checkOutDateFormat;
    this.numbeOfNights = this.hotelSearchForm.value.nightsnumber;
    this.isSearchClick =true;
    this.firstsearchclick = true;
    let roomList = this.Rooms.map(room =>{
      return {"NoOfAdults":room.selectedAdult,"NoOfChild":room.selectedChild,"ChildAge":room.childAge}
    });
  let roomdetails:any ={
  "CheckInDate": checkInDateFormat,
  "NoOfNights": this.hotelSearchForm.value.nightsnumber,
  "CountryCode": this.selectedCountryCode,
  "CityId": this.selectedCityCode,
  "ResultCount": null,
  "PreferredCurrency": "INR",
  "GuestNationality": "IN",
  "NoOfRooms": this.selectedRoomCount,
  "RoomGuests":roomList,
  "PreferredHotel": "",
  "MaxRating": 5,
  "MinRating": 0,
  "ReviewScore": null,
  "IsNearBySearchAllowed": false,
  "EndUserIp": this.ipAddress,
    }
   this.dataService.userSearchData =roomdetails;
   this.dataService.userSearchData.checkOutDate = checkOutDateFormat;
   roomdetails.checkOutDate = checkOutDateFormat;
   localStorage.setItem('userSearchData',JSON.stringify(roomdetails));
   this.hotelservice.getHotelList(roomdetails).subscribe((data:any) =>{
     this.searchHotelListData = data.responsedata.data;
     localStorage.setItem('hotelListData',JSON.stringify(this.searchHotelListData));
     this.isHotelListData = true;
     console.log(data.responsedata.data);
     this.cdr.markForCheck();
   });
  }

  modifySearch(){
    debugger;
    this.isSearchClick = !this.isSearchClick;
  }

  searchQueryOnDataSource($event): void {
    if ($event.target.value.length >= 3) {
      this.resultDataList = [];

      this.enableSearch().then((dataSource) => {
        this.doQuerySearch(dataSource, $event.target.value);
      });
    } else {
      setTimeout(() => {
        const filterListHTMLElement: HTMLElement = this.filterList.nativeElement;
        filterListHTMLElement.style.backgroundColor = 'transparent';
      });

      this.resultDataList = [];
    }
  }

  enableSearch(): Promise<any> {
    return new Promise((resolve) => {
      resolve(this.cityList.map(item => Object.assign({}, item)));
    });
  }

  private doQuerySearch(dataArray: Array<any>, queryString: string): void {
    setTimeout(() => {
      const filterListHTMLElement: HTMLElement = this.filterList.nativeElement;
      filterListHTMLElement.style.backgroundColor = '#fff';
    });

    for (const element of dataArray) {
      const feature = element;
      if (feature.DESTINATION.toLowerCase().search(queryString.toLowerCase()) !== -1) {
        this.resultDataList.push(feature);
      }
    }
    console.log(this.resultDataList)
    for (const element of this.resultDataList) {
      const feature = element;
      const regex = new RegExp( '(' + queryString + ')', 'gi' );
      let updatedText: string;
      if (this.resultDataList.length > 0) {
        updatedText = feature.DESTINATION.replace(regex, '<strong>' + queryString + '</strong>');
      }
      feature.originalText = feature.DESTINATION;
      feature.DESTINATION = updatedText;
    }
  }

  selectedResult(result: any): void {
    this.userInput.nativeElement.value = result.originalText;
    this.selectedCity =result;
    localStorage.setItem('selectedCity',JSON.stringify(result));
    this.selectedCityCode =result.CITYID
    this.selectedCountryCode = result.COUNTRYCODE;
    this.resultDataList = [];
  }


  
}
