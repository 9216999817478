import { Component, OnInit } from '@angular/core';
import { CmsService } from '../../services/cms.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent implements OnInit {
  pageDetails: any;
    bannerpath:string;
  constructor(private cmsservices:CmsService) { }

  ngOnInit(): void {
    
    this.cmsservices.getCopyRightPage().subscribe((data:any) =>{  
      this.pageDetails =data.responsedata[0];
      this.bannerpath = `${environment.api_base_url}upload/cms_image/${this.pageDetails.page_image}`;
      });
     
  }

}
