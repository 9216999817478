
  <section id="feature" style="background:#ededed; margin-top:10px;" *ngIf="hotelDetail;else loadingHotel">
    <div class="container">
       <div class="left wow fadeInDown">
       <div class="col-sm-6">
            <h2 style="line-height:10px;">{{hotelDetail.HotelName}}
                <ng-container *ngFor="let i of showStarRating(hotelDetail.StarRating)" >
                    <label><img src="assets/images/icons/starnew.jpg"></label>
                  </ng-container>
                  <ng-container *ngFor="let i of showStarRating(5 -hotelDetail.StarRating)">
                       <label><img src="assets/images/icons/star_blank.jpg"></label></ng-container> 
            <span style="font-size:13px;">{{hotelDetail.Address}}</span>
            </h2>
            </div>
            <!--<div class="col-sm-6">
                <div class="col-sm-3 pull-right" style="padding-top:20px;">
                    <a href="" class="btn-primary">Choose&nbsp;Room</a>
                </div>
                <div class="col-sm-2 pull-right">
                     <h2 style="line-height:10px;"><i class="fa fa-inr"></i>1080</h2>
                </div>
            </div>-->
        </div>

        <div class="row">
            <div class="features col-sm-12">
               
                <div class="col-md-9 col-sm-11 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="600ms">
                     <div class="col-sm-12">
                     <div class="row">
                        <div class="col-sm-12 col-md-12 col-xl-12 padding5topbot" style="background:#fff; padding-top:10px;" >
    
                        <div style="padding:0 15px 10px 15px;">
                            <ul class="nav nav-tabs" style="padding:0 0 0 0; background: transparent;">
<li [ngClass]="{'active': showTab === 'photos'}" (click)="onTabClick('photos')"><a data-toggle="tab" >Photos</a></li>
<li [ngClass]="{'active': showTab === 'map'}"(click)="onTabClick('map')"><a data-toggle="tab">Map</a></li>

<li [ngClass]="{'active': showTab === 'streetView'}"(click)="onTabClick('streetView')"> <a data-toggle="tab" >Street View</a></li>
<li [ngClass]="{'active': showTab === 'agentReview'}"(click)="onTabClick('agentReview')"><a data-toggle="tab" >Agent Reviews</a></li>

<li [ngClass]="{'active': showTab === 'hotelPolicy'}"(click)="onTabClick('hotelPolicy')"><a data-toggle="tab">Hotel Policies </a></li>
</ul>

<div class="padding0" style="background:none !important; text-align:justify;">
<div *ngIf="showTab=== 'photos'" class="fade in active">
<div id="jssor_1" style="position: relative; margin: 0 auto; top: 0px; left: 0px; width: 800px; height: 456px; overflow: hidden; visibility: hidden; background-color: #24262e;">
    <!-- Loading Screen -->
    <div data-u="loading" style="position: absolute; top: 0px; left: 0px;">
        <div style="filter: alpha(opacity=70); opacity: 0.7; position: absolute; display: block; top: 0px; left: 0px; width: 100%; height: 100%;"></div>
        <div style="position:absolute;display:block;background:url('assets/img/loading.gif') no-repeat center center;top:0px;left:0px;width:100%;height:100%;"></div>
    </div>
    <div data-u="slides" style="cursor: default; position: relative; top: 0px; left: 0px; width: 800px; height: 356px; overflow: hidden;">
        <ng-container *ngFor="let image of hotelDetail.Images">
        <div data-p="144.50" style="display: none;">
            <img data-u="image" [src]="image" />
            <img data-u="thumb" [src]="image" />
        </div>   
    </ng-container>
        
    
    </div>
    <!-- Thumbnail Navigator -->
    <div data-u="thumbnavigator" class="jssort01" style="position:absolute;left:0px;bottom:0px;width:800px;height:100px;" data-autocenter="1">
        <!-- Thumbnail Item Skin Begin -->
        <div data-u="slides" style="cursor: default;">
            <div data-u="prototype" class="p">
                <div class="w">
                    <div data-u="thumbnailtemplate" class="t"></div>
                </div>
                <div class="c"></div>
            </div>
        </div>
        <!-- Thumbnail Item Skin End -->
    </div>
    <!-- Arrow Navigator -->
    <span data-u="arrowleft" class="jssora05l" style="top:158px;left:8px;width:40px;height:40px;"></span>
    <span data-u="arrowright" class="jssora05r" style="top:158px;right:8px;width:40px;height:40px;"></span>
</div>

</div>
<div *ngIf="showTab=== 'map'" >

           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d113032.64621395394!2d85.25609251320085!3d27.708942727046708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu+44600%2C+Nepal!5e0!3m2!1sen!2sin!4v1537941812283" width="100%" height="400" frameborder="0" style="border:0" allowfullscreen></iframe>
           <!-- <agm-map 
  [latitude]=51.673858
  [longitude]=7.815982
  [zoom]="zoom"
  [disableDefaultUI]="false"
  [zoomControl]="false"
  (mapClick)="mapClicked($event)">

</agm-map>
   -->
      </div>
<div *ngIf="showTab=== 'streetView'" >
  <img data-u="image" src="img/03.jpg" />
</div>

<div *ngIf="showTab=== 'agentReview'"  style="height:400px;">
 Not Data
</div>

<div *ngIf="showTab=== 'hotelPolicy'" >
  <div class="col-sm-12 col-md-12 col-xl-12" style="background:#fff; padding-top:10px; margin-top:20px;" >
                        
                        <div style="">
                            

                      <div class="accordion">
                    <div class="panel-group" id="accordion1">
                                                
                      <div class="panel panel-default" style="margin-bottom:10px;">
                        <div class="panel-heading active">
                          <h3 class="panel-title">
                            <a class="" data-toggle="collapse" data-parent="#accordion1" (click)="hotelPolicyAccordian('Amenities')">                           
                            Hotel Amenities
                            <ng-container *ngIf="openAccordianTab ==='Amenities';else hideAmenities" > 
                              <i class="fa fa-angle-down pull-left" style="line-height: 22px;"></i>
                           </ng-container> 
                           <ng-template #hideAmenities>
                             <i class="fa fa-angle-right pull-left" style="line-height: 22px;"></i>
                           </ng-template>                            
                            </a>
                          
                          </h3>
                        </div>
                        <div id="collapseTwo1" *ngIf="openAccordianTab ==='Amenities'" class="panel-collapse">
                          <div class="panel-body bordreveew">
                                   <div class="row ">
                                   
 
                                     <div class="col-sm-12 padding0" *ngFor="let facility of hotelDetail.HotelFacilities">
                                                     <div class="col-sm-3 inclusionexclu">{{facility}}</div>

                                                    
                                                 </div>

                                        <h1></h1>
                                        
                                        </div>
                            </div>
                        </div>
                      </div>

                      <div class="panel panel-default" style="margin-bottom:10px;">
                        <div class="panel-heading active">
                          <h3 class="panel-title">
                            <a class="" data-toggle="collapse" data-parent="#accordion1" (click)="hotelPolicyAccordian('Policy')" >
                        Hotel Policies
                        <ng-container *ngIf="openAccordianTab ==='Policy';else hidePolicy" > 
                          <i class="fa fa-angle-down pull-left" style="line-height: 22px;"></i>
                       </ng-container> 
                       <ng-template #hidePolicy>
                         <i class="fa fa-angle-right pull-left" style="line-height: 22px;"></i>
                       </ng-template>
                            </a>
                          </h3>
                        </div>
                        <div  *ngIf="openAccordianTab ==='Policy'" class="panel-collapse">
                          <div class="panel-body bordreveew">
                           <p> {{hotelDetail.HotelPolicy}} Policy Will be shown here</p>
                          </div>
                        </div>
                      </div>
                      
                      <div class="panel panel-default" style="margin-bottom:10px;">
                        <div class="panel-heading active" style="border-bottom:1px solid #999;">
                          <h3 class="panel-title">
                            
                            <a class=""  (click)="hotelPolicyAccordian('FAQ')"data-toggle="collapse" data-parent="#accordion1" >
                        FAQ
                       <ng-container *ngIf="openAccordianTab ==='FAQ';else hideFAQ" > 
                         <i class="fa fa-angle-down pull-left" style="line-height: 22px;"></i>
                      </ng-container> 
                      <ng-template #hideFAQ>
                        <i class="fa fa-angle-right pull-left" style="line-height: 22px;"></i>
                      </ng-template>
                            </a>
                            
                          </h3>
                        </div>
                        <div id="collapseThree2" *ngIf="openAccordianTab ==='FAQ'" class="panel-collapse">
                          <div class="panel-body bordreveew">
                           
                           <p>FAQ will be shown here</p>
                           
                           
                                                         </div>
                        </div>
                      </div>
                      
                    </div><!--/#accordion1-->
                </div> 

</div>    
  </div>
</div>

</div> 

</div>    
                            
                             
                        </div>
                        
                        <div class="col-sm-12 col-md-12 col-xl-12" style="background:#fff; padding-top:10px; margin-top:20px;" >
                        
                        <div style="">
                            

<div class="" style="background:none !important; text-align:justify;">
<div class="col-sm-6 padding0">
  <h3>Highlight</h3>
   <label class="col-sm-12 padding0" style="font-size:25px; margin-top:10px;">
                       <div  class="col-sm-3" align="center">
                                      <label class="lablecalsicon lablecalsiconfont" style="margin-bottom:0;"><i class="fa fa-wifi" aria-hidden="true"></i></label><br>

                                      <!--<span style="font-size:12px !important; color:#333;">>= 3 stars</span>-->
                                      </div>
                                      
                                     <div  class="col-sm-3" align="center">
                                     <label class="lablecalsicon lablecalsiconfont" style="margin-bottom:0;"> <i class="fa fa-cutlery" aria-hidden="true"></i></label>
                                     <br>
                                     <!--<span style="font-size:12px !important; color:#333;">Transfer</span>-->
                                     </div>
                                     <div  class="col-sm-3" align="center">
                                    <label class="lablecalsicon lablecalsiconfont" style="margin-bottom:0;" > <i class="fa fa-wifi" aria-hidden="true"></i></label><br>
                                     <!--<span style="font-size:12px !important; color:#333;">Meals</span>-->
                                    </div>
                                    <div  class="col-sm-3" align="center">
                                     <label class="lablecalsicon lablecalsiconfont" style="margin-bottom:0;" > <i class="fa fa-cutlery" aria-hidden="true"></i> </label><br>
                                     <!--<span style="font-size:12px !important; color:#333;">Sightseeing</span>-->
                                     </div>
                                     </label>
                                      
  </div>

<div class="col-sm-6 priceregency">
  <h3>Amenities</h3>
  <label class="col-sm-12 padding0" style="font-size:25px; margin-top:10px;">
                       <div  class="col-sm-3 padding0" align="center">
                                      <label class="lablecalsicon lablecalsiconfont" style="margin-bottom:0;"><i class="fa fa-glass" aria-hidden="true"></i></label><br>

                                     <!-- <span style="font-size:12px !important; color:#333;">Summer Special</span>-->
                                      </div>
                                      
                                     <div  class="col-sm-3 padding0" align="center">
                                     <label class="lablecalsicon lablecalsiconfont" style="margin-bottom:0;"> <i class="fa fa-wheelchair" aria-hidden="true"></i></label>
                                     <br>
                                    <!-- <span style="font-size:12px !important; color:#333;">Weekend Trips</span>-->
                                     </div>
                                     <div  class="col-sm-3 padding0" align="center">
                                    <label class="lablecalsicon lablecalsiconfont" style="margin-bottom:0;" > <i class="" aria-hidden="true">
                                    <img src="images/5_icon_result.png" style="width:30px; height:23px;">
                                    </i></label><br>
                                     <!--<span style="font-size:12px !important; color:#333;">Family</span>-->
                                    </div>
                                    <div  class="col-sm-3 padding0" align="center">
                                     <label class="lablecalsicon lablecalsiconfont" style="margin-bottom:0;" > <i class="fa fa-medkit " aria-hidden="true"></i> </label><br>
                                     <!--<span style="font-size:12px !important; color:#333;">Bestseller</span>-->
                                     </div>
                                     </label>
  </div>

 <div class="col-sm-12">
  <h3>Overview</h3>
  <div innerHTML="{{hotelDetail.Description}}">
  </div>


</div>
</div> 

</div>    
  </div>
  
                          <div class="col-sm-12 col-md-12 col-xl-12" style="background:#fff; padding-top:10px; margin-top:20px;" >
                        
                            

                     
                    
                            <div class="col-md-3">
                                                <div class="form-group form-group-lg form-group-icon-left form-group-filled">
                                                 <label style="color:#333;">Check In</label>
                                                    <input placeholder="Check In Date" class="form-control regencydatepicar bokingtextinput" name="from" id="from" type="text">
                                                </div>
                                            </div>
                                            
                            <div class="col-md-3">
                                                <div class="form-group form-group-lg form-group-icon-left">
                                                 <label style="color:#333;">Check Out</label>
                                                    <input  placeholder="Check Out Date" id="to" class="form-control regencydatepicar bokingtextinput" name="to" type="text">
                                                </div>
                                            </div>
                                            
                           <div class="col-md-2">
                                                <div class="form-group form-group-lg form-group-select-plus">
                                                   
                                                    <label style="color:#333;">No of Rooms</label>
                                                    
                                                    <div class="select-style">
                                                    
                                                    <select name="ctl00$cph_main$roomsNo" id="cph_main_roomsNo" size="1" class="form-control" onchange="showRooms();">
                                                        <option selected="selected" value="1">1 Rooms</option>
                                                        <option value="2">2 Rooms</option>
                                                        <option value="3">3 Rooms</option>
                                                        <option value="4">4 Rooms</option>
                                                        <option value="5">5 Rooms</option>
                                                            </select>
                                                    
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            
                                            
                           <div class="col-md-2 col-md-2modify">
                                                            <div class="form-group form-group-lg form-group-select-plus">
                                                                <label style="color:#333;">Guest</label>
                                                                <div class="select-style">
                                                                <select name="drpAdults1" id="drpAdults1" class="form-control" onchange="javascript:ShowRoomType(1)">
                                                            <option value="1">1</option>
                                                            <option value="2" selected="selected">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                                    
                                                                </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        
                          <div class="col-md-2 col-md-2modify">
                                                            <div class="form-group form-group-lg form-group-select-plus">
                                                            
                                                                <label style="color:#333;">
                                                                <br>

                                                                </label>
                                                                 
                                                                 <a href="#" class="btn-primary">Check&nbsp;Avalabilty</a>
                                                               
                                                            </div>
                                                        </div>

                    
 
  </div>
  
  
                          <div class="col-sm-12 col-md-12 col-xl-12" style="background:#fff; padding-top:10px; margin-top:20px;" >
                        
                            <div class="padding0" *ngIf="hotelRoomList">
                              <div class="table-responsive">          
<table class="table" >
<thead>
<tr style="background:#6f021a; color:#fff;">
  <th>Room Category</th>
  
   <th>Meals</th>
  <th>Offer</th>
  <th >Status </th>
  
  <th>Price</th>
      <th>T&C</th>
       <th></th>
</tr>
</thead>
<tbody>
<tr *ngFor="let hotelRoom of hotelRoomList.HotelRoomsDetails">
  <td>
  <label (click)="onRoomSelection(hotelRoom)"class="checkcontainer">{{hotelRoom.RoomTypeName}}<span class="offer-text">offer</span>
            <input type="radio" [checked]="hotelRoom?.RoomIndex === selectedHotelRoom?.RoomIndex" id="onewaycity" name="hotel-cat">
            <span class="radiobtn"></span>
          </label>
  </td>
  <td>
     HB
  </td>
 
  <td>
  <div >
    <span *ngFor="let inclusion of hotelRoom.Amenities">{{inclusion}},</span></div>
  
  </td>
  <td>         
 <div>{{hotelRoom.AvailabilityType}}</div>
  </td>
 
  <td>         
 <div (mouseenter)="showDetailPrice(hotelRoom)" (mouseleave)="hideDetailPrice(hotelRoom)">{{hotelRoom.Price.OfferedPriceRoundedOff}}</div>
 <ng-continer *ngIf="selectedRoomId === hotelRoom.RoomIndex">
 <table border="1" class="db_table">
  <tbody>
      <tr >
          <th></th>
          <th>Mon</th>
          <th>Tue</th>
          <th>Wed</th>
          <th>Thu</th>
          <th>Fri</th>
          <th>Sat</th>
          <th>Sun</th>
      </tr>
      
      <tr *ngFor="let weeksamount of chunkweekWithAmont;let i=index;">
          <th>Week {{i+1}}</th>  
          <td *ngFor="let weekamount of weeksamount">{{weekamount.amount}}</td>  
      </tr>
      
      <tr>
          <th colspan="8">Rate Summary</th>
      </tr>
      <tr>
          <td colspan="4" class="bold tboleft">Currency</td>
          <td colspan="4" id="currency_1">{{hotelRoom.Price.CurrencyCode}}</td>
      </tr>
      <tr >
          <td colspan="4" class="bold tboleft">Total</td>
          <td colspan="4" id="totalFare_1">{{hotelRoom.Price.RoomPrice}}</td>
      </tr>
      <tr >
          <td colspan="4" class="bold tboleft">Tax(+)</td>
          <td colspan="4" id="tax_1">{{hotelRoom.Price?.GST?.TaxableAmount | number : '1.2-2'+ hotelRoom.Price?.Tax | number : '1.2-2'}}</td>
      </tr>
      <tr >
          <td colspan="4" class="bold tboleft">Discount(-)</td>
          <td colspan="4" id="discount_1">{{hotelRoom.Price.Discount| number : '1.2-2'}}</td>
      </tr>
      <tr >
          <td colspan="4" class="bold tboleft">Extra Guest Charge(+)</td>
          <td colspan="4" id="extraGuestRequest_1">{{hotelRoom.Price.ExtraGuestCharge | number : '1.2-2'}}</td>
      </tr>
      <tr >
          <td colspan="4" class="bold tboleft">Service Charges(+)</td>
          <td colspan="4" id="serviceCharges_1">{{hotelRoom.Price.ServiceCharge | number : '1.2-2'}}</td>
      </tr>
      <tr >
          <td colspan="4" class="bold tboleft">No. of Rooms</td>
          <td colspan="4" id="noOfRooms_1">1</td>
      </tr>
      <tr >
          <td colspan="4" class="bold tboleft">Total Price</td>
          <td colspan="4" id="totalPrice_1">{{hotelRoom.Price.PublishedPriceRoundedOff}}</td>
      </tr>
  </tbody>
</table>
</ng-continer>
   </td>
  
 
 
  <td>         
 <div> <a (mouseenter)="showCancelationPolicy(hotelRoom)" (mouseleave)="hideCancelationPolicy(hotelRoom)">Cancellation Policy</a></div>
 <div class="cancellation_module">

 <ng-container  *ngIf="selectedRoomIdForCancel === hotelRoom.RoomIndex">
  
   <table border="1" class="db_table">
      <tbody>
          <tr>
              <th align="left">Cancelled on or After</th>
              <th align="left">Cancelled on or Before</th>
              <th align="left">Cancellation Charges</th>
          </tr>
              <tr *ngFor="let cancelCharge of hotelRoom.CancellationPolicies">
                
                      <td align="left">{{cancelCharge.FromDate | date: 'dd/MM/yyyy'}}</td>
                      <td align="left">{{cancelCharge.ToDate | date: 'dd/MM/yyyy'}}</td>
                      <td align="left">
                        {{cancelCharge.Charge}}%
                      </td>                         
              </tr>  
      </tbody>
  </table>
</ng-container>
</div>
  </td>
  <td><a (click)="showHotelReview(hotelRoom)" class="btn select_but">Book</a></td>
  
</tr>

</tbody>
</table>
</div>
                           
                           </div>
                     
 
  </div>
                        
                        
                        
                        
                     
                        </div>
                       
                    </div>
                </div>
                
                
                <div class="col-md-3 col-sm-5 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="600ms">
                        
                     <div class="col-sm-12 col-md-12 col-xl-12 margin5" style="background:#fff; color:#333;" >
                                 <h2> Summary </h2>              
                      <label class="packagelabel"> <span class="colorred"> <h2 style="margin:2px; line-height:6px;"> <i class="fa fa-inr"></i>{{selectedHotelRoom?.Price?.PublishedPriceRoundedOff}}/-

                      <span style="font-size:11px">Per Room Per Night</span>
                      </h2></span></label>

                           <label class="packagelabel"> 
                        <div class="form-group form-group-lg form-group-icon-left form-group-filled" style="margin-bottom:0;">
                                                
                                                    <input placeholder="{{searchDataObj?.CheckInDate}}" class="form-control regencydatepicar bokingtextinput" name="from" id="checkin" type="text" readonly>
                                                </div>
                        </label>

                        <label class="packagelabel"> 
                        <div class="form-group form-group-lg form-group-icon-left form-group-filled" style="margin-bottom:0;">
                                                
                                                    <input placeholder="{{searchDataObj?.checkOutDate}}" class="form-control regencydatepicar bokingtextinput" name="from" id="checkin" type="text"  readonly>
                                                </div>
                        </label>
                        <label class="packagelabel"> 
                            <div class="form-group form-group-lg form-group-select-plus" style="margin-bottom:0;">
                                                    <div class="select-style">
                                                        <select class="form-control">
                                                         <option>1Guest/1Room</option>
                                                        
                                                        </select>
                                                    </div>
                                                    
                                                   
                                                </div>
                        </label>
                        
                         <label class="packagelabel"> 
                            <div class="form-group form-group-lg form-group-select-plus" style="margin-bottom:0;">
                                                   
                                                    
                                                    <div class="select-style">
                                                        <select class="form-control">
                                                         <option>Deluxe Room</option>
                                                        
                                                        </select>
                                                    </div>
                                                    
                                                   
                                                </div>
                        </label>
                                                    
                    </div>
                    
                    <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                      
                        
                           <label class="packagelabel"> <h2> Inclusions </h2></label>

                    <div class="incluson-icon"><i class="fa fa-hotel"></i> Hotel</div>
                    <div class="incluson-icon"><i class="fa fa-car"></i> Transfers</div>
                    <div class="incluson-icon"><i class="fa fa-building"></i> City tour</div>
                    <div class="incluson-icon"><i class="fa fa-camera"></i> Sightseeing</div>
                    
                     <div class="incluson-icon"><i class="fa fa-coffee"></i> Breakfast</div>	
                        
                    </div>
                    
                    
                    <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff; text-align:center;" >
                      
                        
                           <label class="packagelabel"><h2 style="margin-top:10px; margin-bottom:0;"> <span class="colorred"> <i class="fa fa-inr" aria-hidden="true"></i>
                            {{selectedHotelRoom?.Price?.PublishedPriceRoundedOff * searchDataObj?.NoOfNights}}</span>
                            <br>
                            <span style="font-size:11px;">for {{searchDataObj?.NoOfRooms}} Room {{searchDataObj?.NoOfNights}} nights {{searchDataObj?.RoomGuests[0].NoOfAdults}} Person Excludes Taxes</span>
                            </h2>
                        </label>
<div class="col-sm-12 margin5 padding-bot0">
                                    <a href="#" class="" title="Add Compare"><img src="assets/images/icons/compare-add.png"></a> 
                                    <a href="#" class="" title="Add Wish List"><img src="assets/images/icons/wishlist-add.png"></a>
                                </div>
                        <label class="packagelabel colorred"> <a (click)="showHotelReview(selectedHotelRoom)" class="btn-primary">Book Now</a></label>
                        
                        
                    </div>
                    
                    
                    <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                      
                        
                           

                  <div class="col-sm-12 padding0">
                 <label class="packagelabel"> <h2 style="margin-top:5px;"> Enquiry Now </h2></label>
                  <label style="margin-right:5px;">
                                    <a href="#"  class="contact-iccons" title="Send Enquiry"><i class="fa fa-envelope" aria-hidden="true"></i>
</a>
<br>
Send Enquiry
</label>
<label style="margin-right:5px;">
<a href="#"  class="contact-iccons" title="Gat a Call Back"><i class="fa fa-phone" aria-hidden="true"></i>
</a>
<br> Gat a Call Back 
</label>
<label>
<a href="#"  class="contact-iccons" title="Refer a Friend"><i class="fa fa-user-plus"></i>
</a>
<br>
Refer a Friend
</label>
                                    
                                </div>
                        
             
                    
                </div>
                
                    <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                      
                        
                           

                  <div class="col-sm-12 padding0">
                 <label class="packagelabel"> <h2 style="margin-top:5px;"> Location on Map Now </h2></label>
                 
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d113032.64621395394!2d85.25609251320085!3d27.708942727046708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu+44600%2C+Nepal!5e0!3m2!1sen!2sin!4v1537941812283" width="100%" height="200" frameborder="0" style="border:0" allowfullscreen></iframe>
                                    
                                </div>
                        
             
                    
                </div>
                
                
                    <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                      
                        
                           

                  <div class="col-sm-12 padding0">
                 <label class="packagelabel"> <h2 style="margin-top:5px;"> Near By Hotel </h2></label>
                 <div class="col-sm-12 padding0 margin-bot5px">
                  <label class="pull-left">
                      <img src="images/hotel_smal/2.jpg" width="70" height="70">
                  </label>
                  
                  <div style="margin-left:10px; float:left;">
                      Shyam Place Guest House<br>
                    
                                    <label><img src="images/icons/starnew.jpg"></label>
                                     <label><img src="images/icons/starnew.jpg"></label>
                                      <label><img src="images/icons/starnew.jpg"></label>
                                       <label><img src="images/icons/starnew.jpg"></label>
                                       <label><img src="images/icons/star_blank.jpg"></label>
                  </div>
                  
                  </div>
                  
                  <div class="col-sm-12 padding0 margin-bot5px">
                  <label class="pull-left">
                      <img src="images/hotel_smal/2.jpg" width="70" height="70">
                  </label>
                  
                  <div style="margin-left:10px; float:left;">
                      Shyam Place Guest House<br>
                    
                                    <label><img src="images/icons/starnew.jpg"></label>
                                     <label><img src="images/icons/starnew.jpg"></label>
                                      <label><img src="images/icons/starnew.jpg"></label>
                                       <label><img src="images/icons/starnew.jpg"></label>
                                       <label><img src="images/icons/star_blank.jpg"></label>
                  </div>
                  
                  </div>
                  
                  <div class="col-sm-12 padding0 margin-bot5px">
                  <label class="pull-left">
                      <img src="images/hotel_smal/2.jpg" width="70" height="70">
                  </label>
                  
                  <div style="margin-left:10px; float:left;">
                      Shyam Place Guest House<br>
                    
                                    <label><img src="images/icons/starnew.jpg"></label>
                                     <label><img src="images/icons/starnew.jpg"></label>
                                      <label><img src="images/icons/starnew.jpg"></label>
                                       <label><img src="images/icons/starnew.jpg"></label>
                                       <label><img src="images/icons/star_blank.jpg"></label>
                  </div>
                  
                  </div>
                  
                  <div class="col-sm-12 padding0 margin-bot5px">
                  <label class="pull-left">
                      <img src="images/hotel_smal/2.jpg" width="70" height="70">
                  </label>
                  
                  <div style="margin-left:10px; float:left;">
                      Shyam Place Guest House<br>
                    
                                    <label><img src="images/icons/starnew.jpg"></label>
                                     <label><img src="images/icons/starnew.jpg"></label>
                                      <label><img src="images/icons/starnew.jpg"></label>
                                       <label><img src="images/icons/starnew.jpg"></label>
                                       <label><img src="images/icons/star_blank.jpg"></label>
                  </div>
                  
                  </div>
                  
                  
                                    
                                </div>
                        
             
                    
                </div>
                
            </div><!--/.services-->
        </div><!--/.row-->   
        </div> 
    </div><!--/.container--><br>


</section>

<ng-template #loadingHotel>
    <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
        <circle cx="170" cy="170" r="160" stroke="#E2007C"/>
        <circle cx="170" cy="170" r="135" stroke="#404041"/>
        <circle cx="170" cy="170" r="110" stroke="#E2007C"/>
        <circle cx="170" cy="170" r="85" stroke="#404041"/>
   </svg>
   
</ng-template>