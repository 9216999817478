import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { datepickerAnimation } from 'ngx-bootstrap/datepicker/datepicker-animations';
import { ContactService } from '../../services/contact.service';
import { HotelService } from '../../services/hotel.service';

@Component({
  selector: 'app-review-bookingdetails',
  templateUrl: './review-bookingdetails.component.html',
  styleUrls: ['./review-bookingdetails.component.scss']
})
export class ReviewBookingdetailsComponent implements OnInit {
  roomDataObj: any;
  hotelRoomDetails: any;
  searchDataObj: any;
  hotelDataObj: any;
  ipAddress: any;
  hotelListData: any;
  passengerDetails: any;
  bookingData: any;
  constructor( private contactService:ContactService, private hotelService : HotelService, private router:Router) { }

  ngOnInit(): void {
    this.hotelListData = JSON.parse(localStorage.getItem('hotelListData'));
    this.contactService.getIPAddress().subscribe((res:any) =>{
      this.ipAddress = res.ip
    });
    this.searchDataObj = JSON.parse(localStorage.getItem('userSearchData'));
    this.hotelDataObj = JSON.parse(localStorage.getItem('hotelData'));
    this.roomDataObj =  JSON.parse(localStorage.getItem('roomData'));
    this.hotelRoomDetails =  JSON.parse(localStorage.getItem('roomData'));
    this.passengerDetails  =  JSON.parse(localStorage.getItem('passengerDetails'));
    this.bookingData  =   JSON.parse(localStorage.getItem('BookingData'));
    if(this.bookingData.BookResult.IsPriceChanged) {
      alert('hotel price is changed. Please Check Updated Price before proceeding further');  
      localStorage.setItem('roomData',JSON.stringify(this.bookingData.BookResult.HotelRoomsDetails));
    }
  }

  showStarRating(rating) {
    return Array(rating).fill(0).map((x,i)=>i);
  }

  onHoldBooking() {
    let todayDate = new Date();
    let  cancelationDate = new Date(this.roomDataObj.LastCancellationDate);
   if(cancelationDate>todayDate) {
    let roomBookingDetails =[
      {
        "RoomIndex": this.hotelRoomDetails.RoomIndex,
        "RoomTypeCode": this.hotelRoomDetails.RoomTypeCode,
        "RoomTypeName": this.hotelRoomDetails.RoomTypeName,
        "RatePlanCode": this.hotelRoomDetails.RatePlanCode,
        "BedTypeCode": null,
        "SmokingPreference": 0,
        "Supplements": this.hotelRoomDetails.Supplements,
        "Price": {
          "CurrencyCode": this.hotelRoomDetails.Price.CurrencyCode,
          "RoomPrice": this.hotelRoomDetails.Price.RoomPrice,
          "Tax":this.hotelRoomDetails.Price.Tax,
          "ExtraGuestCharge":this.hotelRoomDetails.Price.ExtraGuestCharge,
          "ChildCharge":this.hotelRoomDetails.Price.ChildCharge,
          "OtherCharges": this.hotelRoomDetails.Price.OtherCharges,
          "Discount":this.hotelRoomDetails.Price.Discount,
          "PublishedPrice": this.hotelRoomDetails.Price.PublishedPrice,
          "PublishedPriceRoundedOff": this.hotelRoomDetails.Price.PublishedPriceRoundedOff,
          "OfferedPrice": this.hotelRoomDetails.Price.OfferedPrice,
          "OfferedPriceRoundedOff": this.hotelRoomDetails.Price.OfferedPriceRoundedOff,
          "AgentCommission": this.hotelRoomDetails.Price.AgentCommission,
          "AgentMarkUp":this.hotelRoomDetails.Price.AgentMarkUp,
          "ServiceTax": this.hotelRoomDetails.Price.ServiceTax,
          "TDS": this.hotelRoomDetails.Price.TDS
        },
        "HotelPassenger": this.passengerDetails
      }
    ]
    let bookingData ={
      'ResultIndex': this.hotelDataObj.ResultIndex,
      'HotelCode': this.hotelDataObj.HotelCode,
      'HotelName':  this.hotelDataObj.HotelName,
      'GuestNationality': "IN",
      'NoOfRooms': this.searchDataObj.NoOfRooms,
      'ClientReferenceNo': "0",
      'IsVoucherBooking': "false",
      'HotelRoomsDetails': roomBookingDetails,
      'EndUserIp': this.ipAddress,
      'TraceId': this.hotelListData.TraceId,
    }
    this.hotelService.hotelBooking(bookingData).subscribe((data:any) =>{
      debugger;
     localStorage.setItem('BookingData',JSON.stringify(data.responsedata.data));
     if(data.responsedata.data.BookResult.Error.ErrorCode === 0){
      alert('We hold your booking and please generate voucher before cancellation date.');
     } else {
       alert('Can not book this hotel due to some issue.');
     }
     
    });
   } else {
     alert('you can not hold booking after cancellation date');
   }
  }

  onGenerateVoucher() {
    console.log('on generate voucher');
    this.router.navigate(['online-payment']);
  }

}
