import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { RegisterService } from '../../services/register.service';

@Component({
  selector: 'app-hotel-review',
  templateUrl: './hotel-review.component.html',
  styleUrls: ['./hotel-review.component.scss']
})
export class HotelReviewComponent implements OnInit {
  hotelRoomDetails: any;
  roomDataObj: any;
  searchDataObj: any;
  hotelDataObj: any;
  isLoggedIn: string;
  guestForm: any;

  constructor(private route: ActivatedRoute, private dataService:DataService, private router:Router, private formBuilder:FormBuilder, private registerService:RegisterService) {
    this.guestForm = this.formBuilder.group({
      firstname:['',Validators.required],
      lastname:['',Validators.required],
      email: ['',Validators.required],
      phone : ['',Validators.required],
    });
   }

  ngOnInit(): void {
    //this.hotelRoomDetails =this.route.snapshot.params;
   // console.log(this.hotelRoomDetails);
    this.isLoggedIn = localStorage.getItem('isLoggedIn');
   
    console.log(this.dataService.roomData,'room data');
    console.log(this.dataService.hotelData,'hotel data');
    console.log(this.dataService.userSearchData,'search data');
   // this.roomDataObj = this.dataService.roomData;
   this.roomDataObj =  JSON.parse(localStorage.getItem('roomData'));
   this.hotelRoomDetails =  JSON.parse(localStorage.getItem('roomData'));
   console.log(this.hotelRoomDetails);
   // this.searchDataObj = this.dataService.userSearchData;
   this.searchDataObj = JSON.parse(localStorage.getItem('userSearchData'));
   // this.hotelDataObj = this.dataService.hotelData;
   this.hotelDataObj = JSON.parse(localStorage.getItem('hotelData'));

  }

  showStarRating(rating) {
    return Array(rating).fill(0).map((x,i)=>i);
  }

onClickBookAsGuest(){
let guestForm =this.guestForm.value;
let guestDetails = {
  user_type_id :2,
  company_id :1,
  first_name:guestForm.firstname,
  last_name:guestForm.lastname,
  mobile:guestForm.phone,
  user_name:guestForm.email,
  email:guestForm.email
}

this.registerService.verifyUser(guestDetails).subscribe((data:any) =>{
  debugger;
  if(data.responsedata.error= "No Record Found")  {
    this.registerService.registerGuestUser(guestDetails).subscribe(data =>{
      console.log(data);
    });
  } else {

  }
  });
  //  this.router.navigate(['traveller-details']);
  }

onProceedBooking() {
  console.log('ttt')
  this.router.navigate(['traveller-details']);
}  

}
