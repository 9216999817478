import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WalletService } from '../../services/wallet.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  hotelsearchForm:FormGroup;
  userDetails: any;
  constructor(private formBuilder: FormBuilder, private waletService:WalletService) { }

  ngOnInit(): void {
    this.hotelsearchForm = this.formBuilder.group({
      searchText :[""]
    })
    
  }

 

}
