<section id="main-slider" style="padding:0 0;">
    <div class="formbgnepal">
        <div class="container">
            <div class="center col-lg-12">
                <h2><strong>Drop Your Message</strong></h2>
                <p class="lead">Please fill the form for Contact Us.</p>
                        </div>
            <div class="row contact-wrap col-lg-12">
                <div class="status alert alert-success" style="display: none" id="mailsendmsg"></div>
                <form [formGroup]="contactForm" name="contact-form" class="contact-form" id="contact-form">            <div class="col-sm-5 col-sm-offset-1">
                    <div class="form-group">
                        <label for="contact-name">Name<span style="color: red"> *</span></label>                  
                          <input type="text" name="contact_name" id="contact-name" placeholder="Name" class="form-control" formControlName="name" maxlength="35" value="">                                    </div>
    
                    <div class="form-group">
                        <label for="contact-email">Email<span style="color: red"> *</span></label>                   
                         <input type="text" name="contact_email" id="contact-email" placeholder="Email" class="form-control" formControlName="email" >                                    </div>
    
                    <div class="form-group">
                        <label for="contact-mobile">Mobile No<span style="color: red"> *</span></label>                    
                        <input type="text" name="contact_mobile" id="contact-mobile" placeholder="Mobile No." class="form-control" maxlength="12" formControlName="mobile" >                                    </div>
    
                    <div class="form-group">
                        <label for="contact-comp-name">Company Name<span style="color: red"> *</span></label>                  
                          <input type="text" name="contact_comp_name" id="contact-comp-name" placeholder="Company Name" class="form-control"  formControlName="companyname" maxlength="50" >                                    </div>
                </div>
                <div class="col-sm-5">
                    <div class="form-group">
                        <label for="contact-subject">Subject<span style="color: red"> *</span></label>                  
                          <input type="text" name="contact_subject" id="contact-subject" placeholder="Subject" class="form-control" maxlength="80" formControlName="subject" >                                    </div>
                    <div class="form-group">
                        <label for="contact-msg">Message<span style="color: red"> *</span></label>              
                              <textarea name="contact_msg" id="contact-msg" placeholder="Message" class="form-control" rows="8" formControlName="message" ></textarea>    
                                                    </div>
                    <div class="form-group">
                        <input type="submit"[disabled] ="!isFormSubmit" name="contact_submit" id="contact-submit" class="btn btn-primary btn-lg" (click)="onContactSubmit()" value="Submit Message">    
                                </div>
                                <p class="text-success1">{{formMessage}}</p>
                </div>
                </form>        </div><!--/.row-->
        </div>

    </div>
</section>
