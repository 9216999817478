<section id="main-slider" style="padding:0 0;">
    <div class="formbgnepal">
               
    <div class="container">
                            <div class="slide-margin padding-top100px">
                            <div class="col-sm-10 margin-flot">
                                 <div class="search-tabs search-tabs-bg search-tabs-nobox search-tabs-lift-top">
                <div class="tabbable">
                    
                    <div class="tab-content">
                        <div class="tab-pane fade in active" id="tab-1">
                           
                            <form [formGroup]="hotelsearchForm">
                                <div class="row">
                                    <div class="col-md-10 padding-right0">
                                       <div class="form-group form-group-lg form-group-icon-left">
                                                   
                                           
                                            <input dir="auto" class="typeahead form-control rt-input" placeholder="Type City, Country, Tour Code or Tour Name to search" type="text">
                                        </div>
                                        
                                    </div>
                                      <div class="col-sm-2 pull-right padding-left0">
                                                <button class="btn btn-lg btn-tour" type="submit">Search</button> </div>
                                                
                                                
                                    <div class="col-md-12 display-none margin-bot5px" id="advance-serch-box">
                                        <div class="input-daterange">
                                            <div class="row">
                                            <div class="col-sm-4">
                                            	<label>Date</label>
                                                <input type="date" class="form-control rt-input1">
                                            </div>
                                            
                                            <div class="col-sm-4">
                                            	<label>Theme</label>
                                                
                                                <div class="select-style">
															<select class="form-control rt-input1">		<option data-subtext="" selected>--Any Theme--</option>
															  <option data-subtext="">Holiday </option>
        <option data-subtext="">Adventure Tours</option>
        <option data-subtext="">Romantic & Honeymoon Tours</option>
                                                            
															</select>
														</div>
                                                        
                                            </div>
                                            
                                            <div class="col-sm-4">
                                            	<label>Departure From</label>
                                                
                                                <div class="select-style">
															<select class="form-control rt-input1">		 <option data-subtext="" selected>--Any City--</option>
        <option data-subtext="">Delhi</option>
        <option data-subtext="">Mumbai</option>
        <option data-subtext="">jiapur</option>
                                                            
															</select>
														</div>
                                                
                                            </div>
                                          
                                                
                                            </div>
                                            
                                            <div class="row">
                                            <div class="col-sm-12">
                                            	<label>Add Destination</label>
                                                <input type="text" class="form-control rt-input1">
                                            </div>
                                            
                                            
                                                
                                            </div>
                                        </div>
                                    </div>            
                                                
                                    
                                    <div class="col-md-12">
                                        <div class="input-daterange" data-date-format="M d, D">
                                            <div class="row">
                                            <div class="col-sm-8">
                                            	<label class="service-icon"><a [routerLink]="['/hotel-search']" class="" title="Hotel Booking"><i class="fa fa-hotel"></i></a></label>
                                                <label class="service-icon"><a href="package-search.html" class="" title="Tour Packages"><i class="fa fa-umbrella"></i></a></label>
                                                <label class="service-icon"><a href="#" class="" title="Transfer"><i class="fa fa fa-car"></i></a></label>
                                                <label class="service-icon"><a href="#" class="" title="Sightseeing"><i class="fa fa-binoculars"></i></a></label>
                                                
                                            </div>
                                            
                                            
                                            <div class="col-sm-3 pull-right text-right">
                                            	<label><a href="#" class="white advance-search">Advance Search </a> </label>
                                       <label class="arrow-down"><a href="#" class="" title="Sightseeing"><i class="fa fa-arrow-down"></i></a></label>         
                                                
                                              
                                                
                                            </div>
                                          
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                               
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div>
            
                            </div>
 						
                        </div>
                    </div>

    </div>
    </section>
