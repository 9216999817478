import { Component } from '@angular/core';
import { GlobalconfigService } from './core/services/globalconfig.service';
declare var $:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  globalConfig: any;
  title: any;
  constructor(private globalConfigService:GlobalconfigService) {
    this.globalConfigService.getGlobalConfig().subscribe(data =>{
       debugger;
       localStorage.setItem('globalConfig',JSON.stringify(data))
    });
    this.globalConfig = JSON.parse(localStorage.getItem('globalConfig'));
    this.title = this.globalConfig && this.globalConfig.site_title;
  }
 

}
