import { Injectable } from '@angular/core';
import { environment} from '../../../environments/environment'
import {HttpClient} from '@angular/common/http';
import {USER} from '../models/user';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  baseurl =environment.api;
  constructor(private http:HttpClient) {

   }

   loggedInUser(user:USER) {
     let login_api_url =`${this.baseurl}user/login`
     return this.http.post(login_api_url,user);
   }
}
