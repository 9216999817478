<footer id="footer" class="midnight-blue">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                Copyright &copy; 2013 <a target="_blank" href="#" title="RegencyTours">Regency Tours Pvt. Ltd.</a>. All Rights Reserved.
            </div>
            <div class="col-sm-6">
            
             <div class="social">
                        <ul class="social-share">
                                                    
                       
                            <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                            <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                            <li><a href="#"><i class="fa fa-linkedin"></i></a></li> 
                         <li><a href="#"><i class="fa fa-google-plus "></i></a></li>
                            <li><a href="#"><i class="fa fa-skype"></i></a></li>
                        </ul>
               
            </div>
        </div>
    </div>
    
    </div>
</footer>