import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutusComponent } from './core/components/aboutus/aboutus.component';
import { BillingSystemsComponent } from './core/components/billing-systems/billing-systems.component';
import { ContactusComponent } from './core/components/contactus/contactus.component';
import { CopyrightComponent } from './core/components/copyright/copyright.component';
import { DocumentationsComponent } from './core/components/documentations/documentations.component';
import { FranchiseeComponent } from './core/components/franchisee/franchisee.component';
import { HomeComponent } from './core/components/home/home.component';
import { HotelDetailsComponent } from './core/components/hotel-details/hotel-details.component';
import { HotelReviewComponent } from './core/components/hotel-review/hotel-review.component';
import { HotelSearchComponent } from './core/components/hotel-search/hotel-search.component';
import { InvoiceComponent } from './core/components/invoice/invoice.component';
import { LoginComponent } from './core/components/login/login.component';
import { MakePaymentComponent } from './core/components/make-payment/make-payment.component';
import { OnlinePaymentComponent } from './core/components/online-payment/online-payment.component';
import { PrivacyComponent } from './core/components/privacy/privacy.component';
import { RefundPolicyComponent } from './core/components/refund-policy/refund-policy.component';
import { RegisterComponent } from './core/components/register/register.component';
import { ReviewBookingdetailsComponent } from './core/components/review-bookingdetails/review-bookingdetails.component';
import { ServicesComponent } from './core/components/services/services.component';
import { TermsComponent } from './core/components/terms/terms.component';
import { TravellerDetailsComponent } from './core/components/traveller-details/traveller-details.component';
import { ZeroToleranceComponent } from './core/components/zero-tolerance/zero-tolerance.component';
import { AuthGuard } from './core/guards/auth.guard'

const routes: Routes = [
  {
    path:'',
    component:HomeComponent
  },
  {
    path:'login',
    component:LoginComponent
  },
  {
    path:'signup',
    component:RegisterComponent
  },
  {
    path:'contact',
    component:ContactusComponent
  },
  {
    path:'make-payment',
    component:MakePaymentComponent
  },
  {
    path:'franchisee',
    component:FranchiseeComponent
  },
  {
    path:'hotel-search',
    component:HotelSearchComponent
  },
  {
    path:'hotel-detail',
    component:HotelDetailsComponent
  },
  {
    path:'hotel-review',
    component:HotelReviewComponent
  },
  {
    path:'traveller-details',
    component:TravellerDetailsComponent
  },
  {
    path:'booking-review',
    component:ReviewBookingdetailsComponent
  },
  {
    path:'online-payment',
    component:OnlinePaymentComponent
  },
  {
    path:'invoice',
    component:InvoiceComponent
  },
  {
    path:'our-services',
    component:ServicesComponent
  },
  {
    path:'about-us',
    component:AboutusComponent
  },
  {
    path:'terms-and-conditions',
    component:TermsComponent
  },
  {
    path:'copy-right',
    component:CopyrightComponent
  },
  {
    path:'privacy-policy',
    component:PrivacyComponent
  },

  {
    path:'refund-policy',
    component:RefundPolicyComponent
  },
  {
    path:'documentations',
    component:DocumentationsComponent
  },
  {
    path:'billing-system',
    component:BillingSystemsComponent
  },
  {
    path:'zero-tolerance-policy',
    component:ZeroToleranceComponent
  },
  {
    path:'**',
    component:HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
