<section id="partner">
    <div class="container">
        <div class="center wow fadeInDown">
            <h2>WHY BOOK WITH REGENCY?</h2>
            
        </div>    

        
        
        <div style="width:auto; margin:auto; max-width:75%;">
            <div class="col-sm-3" style="text-align:center;" align="center">
           <img class="" data-wow-duration="1000ms" data-wow-delay="300ms" src="assets/images/icon_hotel.png"><br>
                                            Over 40,000 hotels in India and 430,000 wordwide
</div>

            <div class="col-sm-3" style="text-align:center;" align="center">
           <img class="" data-wow-duration="1000ms" data-wow-delay="300ms" src="assets/images/sward_icon.png"><br>
                                            India’s leading travel brand
</div>

            <div class="col-sm-3" style="text-align:center;" align="center">
           <img class="" data-wow-duration="1000ms" data-wow-delay="300ms" src="assets/images/trust_icon.png"><br>
                                            Trusted by over 31,538 happy travellers
</div>

            <div class="col-sm-3" style="text-align:center;" align="center">
           <img class="" data-wow-duration="1000ms" data-wow-delay="300ms" src="assets/images/gurrunty.png"><br>
                                            Best price guarantee
</div>
        </div>
            
           
    </div><!--/.container-->
</section>