import { Component, OnInit } from '@angular/core';
import { CmsService } from '../../services/cms.service'
@Component({
  selector: 'app-franchisee',
  templateUrl: './franchisee.component.html',
  styleUrls: ['./franchisee.component.scss']
})
export class FranchiseeComponent implements OnInit {
  pageTitle: any;
  page_desc: any;

  constructor(private cmsService:CmsService) { }

  ngOnInit(): void {
    this.getFranchiseePage();
  }

  getFranchiseePage() {
    this.cmsService.getCMSpageByID(37).subscribe((data:any) =>{
      console.log(data);
      this.pageTitle =data.page_title;
      this.page_desc =data.page_desc
    })
  }

}
