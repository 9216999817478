import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContactService } from '../../services/contact.service';
import { DataService } from '../../services/data.service';
import { HotelService } from '../../services/hotel.service';
import { Options, LabelType } from 'ng5-slider';

@Component({
  selector: 'app-hotel-result',
  templateUrl: './hotel-result.component.html',
  styleUrls: ['./hotel-result.component.scss']
})
export class HotelResultComponent implements OnInit {
  @Input() hotelList:any;
  config: any;
  ipAddress: any;
  hotelRoomList: any;
  selectedHotel: any;
  chunkweekWithAmont: any[];
  selectedRoomId: any;
  selectedRoomIdForCancel: any;
  minHotelPrice: number = 100;
  maxHotelPrice: number = 20000;
  options: Options = {
    floor: 100,
    ceil: 20000,
    translate: (value: number, label: LabelType): string => {
      setTimeout(() =>{
        this.finalhotelList = this.hotelList && this.hotelList.HotelResults && this.hotelList.HotelResults.filter(hotel =>{
          if(hotel.HotelName.toLowerCase().search(this.currentSearchValue.toLowerCase()) !== -1 && hotel.Price.OfferedPriceRoundedOff > this.minHotelPrice && hotel.Price.OfferedPriceRoundedOff < this.maxHotelPrice && hotel.StarRating >= this.selectedRating) {
            return hotel;
          }
        });
      },1000)
      switch (label) {  
        case LabelType.Low:
          this.minHotelPrice = value;
          return '' + value;
        case LabelType.High:
          this.maxHotelPrice = value;
          return '' + value;
        default:
          return 'Rs. ' + value;
      }
    }
  };
  finalHotelRoomList: any;
  finalhotelList: any;
  selectedCity: any;
  currentSearchValue: any ='';
  selectedRating: number = 1;
  priceShortingOrder: boolean;
  nameShortingOrder: boolean;
  constructor(private contactService:ContactService, private hotelservice:HotelService, private cdr:ChangeDetectorRef,private router:Router, private dataService:DataService) {
    this.contactService.getIPAddress().subscribe((res:any) =>{
      this.ipAddress = res.ip
    });
    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems:this.hotelList && this.hotelList.HotelResults.length
    };
  
   }

  ngOnInit(): void {
    this.finalhotelList =[... this.hotelList.HotelResults];
    this.selectedCity = JSON.parse(localStorage.getItem('selectedCity'));
  }

  showStarRating(rating) {
    return Array(rating).fill(0).map((x,i)=>i);
  }
  
  showCancelationPolicy(hotelRoom){
    this.selectedRoomIdForCancel =hotelRoom.RoomIndex;
  }
  
  hideCancelationPolicy(hotelRoom){
    this.selectedRoomIdForCancel =''
  }

  
  pageChanged(event){
    this.config.currentPage = event;
  }

  getHotelRoomList(hotel) {

    let hotelData = {
      "ResultIndex": hotel.ResultIndex,
      "HotelCode": hotel.HotelCode,
      "EndUserIp": this.ipAddress,
      "TraceId": this.hotelList.TraceId
    }
    
    this.hotelservice.getHotelRoomList(hotelData).subscribe((resdata:any) =>{
      this.selectedHotel = hotel.HotelCode;
      this.hotelRoomList = resdata.responsedata.data;
      this.finalHotelRoomList = [...this.hotelRoomList];
    });
  }


  getHotelDetails(hotel){
    let hotelData = {
      "ResultIndex": hotel.ResultIndex,
      "HotelCode": hotel.HotelCode,
      "EndUserIp": this.ipAddress,
      "TraceId": this.hotelList.TraceId
    } 
    this.dataService.hotelData =hotel;
    localStorage.setItem('hotelData',JSON.stringify(hotel));
    this.router.navigate(['hotel-detail', hotelData]);
  }

  showDetailPrice(hotelRoom) {
    this.selectedRoomId =hotelRoom.RoomIndex;
    let DayRates=hotelRoom.DayRates;
    let daysWithWeek = [];
    let firstStayDay = new Date(String(DayRates[0].Date));
    let lastStayDay = new Date(String(DayRates[DayRates.length -1].Date));
    let time = lastStayDay.getTime() - firstStayDay.getTime(); 
    let stayDays = time / (1000 * 3600 * 24);
    let firstday = firstStayDay.getDay();
    if(firstday ==0){
      firstday =7;
    }
    let lastDay = firstday+stayDays;
    let totalWeek = Math.ceil(lastDay/7) *7;
    let dayRateCount =0;
    for(let i=1;i<=totalWeek;i++) {
      if(i>=firstday && i<=lastDay){
        daysWithWeek.push({"amount":DayRates[dayRateCount].Amount})
      }else{
        daysWithWeek.push({"amount":''})
      }
    }
      this.chunkweekWithAmont = this.chunkArray(daysWithWeek,Math.ceil(lastDay/7));
      console.log(this.chunkweekWithAmont);
      }

      chunkArray(arr,n){
        var chunkLength = Math.max(arr.length/n ,1);
        var chunks = [];
        for (var i = 0; i < n; i++) {
            if(chunkLength*(i+1)<=arr.length)chunks.push(arr.slice(chunkLength*i, chunkLength*(i+1)));
        }
        return chunks; 
}

hideDetailPrice(hotelRoom){
 this.selectedRoomId =''; 
}

filterByHotelName() {
  this.finalhotelList = this.hotelList && this.hotelList.HotelResults && this.hotelList.HotelResults.filter(hotel => {
    if(hotel.HotelName.toLowerCase().search(this.currentSearchValue.toLowerCase()) !== -1 && hotel.Price.OfferedPriceRoundedOff > this.minHotelPrice && hotel.Price.OfferedPriceRoundedOff < this.maxHotelPrice &&  hotel.StarRating >= this.selectedRating) {
      return hotel;
    }
  });
}

onSearchChange(searchValue,event) {
  this.currentSearchValue = searchValue;
}

filterByRating(rating) {
  this.selectedRating = rating;
  this.finalhotelList = this.hotelList && this.hotelList.HotelResults && this.hotelList.HotelResults.filter(hotel => {
    if(hotel.HotelName.toLowerCase().search(this.currentSearchValue.toLowerCase()) !== -1 && hotel.Price.OfferedPriceRoundedOff > this.minHotelPrice && hotel.Price.OfferedPriceRoundedOff < this.maxHotelPrice &&  hotel.StarRating >= this.selectedRating) {
      return hotel;
    }
  });
}

onNameShorting() {
  this.nameShortingOrder = !this.nameShortingOrder;
  if(this.nameShortingOrder) {
    this.finalhotelList.sort(function(a, b){
      if(a.HotelName < b.HotelName) { return -1; }
      if(a.HotelName > b.HotelName) { return 1; }
      return 0;
  })
  } else {
    this.finalhotelList.sort(function(a, b){
      if(a.HotelName > b.HotelName) { return -1; }
      if(a.HotelName < b.HotelName) { return 1; }
      return 0;
  })
  }
 
}

onPriceShorting() {
  this.priceShortingOrder = !this.priceShortingOrder;
  if(this.priceShortingOrder) {
    this.finalhotelList.sort(function(a, b){
      if(a.Price.OfferedPriceRoundedOff < b.Price.OfferedPriceRoundedOff) { return -1; }
      if(a.Price.OfferedPriceRoundedOff > b.Price.OfferedPriceRoundedOff) { return 1; }
      return 0;
  })
  } else {
    this.finalhotelList.sort(function(a, b){
      if(a.Price.OfferedPriceRoundedOff > b.Price.OfferedPriceRoundedOff) { return -1; }
      if(a.Price.OfferedPriceRoundedOff < b.Price.OfferedPriceRoundedOff) { return 1; }
      return 0;
  }) 
  }
 

}

}
