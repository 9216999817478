import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { RegisterService } from '../../services/register.service'

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;

  constructor(private formBuilder:FormBuilder,private loginService:LoginService,private router:Router,private registerService:RegisterService) {
   

   }

   onRegister() {
    console.log(this.registerForm.value)
    let userDetail = {
      "user_type_id": 0,
      "company_id": 0,
      "username": this.registerForm.value.userid,
      "firstName": this.registerForm.value.firstname,
      "lastName": this.registerForm.value.lastname,
      "mobile": this.registerForm.value.mobilenumber,
      "email": this.registerForm.value.email,
      "gender": "male",
      "password": this.registerForm.value.password,
      "country_id": 0,
      "state_id": 0,
      "city_id": 0,
      "address1": this.registerForm.value.address,
      "father_name": "dev",
      "dob": "09/07/1995",
      "alt_contact_no": this.registerForm.value.altmobilenumber,
      "pincode": this.registerForm.value.address,
      "id": 0
    }
    console.log(userDetail);
    this.registerService.registerUser(userDetail).subscribe(data =>{
      console.log(data);
    })
   }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      bussinesstype:[''],
      firstname:[''],
      lastname:[''],
      nametitle:[''],
      companyname:[''],
      state:[''],
      city:[''],
      address:[''],
      zipcode:[''],
      phonecode:[''],
      phonesdtcode:[''],
      phonenumber:[''],
      mobilecode:[''],
      mobilenumber:[''],
      altmobilecode:[''],
      altmobilenumber:[''],
      email:[''],
      confirmemail:[''],
      faxcode:[''],
      faxareacode:[''],
      faxnumber:[''],
      url:[''],
      userid:[''],
      password:[''],
      confirmpassword:[''],
      pannumber:['']

    });
  }

}
