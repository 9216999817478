import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  baseurl =environment.api;
  localurl = environment.localapi;
  constructor(private http:HttpClient) { }

  walletAmountByUserID(userid:number) {
    let api_url =`${this.baseurl}user/walletamount?user_id=${userid}`
    return this.http.get(api_url);
  } 

  getTheBookingList(bookingDetails:any) {
    let api_url =`${this.baseurl}/Booking/bookingListSearch`
    return this.http.post(api_url,bookingDetails);
  } 

  updateWalletAmount(updateWalletAmount:any) {
    let api_url =`${this.baseurl}/user/updateWalletAmount`
    return this.http.put(api_url,updateWalletAmount);
  }

  atomPayment(txnDetail) {
    let api_url =`${this.localurl}payment/atomPayment`
    return this.http.post(api_url,txnDetail);
  }

  atomPaymentResponse(txnDetail) {
    let api_url =`${this.localurl}payment/atomPaymentResponse`
    return this.http.post(api_url,txnDetail);
  }
   
}
