<section id="main-slider" style="padding:0 0;">
    <div class="formbgnepal">
        <div class="container">
            <div class="slide-margin">
                <div class="col-sm-5">
                    <div class="search-tabs search-tabs-bg search-tabs-nobox search-tabs-lift-top">
                        <div class="tabbable">

                            <div class="tab-content">
                                <div class="tab-pane fade in active" id="tab-1">

                                    <form [formGroup]="loginForm">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h2 class="white"><label>Login to your account</label></h2>
                                                <div class="form-group form-group-lg form-group-icon-left">


                                                    <input dir="auto" formControlName="username"
                                                        style="position: relative; vertical-align: top;"
                                                        class="typeahead form-control tt-input" placeholder="User Name"
                                                        type="text">
                                                </div>

                                                <div
                                                    class="form-group form-group-lg form-group-icon-left form-group-filled">

                                                    <input formControlName="password" placeholder="Password" class="form-control" name="password"
                                                        id="pass" type="password">
                                                </div>

                                            </div>
                                            <div class="col-md-12">
                                                <div class="input-daterange" data-date-format="M d, D">
                                                    <div class="row">
                                                        <div class="col-sm-6 pull-left">
                                                            <p class="text-danger">{{errorMessage}}</p>
                                                            <label><a href="#" class="white">Forgot Your
                                                                    Password</a></label>
                                                            <label><a href="b2b_ragistration.html" class="white">New
                                                                    Agents Register here</a></label>
                                                        </div>
                                                        <div class="col-sm-3 pull-right">
                                                            <button class="btn btn-primary btn-lg"
                                                                type="submit"(click)="onLoginSubmit()">Login</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>
</section>