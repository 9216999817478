<section id="feature" style="background:#ededed; margin-top:10px;" >
       
    <div class="left wow fadeInDown reviewbg">
        <div class="container">
            <div class="col-sm-6">
            <h2 style="margin-top:15px; color:#fff;">{{hotelDataObj.HotelName}}
               <ng-container *ngFor="let i of showStarRating(hotelDataObj.StarRating)" >
                   <label><img src="assets/images/icons/starnew.jpg"></label>
                 </ng-container>
                 <ng-container *ngFor="let i of showStarRating(5 -hotelDataObj.StarRating)">
                      <label><img src="assets/images/icons/star_blank.jpg"></label></ng-container> 
   <br>
            <span style="font-size:13px;">{{hotelDataObj.HotelLocation}}</span></h2>
            </div>
            <div class="col-sm-2">
            <h2 style="margin-top:15px; color:#fff;">Check-in<br>
            <span style="font-size:13px;">{{searchDataObj.CheckInDate}}</span>
            </h2>
            </div>
             <div class="col-sm-2">
            <h2 style="margin-top:15px; color:#fff;">Check-out <br>
            <span style="font-size:13px;">{{searchDataObj.checkOutDate}}</span>
            </h2>
            </div>
            
            <div class="col-sm-2">
            <h2 style="margin-top:15px; color:#fff;">Guest/Room <br>
            <span style="font-size:13px;">{{searchDataObj.RoomGuests[0].NoOfAdults}}/{{searchDataObj.NoOfRooms}}</span>
            </h2>
            </div>
            
            </div>
     </div>
<div class="container">
     <div class="row">
         <div class="features col-sm-12">
           <div class="col-md-12 col-sm-11 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="600ms">
                <div class="">
                  <div class="row">
                     
                     
                     <div class="col-sm-12 col-md-12 col-xl-12 padding0" style="background:#fff; padding-top:10px; margin-top:5px;" >
                     
                    
                         <div class="col-xs-12 col-sm-12">
            
             <div class="accordion" *ngIf="isShowInvoce;else loader">
                 <div *ngIf="!isShowError"class="panel-group">
                                             
                   <div class="panel panel-default" style="margin-bottom:10px;">
                     <div class="panel-heading active" style="border-bottom:1px solid #999;">
                       <h3 class="panel-title">
                         <a class="" data-toggle="collapse" data-parent="#accordion1" href="#collapseThree1">
                         Bookign                                  
                         Confirmation</a>
                       </h3>
                     </div>
                     <div>
                       <div class="bordreveew borderregency1 width100per">
                       
                       <div class="col-sm-12 padding0">
                      
                      
                        
                        <div class="col-sm-12 holidaytextbot">
                      <div class="travler-box ">
         
          
          <div class="col-sm-12 padding0 margin-bot5px confirm-booking" style="margin-bottom:20px;">
          <div class="form-group">
              <div class="col-sm-3 margin-bot5px "><strong>Reservation ID</strong></div>
             <div class="col-sm-3 margin-bot5px">
             MAU-2087
             </div>
             <div class="col-sm-3 margin-bot5px"><strong>Agent Ref.</strong></div>
             
             <div class="col-sm-3 margin-bot5px">SHALINI</div>
             </div>
             
             <div class="form-group">
              <div class="col-sm-3 margin-bot5px "><strong>Lead Pax Name</strong></div>
             <div class="col-sm-3 margin-bot5px">
{{leadPassengerDetails[0].Title}} {{leadPassengerDetails[0].FirstName}} {{leadPassengerDetails[0].Middlename}} {{leadPassengerDetails[0].LastName}}
             </div>
             <div class="col-sm-6 margin-bot5px"><strong>Agent Ref.</strong></div>
             
          </div>
          
          <div class="form-group">
              <div class="col-sm-3 margin-bot5px "><strong>Country</strong></div>
             <div class="col-sm-3 margin-bot5px">
             India
             </div>
             <div class="col-sm-3 margin-bot5px"><strong>City</strong></div>
             
             <div class="col-sm-3 margin-bot5px">New Delhi</div>
             </div>
             
             <div class="form-group">
              <div class="col-sm-3 margin-bot5px "><strong>Chick-In</strong></div>
             <div class="col-sm-3 margin-bot5px">
                {{searchDataObj.CheckInDate}}
             </div>
             <div class="col-sm-3 margin-bot5px"><strong>Duration</strong></div>
             
             <div class="col-sm-3 margin-bot5px">{{searchDataObj.NoOfNights}} Night(s)</div>
             </div>
             
             <div class="form-group">
              <div class="col-sm-3 margin-bot5px "><strong>No of Rooms</strong></div>
             <div class="col-sm-3 margin-bot5px">
            {{searchDataObj.NoOfRooms}}
             </div>
             <div class="col-sm-3 margin-bot5px"><strong>Room Type</strong></div>
             
             <div class="col-sm-3 margin-bot5px">{{hotelRoomDetails.RoomDescription}} </div>
             </div>
             
             <div class="form-group">
              <div class="col-sm-3 margin-bot5px "><strong>Status</strong></div>
             <div class="col-sm-3 margin-bot5px">
             Confirm
             </div>
             <div class="col-sm-3 margin-bot5px"><strong>Price</strong></div>
             
             <div class="col-sm-3 margin-bot5px">INR  {{hotelRoomDetails?.Price?.PublishedPriceRoundedOff * searchDataObj?.NoOfNights}} </div>
             </div>
             
         
             <div class="form-group">
         
                 <div class="col-lg-12 col-md-12  padding12top">
                        <a (click)="showInvoice()" class="btn btn-danger pull-right" data-toggle="modal" data-target="#myModal-invoice">Invoice</a>
                     <a class="btn btn-danger pull-right margin-right5px" data-toggle="modal" data-target="#myModal-voucher">Another Booking</a>&nbsp;&nbsp;
                     
                     </div>


             
             </div>
             
              
         </div>                  
                         
                                                         
                        </div>
                        
                        </div>
                        
                        
                        
                        
                         
                          <!--<div class="col-sm-12" align="right" style="margin-top:10px;"><a href="#" class="btn-primary">Continue</a> </div>-->
                       </div>
                     </div>
                   </div>
                   
                 </div><!--/#accordion1-->
             </div>
             <div *ngIf="isShowError">
               <h1 style="color: black;">Error In booking</h1>
             </div>
         </div>

<ng-template #loader>
  <div  class="loadercontainer">
	
    <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
         <circle cx="170" cy="170" r="160" stroke="#E2007C"/>
         <circle cx="170" cy="170" r="135" stroke="#404041"/>
         <circle cx="170" cy="170" r="110" stroke="#E2007C"/>
         <circle cx="170" cy="170" r="85" stroke="#404041"/>
    </svg>
    
</div>
</ng-template>
                           </div>
                     
                     
                  
                     </div>
                    
                 </div>
             </div>
             
             </div>

         </div><!--/.services-->
     </div><!--/.row-->    
 </div><!--/.container--><br>


</section>




<div id="myModal-invoice" *ngIf="isShowInvoce" class="modal fade" role="dialog">
    <div class="modal-dialog width80per">
  
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">Invoice</h4>
        </div>
        <div class="modal-body">
          <div class="box-body table-responsive display-none" id="invoice" style="display: block;">
           <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
          <tbody><tr>
              <td colspan="0">
                   <table width="100%" cellspacing="0" cellpadding="0" border="1" align="center">
                      <tbody><tr>
                          
                          <td class="paddinT5R0L5B5" colspan="2" align="left">
                          <table width="100%" cellspacing="0" cellpadding="0">
                                  
                                  <tbody><tr>
                                      <td class="invoice-td-class">
                                      <div class="col-sm-6 no-padding vertical-align-top">
                                           <img src="assets/images/logo.png">
                                          </div>
                                          
                                          
                                          
                                          <div class="col-sm-4 no-padding pull-right new1">
                                          
                                         <strong>Invoice No. :</strong> HW15085900<br>
                          <strong>Date :</strong> 16/06/2016<br>
                          <strong>Agent Reff. :</strong> Shalini
                                       
                                          </div>
                                      </td>
                                      
                                     
                                  </tr>
                              </tbody></table>
                          
                          
                           <div class="col-sm6 no-padding ">
                         
                          </div>
                          <div class="col-sm3 pull-right ">
                         
                          </div>
                          </td>
                          
                          </tr>
                        <tr>
                            <td colspan="2">
                              <table width="100%" cellspacing="0" cellpadding="0">
                                  
                                  <tbody><tr>
                                      <td class="tdclass1">
                                         <div class="col-sm-6 pull-left new1">
                                          
                                         <strong> {{leadPassengerDetails[0].Title}} {{leadPassengerDetails[0].FirstName}} {{leadPassengerDetails[0].Middlename}} {{leadPassengerDetails[0].LastName}}<br>
  
                                          3/204 Third floor Subhash Nagar,<br>
   
                                          New Delhi, Delhi-110005<br></strong>
                                                                                
                                          Phone:  011 42424242<br>
                                          Fax: 	011 23544080<br>
                                          Email: info.regencytours.in
                                          <br>
                                             PAN No.:apnj256n<br>
                                          State Code: [07] Delhi<br>
                                          GST No.: 
                                       
                                          </div>
                                          
                                          <div class="col-sm-4 no-padding vertical-align-top pull-right">
                                          <strong>Regency Tours Pvt Ltd<br>
                                          120-121, D. B. Gupta Market<br>
                                          Karol Bagh, New Delhi-110005.</strong><br>
  
                                          Phone:  011 42424242<br>
                                          Fax: 	011 23544080<br>
                                          Email: info.regencytours.in
                                          <br>																							  										PAN No.:apnj256n<br>
                                          State Code.:[07] Delhi<br>
                                          GST No.:55454554554<br>
                                          </div>
                                      </td>
                                      
                                     
                                  </tr>
                              </tbody></table>
                          </td>  
                          </tr>                   	                       
                        
                       
                        <tr>
                            <td colspan="2">
                              <div class="box-body table-responsive min-hieght250px">
                            <table id="example1" class="table table-bordered table-striped">
                                  <thead>
                                      <tr>
                                          <th>S. No</th>
                                          <th>Patriculars</th>
                                          <th>Night(s)</th>
                                          <th>Qty</th>
                                          <th>Price</th>
                                          <th align="right">Amount</th>
                                          
                                      </tr>
                                  </thead>
                              
                                  <tbody>
                                  <tr>
                                      <td>01</td>
                                      <td width="35%">           {{hotelRoomDetails.RoomDescription}} </td>
                                      <td>{{searchDataObj.NoOfNights}} </td>
                                      <td>{{searchDataObj.NoOfRooms}} </td>
                                      <td> <i class="fa fa-inr"></i>  {{hotelRoomDetails?.Price?.PublishedPriceRoundedOff * searchDataObj?.NoOfNights}}</td>
                                      <td align="right"><i class="fa fa-inr"></i>   {{hotelRoomDetails?.Price?.PublishedPriceRoundedOff * searchDataObj?.NoOfNights}}</td>
                                  </tr>
                                  
                                  <tr>
                                      <td height="35"></td>
                                      <td width="35%"></td>
                                      <td> </td>
                                      <td> </td>
                                      <td> </td>
                                      <td align="right"></td>
                                  </tr>
                                  
                                  
                                  </tbody>
                                                            
                              </table>
                              </div>
                          </td>
                        </tr>
                        
                          <tr>
                                      <td class="widthpersent100 vertical-align-top" colspan="2">
                                      
                                          
                          <div class="col-sm-5 pull-right">
                          
                             <div class="col-sm-6 no-padding" align="right">	Tatal Amt: </div>
                          <div class="col-sm-6 no-padding" align="right"><i class="fa fa-inr"></i> {{hotelRoomDetails?.Price?.PublishedPriceRoundedOff * searchDataObj?.NoOfNights}}</div>
                          
                          <div class="col-sm-6 no-padding" align="right">	(+Service Tax (0.00%)) </div>
                          <div class="col-sm-6 no-padding" align="right"><i class="fa fa-inr"></i> 0</div>
                          
                          <div class="col-sm-6 no-padding" align="right">	SGST on (2.50% of 700): </div>
                          <div class="col-sm-6 no-padding" align="right"><i class="fa fa-inr"></i> 15</div>
                          
                          <div class="col-sm-6 no-padding" align="right">	CGST on (2.50% of 700): </div>
                          <div class="col-sm-6 no-padding" align="right"><i class="fa fa-inr"></i> 15</div>
                          <div class="border-bottom col-sm-12 no-padding border-top margintop5 padding-top5px pull-right widthpersent100">
                          <div class="col-sm-6 no-padding" align="right">	Net Ammount: </div>
                          <div class="col-sm-6 no-padding" align="right"><i class="fa fa-inr"></i> {{hotelRoomDetails?.Price?.PublishedPriceRoundedOff * searchDataObj?.NoOfNights}}</div>
                          </div>
                          
                          <div class="col-sm-12 pull-right no-padding widthpersent100 margin-top20">
                          <div class="col-sm-6 no-padding" align="right">	PAYMENT RECEIVABLE </div>
                          <div class="col-sm-6 no-padding" align="right"><i class="fa fa-inr"></i> 0.00</div>
                          </div>
                          
                          <div class="col-sm-12 pull-right no-padding widthpersent100 ">
                          <div class="col-sm-6 no-padding" align="right">	OUT STANDING BALANCE </div>
                          <div class="col-sm-6 no-padding" align="right"><i class="fa fa-inr"></i> 46979</div>
                          </div>
                          
                          <div class="col-sm-12 pull-right no-padding widthpersent100 text-right margin-top10 font-size14px">
                          Indian Rupees: Four Thound six Hundred ninty seven
                          </div>
                          
                          <div class="col-sm-12 no-padding paddingT50pxB10px" align="right">For REGENCY TOURS PVT LTD</div>
                          
                          </div>
                         
                                      
                                      </td>
                                      
                                  </tr>  
                        
                  </tbody></table>
            </td>
         </tr>
          
                 
          
          
      </tbody></table>
      
          <table style="margin-top:10px;" width="100%" border="1" align="center">
          <tbody><tr>
              <td>
              <div class="col-sm-12 no-padding">
                  <div class="col-sm-12 ">
                  Please refer to above fo your term of payment and settle your payment to our bank details below.
                  </div>
                  <div class="col-sm-2">A/C Name</div>
                  <div class="col-sm-10">Regency Tours Pvt Ltd</div>
                   <div class="col-sm-2">Bank Name</div>
                  <div class="col-sm-10">HDFC Bank</div>
                   <div class="col-sm-2">Branch</div>
                  <div class="col-sm-10">Karol Bagh</div>
                   <div class="col-sm-2">Account No.</div>
                  <div class="col-sm-10">25421586542</div>
                   <div class="col-sm-2">Address</div>
                  <div class="col-sm-10">29-30, DB Gupta Market, Karol Bagh, Dlhi-110005</div>
                   <div class="col-sm-2">Swift Code</div>
                  <div class="col-sm-10">HDFCJKJKFDFKJ</div>
              </div>
                  
              </td>
          </tr>
      </tbody></table>
      
   
        <div class="no-padding">
                                  <a href="#" class="btn btn-primary"><i class="fa fa-envelope"></i> Email </a>
                                  <a href="#" class="btn btn-primary"><i class="fa fa-print"></i> Print </a>
                              </div>
          
        </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>
  
    </div>
  </div>