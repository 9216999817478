<section id="feature" style="background:#ededed; margin-top:10px;" >
       
    <div class="left wow fadeInDown reviewbg">
        <div class="container">
            <div class="col-sm-6">
            <h2 style="margin-top:15px; color:#fff;">{{hotelDataObj.HotelName}}
               <ng-container *ngFor="let i of showStarRating(hotelDataObj.StarRating)" >
                   <label><img src="assets/images/icons/starnew.jpg"></label>
                 </ng-container>
                 <ng-container *ngFor="let i of showStarRating(5 -hotelDataObj.StarRating)">
                      <label><img src="assets/images/icons/star_blank.jpg"></label></ng-container> 
   <br>
            <span style="font-size:13px;">{{hotelDataObj.HotelLocation}}</span></h2>
            </div>
            <div class="col-sm-2">
            <h2 style="margin-top:15px; color:#fff;">Check-in<br>
            <span style="font-size:13px;">{{searchDataObj.CheckInDate}}</span>
            </h2>
            </div>
             <div class="col-sm-2">
            <h2 style="margin-top:15px; color:#fff;">Check-out <br>
            <span style="font-size:13px;">{{searchDataObj.checkOutDate}}</span>
            </h2>
            </div>
            
            <div class="col-sm-2">
            <h2 style="margin-top:15px; color:#fff;">Guest/Room <br>
            <span style="font-size:13px;">{{searchDataObj.RoomGuests[0].NoOfAdults}}/{{searchDataObj.NoOfRooms}}</span>
            </h2>
            </div>
            
            </div>
     </div>
<div class="container">
     <div class="row">
         <div class="features col-sm-12">
            <div class="col-sm-12" align="center" style="background:#fff; margin-bottom:10px;">
                <img src="assets/images/makepayment.jpg" width="1098">
            </div>
            
             <div class="col-md-9 col-sm-11 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="600ms">
                  <div class="">
                  <div class="row">
                     
                     
                     <div class="col-sm-12 col-md-12 col-xl-12 padding0" style="background:#fff; padding-top:10px; margin-top:5px;" >
                     
                    
                         <div class="col-xs-12 col-sm-12">
            
             <div class="accordion">
                 <div class="panel-group" id="accordion1">
                   

                                            
                   

                   
                   
                   <div class="panel panel-default" style="margin-bottom:10px;">
                     <div class="panel-heading active" style="border-bottom:1px solid #999;">
                       <h3 class="panel-title">
                         <a class="" data-toggle="collapse" data-parent="#accordion1" href="#collapseThree1">
                         Payment Method                                  
                         </a>
                       </h3>
                     </div>
                     <div id="collapseThree2" class="">
                       <div class="bordreveew borderregency1">
                       
                       <div class="col-sm-12 padding0">
                      
                      
                        
                        <div class="col-sm-12 holidaytextbot">
                      <div class="travler-box ">
          <h2 class="no-margin padding0 no-margin padding-left15px" style="margin-bottom:5px;">Pay <i class="fa fa-inr"> </i> {{hotelRoomDetails?.Price?.PublishedPriceRoundedOff * searchDataObj?.NoOfNights}}</h2>
          
          <div class="col-sm-12 padding0 margin-bot5px" style="margin-bottom:20px;">
              <div class="col-sm-3 margin-bot5px linhight36"  (click)="onSelectCrediShell()"> <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> Creadit Shell</label></div>
             <div class="col-sm-9 margin-bot5px"><label class="borderregency1 padding5pxx"><i class="fa fa-inr"></i> {{userWalletDetails?.wallet_amount}}</label></div>
             <div class="col-sm-3 margin-bot5px"><input id="loginhave" value="loginhave" type="checkbox" name="loginhave"  (change)="onOnlinePayment($event)"  ><label for="showwithhotel1" style="font-size:12px;"><span></span> Online Payment</label></div>
          
          </div>
             
         
             <div class="form-group" *ngIf="isOnlinePayment">
         
                 <div class="col-lg-12 col-md-12 bhoechie-tab-container">
     <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 bhoechie-tab-menu">
       <div class="list-group">
         <a (click)="OnSelectOnlinePaymentType('Credit')" class="list-group-item no-border-top" [ngClass]="{'active': onlinePaymentType ==='Credit'}">
           Credit/Debit Card
         </a>
         <a  (click)="OnSelectOnlinePaymentType('Net Banking')" class="btn list-group-item " [ngClass]="{'active': onlinePaymentType ==='Net Banking'}">
           Net Banking
         </a>
         <a (click)="OnSelectOnlinePaymentType('Wallets')" class="btn list-group-item " [ngClass]="{'active': onlinePaymentType ==='Wallets'}">
           Wallets
         </a>
         <a (click)="OnSelectOnlinePaymentType('Atom')" class="btn list-group-item " [ngClass]="{'active': onlinePaymentType ==='Amex'}">
           Atom
         </a>
         <a (click)="OnSelectOnlinePaymentType('International Card')" class="btn list-group-item" [ngClass]="{'active': onlinePaymentType ==='International Card'}">
           International Card
         </a>
       </div>
     </div>
     <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 bhoechie-tab">
  
         
         <div class="bhoechie-tab-content" [ngClass]="{'active': onlinePaymentType ==='Credit'}">
              <div class="col-sm-12 no-padding border-bottom margin-bot10px">
                 <h2>Transactions on Regency Tours Pvt Ltd are 100% safe.</h2>
             </div>
             <div class="col-sm-12 no-padding margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3">Card number</label>
                  <div class="col-sm-6"><input type="text" class="form-control bokingtextinput" /></div>
                 <div class="col-sm-3"><img src="assets/images/icons/payment-icon.png" /></div>
              </div>
              </div>
             </div>
             
             <div class="col-sm-12 no-padding margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3">Name on Card</label>
                  <div class="col-sm-6"><input type="text" class="form-control bokingtextinput" /></div>
                 <div class="col-sm-3"></div>
              </div>
              </div>
             </div>
             
             <div class="col-sm-12 no-padding border-bottom padding-bot10px margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3">Expiry (MM/YY)</label>
              
                  <div class="col-sm-9 no-padding">
                  <div class="col-sm-2 padding0"><input type="text" class="form-control bokingtextinput" placeholder="MM" /></div>
                  <label class="col-sm-1">/</label>
                 <div class="col-sm-2 padding0"><input type="text" class="form-control bokingtextinput" placeholder="YY" /></div>
                 <!--<div class="col-sm-1 cvv linhight36">CVV</div>-->
                 <div class="col-sm-3 cvv-input "><input type="text" class="form-control bokingtextinput" placeholder="CVP" /></div>
                 </div>
              </div>
              </div>
             </div>
             
             <div class="col-sm-12 no-padding margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3 line-hidght25px"></label>
              <div class="col-sm-3">
              <a href="#" class="btn btn-bookingcab-search btn-search font-size14px color-black"><span>Pay</span> <span><i class="fa fa-inr"></i> 675</span></a>
              </div>
                  
              </div>
              </div>
             </div>
             
         </div>
         
         <div class="bhoechie-tab-content" [ngClass]="{'active': onlinePaymentType ==='Net Banking'}">
              <div class="col-sm-12 no-padding border-bottom margin-bot10px">
                 <h2>Transactions on Regency Tours Pvt Ltd are 100% safe.</h2>
             </div>
             <div class="col-sm-12 no-padding margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3">User Id</label>
                  <div class="col-sm-6"><input type="text" class="form-control bokingtextinput" /></div>
                 
              </div>
              </div>
             </div>
             
             <div class="col-sm-12 no-padding margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3">Password</label>
                  <div class="col-sm-6"><input type="text" class="form-control bokingtextinput" /></div>
                 <div class="col-sm-3"></div>
              </div>
              </div>
             </div>
             
             <div class="col-sm-12 no-padding margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3 line-hidght25px"></label>
              <div class="col-sm-3">
              <a href="#" class="btn btn-bookingcab-search btn-search font-size14px color-black"><span>Pay</span> <span><i class="fa fa-inr"></i> 675</span></a>
              </div>
                  
              </div>
              </div>
             </div>
             
         </div>

        
         <div class="bhoechie-tab-content" [ngClass]="{'active': onlinePaymentType ==='Wallets'}">
              <div class="col-sm-12 no-padding border-bottom margin-bot10px">
                 <h2>Transactions on Regency Tours Pvt Ltd are 100% safe.</h2>
             </div>
             <div class="col-sm-12 no-padding margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3">Card number</label>
                  <div class="col-sm-6"><input type="text" class="form-control bokingtextinput" /></div>
                 <div class="col-sm-3"><img src="assets/images/icons/payment-icon.png" /></div>
              </div>
              </div>
             </div>
             
             <div class="col-sm-12 no-padding margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3">Name on Card</label>
                  <div class="col-sm-6"><input type="text" class="form-control bokingtextinput" /></div>
                 <div class="col-sm-3"></div>
              </div>
              </div>
             </div>
             
             <div class="col-sm-12 no-padding border-bottom padding-bot10px margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3">Expiry (MM/YY)</label>
              
                  <div class="col-sm-9 no-padding">
                  <div class="col-sm-2 padding0"><input type="text" class="form-control bokingtextinput" placeholder="MM" /></div>
                  <label class="col-sm-1">/</label>
                 <div class="col-sm-2 padding0"><input type="text" class="form-control bokingtextinput" placeholder="YY" /></div>
                 <!--<div class="col-sm-1 cvv linhight36">CVV</div>-->
                 <div class="col-sm-3 cvv-input "><input type="text" class="form-control bokingtextinput" placeholder="CVP" /></div>
                 </div>
              </div>
              </div>
             </div>
             
             <div class="col-sm-12 no-padding margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3 line-hidght25px"></label>
              <div class="col-sm-3">
              <a href="#" class="btn btn-bookingcab-search btn-search font-size14px color-black"><span>Pay</span> <span><i class="fa fa-inr"></i> 675</span></a>
              </div>
                  
              </div>
              </div>
             </div>
             
         </div>
         
         <div class="bhoechie-tab-content" [ngClass]="{'active': onlinePaymentType ==='Amex'}">
              <div class="col-sm-12 no-padding border-bottom margin-bot10px">
                 <h2>Transactions on Regency Tours Pvt Ltd are 100% safe.</h2>
             </div>
             <div class="col-sm-12 no-padding margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3">Card number</label>
                  <div class="col-sm-6"><input type="text" class="form-control bokingtextinput" /></div>
                 <div class="col-sm-3"><img src="assets/images/icons/payment-icon.png" /></div>
              </div>
              </div>
             </div>
             
             <div class="col-sm-12 no-padding margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3">Name on Card</label>
                  <div class="col-sm-6"><input type="text" class="form-control bokingtextinput" /></div>
                 <div class="col-sm-3"></div>
              </div>
              </div>
             </div>
             
             <div class="col-sm-12 no-padding border-bottom padding-bot10px margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3">Expiry (MM/YY)</label>
              
                  <div class="col-sm-9 no-padding">
                  <div class="col-sm-2 padding0"><input type="text" class="form-control bokingtextinput" placeholder="MM" /></div>
                  <label class="col-sm-1">/</label>
                 <div class="col-sm-2 padding0"><input type="text" class="form-control bokingtextinput" placeholder="YY" /></div>
                 <!--<div class="col-sm-1 cvv linhight36">CVV</div>-->
                 <div class="col-sm-3 cvv-input "><input type="text" class="form-control bokingtextinput" placeholder="CVP" /></div>
                 </div>
              </div>
              </div>
             </div>
             
             <div class="col-sm-12 no-padding margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3 line-hidght25px"></label>
              <div class="col-sm-3">
              <a href="#" class="btn btn-bookingcab-search btn-search font-size14px color-black"><span>Pay</span> <span><i class="fa fa-inr"></i> 675</span></a>
              </div>
                  
              </div>
              </div>
             </div>
             
         </div>
         
         <div class="bhoechie-tab-content" [ngClass]="{'active': onlinePaymentType ==='International Card'}">
              <div class="col-sm-12 no-padding border-bottom margin-bot10px">
                 <h2>Transactions on Regency Tours Pvt Ltd are 100% safe.</h2>
             </div>
             <div class="col-sm-12 no-padding margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3">Card number</label>
                  <div class="col-sm-6"><input type="text" class="form-control bokingtextinput" /></div>
                 <div class="col-sm-3"><img src="assets/images/icons/payment-icon.png" /></div>
              </div>
              </div>
             </div>
             
             <div class="col-sm-12 no-padding margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3">Name on Card</label>
                  <div class="col-sm-6"><input type="text" class="form-control bokingtextinput" /></div>
                 <div class="col-sm-3"></div>
              </div>
              </div>
             </div>
             
             <div class="col-sm-12 no-padding border-bottom padding-bot10px margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3">Expiry (MM/YY)</label>
              
                  <div class="col-sm-9 no-padding">
                  <div class="col-sm-2 padding0"><input type="text" class="form-control bokingtextinput" placeholder="MM" /></div>
                  <label class="col-sm-1">/</label>
                 <div class="col-sm-2 padding0"><input type="text" class="form-control bokingtextinput" placeholder="YY" /></div>
                 <!--<div class="col-sm-1 cvv linhight36">CVV</div>-->
                 <div class="col-sm-3 cvv-input "><input type="text" class="form-control bokingtextinput" placeholder="CVP" /></div>
                 </div>
              </div>
              </div>
             </div>

             <div class="col-sm-12 no-padding margin-bot10px">
             <div class="row">
              <div class="form-group">
              <label class="col-sm-3 line-hidght25px"></label>
              <div class="col-sm-3">
              <a href="#" class="btn btn-bookingcab-search btn-search font-size14px color-black"><span>Pay</span> <span><i class="fa fa-inr"></i> 675</span></a>
              </div>
                  
              </div>
              </div>
             </div>
             
         </div>
     </div>
 </div>


             
             </div>
             <div class="col-sm-12 no-padding margin-bot10px"  *ngIf="!isOnlinePayment && isSelectCreditShell">
                <div class="row">
                 <div class="form-group">
                 <label class="col-sm-3 line-hidght25px"></label>
                 <div class="col-sm-3">
                 <a (click)="onWalletPayment()" type="btn" class="btn btn-bookingcab-search btn-search font-size14px color-black"><span>Pay</span> <span><i class="fa fa-inr"></i> {{hotelRoomDetails?.Price?.PublishedPriceRoundedOff * searchDataObj?.NoOfNights}}</span></a>
                 </div>
                     
                 </div>
                 </div>
                </div> 
              
         </div>                  
                         
                                                         
                        </div>
                        
                        </div>
                        
                        
                        
                        
                         
                          <!--<div class="col-sm-12" align="right" style="margin-top:10px;"><a href="#" class="btn-primary">Continue</a> </div>-->
                       </div>
                     </div>
                   </div>
                   
                 </div><!--/#accordion1-->
             </div>
             
         </div>


                           </div>
                     
                     
                  
                     </div>
                    
                 </div>
             </div>
             
             
             <div class="col-md-3 col-sm-5 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="600ms" style="padding-right:0;">
                     
                <div class="col-sm-12 col-md-12 col-xl-12 margin5" style="background:#fff; color:#333;" >
                    <h2> Summary </h2>              
         <label class="packagelabel"> <span class="colorred"> <h2 style="margin:2px; line-height:6px;"> <i class="fa fa-inr"></i>{{hotelRoomDetails?.Price?.PublishedPriceRoundedOff}}/-<br>

         <span style="font-size:11px;">Per Room Per Night</span>
         </h2></span></label>

              <label class="packagelabel"> 
           <div class="form-group form-group-lg form-group-icon-left form-group-filled" style="margin-bottom:0;">
                                   
            <input placeholder="{{searchDataObj?.CheckInDate}}" class="form-control regencydatepicar bokingtextinput" name="from" id="checkin" type="text" readonly>
                                   </div>
           </label>

           <label class="packagelabel"> 
           <div class="form-group form-group-lg form-group-icon-left form-group-filled" style="margin-bottom:0;">
                                   
            <input placeholder="{{searchDataObj?.checkOutDate}}" class="form-control regencydatepicar bokingtextinput" name="from" id="checkin" type="text"  readonly>
                                   </div>
           </label>
           <label class="packagelabel"> 
               <div class="form-group form-group-lg form-group-select-plus" style="margin-bottom:0;">
                                      
                                       
                                       <div class="select-style">
                                           <select class="form-control">
                                            <option>{{searchDataObj.RoomGuests[0].NoOfAdults}} Guest/{{searchDataObj.NoOfRooms}} Rooms</option>
                                           
                                           </select>
                                       </div>
                                       
                                      
                                   </div>
           </label>
           
            <label class="packagelabel"> 
               <div class="form-group form-group-lg form-group-select-plus" style="margin-bottom:0;">
                                      
                                       
                                       <div class="select-style">
                                           <select class="form-control">
                                            <option>{{roomDataObj.RoomDescription}}</option>
                                           
                                           </select>
                                       </div>
                                       
                                      
                                   </div>
           </label>
                                       
       </div>
                 
                 <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                   
                     
                        <label class="packagelabel"> <h2> Inclusions </h2></label>

                 <div class="incluson-icon"><i class="fa fa-hotel"></i> Hotel</div>
                 <div class="incluson-icon"><i class="fa fa-car"></i> Transfers</div>
                 <div class="incluson-icon"><i class="fa fa-building"></i> City tour</div>
                 <div class="incluson-icon"><i class="fa fa-camera"></i> Sightseeing</div>
                 
                  <div class="incluson-icon"><i class="fa fa-coffee"></i> Breakfast</div>	
                     
                 </div>
                 
                 
                 <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff; text-align:center;" >
                   
                     
                    <label class="packagelabel"><h2 style="margin-top:10px; margin-bottom:0;"> <span class="colorred"> <i class="fa fa-inr" aria-hidden="true"></i>
                        {{hotelRoomDetails?.Price?.PublishedPriceRoundedOff * searchDataObj?.NoOfNights}}</span>
                       <br>
                       <span style="font-size:11px;">for {{searchDataObj?.NoOfRooms}} Room {{searchDataObj?.NoOfNights}} nights {{searchDataObj?.RoomGuests[0].NoOfAdults}} Person Excludes Taxes</span>
                       </h2>
                   </label>
<div class="col-sm-12 margin5 padding-bot0">
                                 <a href="#" class="" title="Add Compare"><img src="assets/images/icons/compare-add.png"></a> 
                                 <a href="#" class="" title="Add Wish List"><img src="assets/images/icons/wishlist-add.png"></a>
                             </div>
                     <label class="packagelabel colorred"> <a href="#" class="btn-primary" data-toggle="modal" data-target="#myModal">Book Now</a></label>
                     
                     
                 </div>
                 
                 
                 <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                   
                     
                        

               <div class="col-sm-12 padding0">
              <label class="packagelabel"> <h2 style="margin-top:5px;"> Enquiry Now </h2></label>
               <label style="margin-right:5px;">
                                 <a href="#"  class="contact-iccons" title="Send Enquiry"><i class="fa fa-envelope" aria-hidden="true"></i>
</a>
<br>
Send Enquiry
</label>
<label style="margin-right:5px;">
<a href="#"  class="contact-iccons" title="Gat a Call Back"><i class="fa fa-phone" aria-hidden="true"></i>
</a>
<br> Gat a Call Back 
</label>
<label>
<a href="#"  class="contact-iccons" title="Refer a Friend"><i class="fa fa-user-plus"></i>
</a>
<br>
Refer a Friend
</label>
                                 
                             </div>
                     
          
                 
             </div>
             
                 <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                   
                     
                        

               <div class="col-sm-12 padding0">
              <label class="packagelabel"> <h2 style="margin-top:5px;"> Location on Map Now </h2></label>
              
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d113032.64621395394!2d85.25609251320085!3d27.708942727046708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu+44600%2C+Nepal!5e0!3m2!1sen!2sin!4v1537941812283" width="100%" height="200" frameborder="0" style="border:0" allowfullscreen></iframe>
                                 
                             </div>
                     
          
                 
             </div>
             
             
                 <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                   
                     
                        

               <div class="col-sm-12 padding0">
              <label class="packagelabel"> <h2 style="margin-top:5px;"> Near By Hotel </h2></label>
              <div class="col-sm-12 padding0 margin-bot5px">
               <label class="pull-left">
                   <img src="assets/images/hotel_smal/2.jpg" width="70" height="70">
               </label>
               
               <div style="margin-left:10px; float:left;">
                   Shyam Place Guest House<br>
                 
                                 <label><img src="assets/images/icons/starnew.jpg"></label>
                                  <label><img src="assets/images/icons/starnew.jpg"></label>
                                   <label><img src="assets/images/icons/starnew.jpg"></label>
                                    <label><img src="assets/images/icons/starnew.jpg"></label>
                                    <label><img src="assets/images/icons/star_blank.jpg"></label>
               </div>
               
               </div>
               
               <div class="col-sm-12 padding0 margin-bot5px">
               <label class="pull-left">
                   <img src="assets/images/hotel_smal/2.jpg" width="70" height="70">
               </label>
               
               <div style="margin-left:10px; float:left;">
                   Shyam Place Guest House<br>
                 
                                 <label><img src="assets/images/icons/starnew.jpg"></label>
                                  <label><img src="assets/images/icons/starnew.jpg"></label>
                                   <label><img src="assets/images/icons/starnew.jpg"></label>
                                    <label><img src="assets/images/icons/starnew.jpg"></label>
                                    <label><img src="assets/images/icons/star_blank.jpg"></label>
               </div>
               
               </div>
               
               <div class="col-sm-12 padding0 margin-bot5px">
               <label class="pull-left">
                   <img src="assets/images/hotel_smal/2.jpg" width="70" height="70">
               </label>
               
               <div style="margin-left:10px; float:left;">
                   Shyam Place Guest House<br>
                 
                                 <label><img src="assets/images/icons/starnew.jpg"></label>
                                  <label><img src="assets/images/icons/starnew.jpg"></label>
                                   <label><img src="assets/images/icons/starnew.jpg"></label>
                                    <label><img src="assets/images/icons/starnew.jpg"></label>
                                    <label><img src="assets/images/icons/star_blank.jpg"></label>
               </div>
               
               </div>
               
               <div class="col-sm-12 padding0 margin-bot5px">
               <label class="pull-left">
                   <img src="assets/images/hotel_smal/2.jpg" width="70" height="70">
               </label>
               
               <div style="margin-left:10px; float:left;">
                   Shyam Place Guest House<br>
                 
                                 <label><img src="assets/images/icons/starnew.jpg"></label>
                                  <label><img src="assets/images/icons/starnew.jpg"></label>
                                   <label><img src="assets/images/icons/starnew.jpg"></label>
                                    <label><img src="assets/images/icons/starnew.jpg"></label>
                                    <label><img src="assets/images/icons/star_blank.jpg"></label>
               </div>
               
               </div>
               
               
                                 
                             </div>
                     
          
                 
             </div>
             
         </div>
         </div><!--/.services-->
     </div><!--/.row-->    
 </div><!--/.container--><br>


</section>