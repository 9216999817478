import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { WalletService } from '../../services/wallet.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('loginModal22') loginModal22: any;
  loginForm:FormGroup;
  isLoggedin:boolean=false;
  userDetails: any;
  currentRoute:'Home'
  userWalletDetails: any;
  globalConfig: any;
  errorMessage: any;
  
  constructor(private formBuilder:FormBuilder, private cdr:ChangeDetectorRef,private router:Router,activateRoute:ActivatedRoute, private waletService:WalletService,private loginService:LoginService) {  
    this.loginForm = formBuilder.group({
      username:['',Validators.required],
      password:['',Validators.required]
    });
  }

  ngOnInit(): void {
    this.isLoggedin = Boolean(sessionStorage.getItem('isLoggedIn'));
    this.userDetails = JSON.parse(sessionStorage.getItem('loginuserData'));
    this.globalConfig = JSON.parse(sessionStorage.getItem('globalConfig'));
    if(this.userDetails){
      this.waletService.walletAmountByUserID(this.userDetails.user_id).subscribe((data:any) =>{
        this.userWalletDetails =data.responsedata.data;
        });
    }
    
  }

  onLogout(){
    sessionStorage.removeItem('isLoggedIn');
    sessionStorage.removeItem('loginuserData');
    sessionStorage.removeItem('token');
    let getUrl = window.location;
    let baseUrl = getUrl.origin;
    this.router.navigate(['/']) 
        .then(() => {
          window.location.reload();
        });
  }

  openLoginModal() {
    this.loginModal22.open();
    }

    onLoginSubmit() {
      let loginUserDetails ={
        "user_type_id": 1,
        "username": this.loginForm.value.username,
        "password": this.loginForm.value.password,
        // "id": 0
      }
      this.loginService.loggedInUser(loginUserDetails).subscribe((logindata:any) =>{
        debugger;
        console.log(logindata.responsedata.status)
        if(logindata.responsedata.status ==='failed') {
          this.errorMessage = logindata.responsedata.error;
          sessionStorage.setItem('isLoggedIn',"false");
          sessionStorage.removeItem('token');
        } else { 
          this.errorMessage ='';
          let loginData11 =logindata.responsedata;
          console.log(loginData11);
          sessionStorage.setItem('token',logindata.responsedata.data.token);
          sessionStorage.setItem('loginuserData',JSON.stringify(logindata.responsedata.data));
          sessionStorage.setItem('isLoggedIn',"true");   
          window.location.reload();       
        }
       
      })
    }

}
