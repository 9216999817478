import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  baseurl =environment.api;
  constructor(private http:HttpClient) { }

  registerUser(user:any) {
    let register_api_url =`${this.baseurl}user/signup`
    return this.http.post(register_api_url,user);
  }

  registerGuestUser(user:any) {
    let register_api_url =`${this.baseurl}user/newsignup`
    return this.http.post(register_api_url,user);
  }

  verifyUser(user:any) {
    let register_api_url =`${this.baseurl}user/verifysignup?email=${user.email}&mobile=${user.mobile}&user_type_id=${user.user_type_id}`
    return this.http.get(register_api_url);
  }

}
