<div class="col-md-3 col-sm-6">
    <div class="widget">
        <h3>GET IN TOUCH</h3>
        <ul>
            <li>
            <strong>Address</strong><br>

{{globalConfig?.comp_address}}</li>
            <li><strong>Contact No.</strong>: 
                {{globalConfig?.mobile}}
</li>
            <li>Email: <a href="#">{{globalConfig?.email}}</a></li>
            <li>Skype: <a href="#">regencytours</a></li>
            
           
        </ul>
    </div>    
</div><!--/.col-md-3-->

<div class="col-md-3 col-sm-6">
    <div class="widget">
        <h3>ABOUT COMPANY</h3>
        <ul>
           
            <li><a [routerLink]="['/about-us']">About Us</a></li>
             <li><a [routerLink]="['/copy-right']">Copyright</a></li>
             <li><a [routerLink]="['/terms-and-conditions']">Terms of use</a></li>
              <li><a [routerLink]="['/privacy-policy']">Privacy policy</a></li>
               <li><a [routerLink]="['/about-us']">Contact Us</a></li>
            
           
        </ul>
    </div>    
</div><!--/.col-md-3-->

<div class="col-md-3 col-sm-6">
    <div class="widget">
        <h3>SUPPORT</h3>
        <ul>
             
            <li><a href="#">Faq</a></li>
            <li><a [routerLink]="['/documentations']">Documentation</a></li>
              <li><a [routerLink]="['/refund-policy']">Refund policy</a></li>
            <li><a [routerLink]="['/zero-tolerance-policy']">Zero Tolerence Policy</a></li>
            <li><a [routerLink]="['/billing-system']">Billing system</a></li>
        </ul>
    </div>    
</div><!--/.col-md-3-->

<div class="col-md-3 col-sm-6">
    <div class="widget">
        <h3>NEWWLETTER SIGN UP</h3>
        
            <input type="text" class="form-control newslatter" placeholder="Your Email ID"><br>
            <input type="button" value="Sign Up" class="newsletterbutton">
            
            
       
    </div>    
</div><!--/.col-md-3-->