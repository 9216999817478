import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Contact } from '../models/contact';


@Injectable({
  providedIn: 'root'
})
export class ContactService {
  baseurl =environment.api;
  constructor(private http:HttpClient) { }

  contactSubmit(contact:Contact) {
    let api_url =`${this.baseurl}contact`
    return this.http.post(api_url,contact);
  }

  getIPAddress()
  {
    return this.http.get("http://api.ipify.org/?format=json");
  }
}
