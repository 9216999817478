<section id="bottom">
    <div class="container wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="600ms">
        <div class="row">
        <div class="col-md-12 col-sm-12" style="margin-bottom:10px;">


<div class="row newsletter special-mar" style="margin-top: 30px;">
    <h6 class="fooash" style="text-align: center;"> Get Regular Updates</h6>
    <!--NEWSLETTER SUBSCRIPTION-->
    
    <form id="frmNewsletter" [formGroup]="contactForm">
        <div class="col-sm-12 mar-top-15-res">
            <div class="col-sm-12">
            <div class="col-sm-6 subscribe">Subscribe / Un-subscribe To Our Newsletter &amp; Promotional Messages</div>
           
                    <div class="col-sm-6 error_newsletter_subscription" style="background: orange none repeat scroll 0 0; color: #fff; padding:5px; display: none; width: 48%; margin-left: 18px;"><br></div>
        </div>
                                    
                    <div class="col-sm-12">
                        
                    <div class="col-lg-3">
                    <label>First Name</label>
                        <input type="text" name="sub_first_name" id="sub-first-name" class="form-control inputuppercase input-border newslatter" maxlength="60" placeholder="First Name" onkeypress="return isAlphaNumber(event)" value="" >
                    </div>
                    
                    <div class="col-lg-3">
                    <label>Last Name</label>
                    <input type="text" name="sub_last_name" id="sub-last-name" placeholder="Last Name" class="form-control inputuppercase input-border newslatter" maxlength="60" onkeypress="return isAlphaNumber(event)" value="" >
                    </div>
                    
                  
                   <div class="col-lg-3">
                   <label>Email</label>
                    <input type="text" name="sub_email" id="sub-email" placeholder="user@gmail.com" class="form-control input-border inputlowercase personalemail newslatter" value="" >                        
                   </div>
                   
                   <div class="col-lg-3">
                   <label>Mobile No.</label>
                    <input type="text" name="sub_contact_no" id="sub-contact-no" placeholder="Mobile No." class="form-control input-border user-contact newslatter" maxlength="10" onkeypress="return isNumber(event)" value="" >                       
                   </div>
                        
                    </div>
                    <div class="col-sm-12">
                    <div class="col-lg-3">
                        <label class="booking-check">Only Email
                            <input type="checkbox" name="group-news" id="email-subscription" value="1" checked="">                                       <span class="checkmark"></span>
                        </label>
                    </div>
                    
                    <div class="col-lg-3">
                        <label class="booking-check">Only Mobile
                            <input type="checkbox"  name="group-news" id="mobile-subscription" value="1" checked="">                                       <span class="checkmark"></span>
                        </label>
                    </div>
                    
                    <div class="col-lg-3">
                        <label class="booking-check">Both Email &amp; Mobile
                            <input type="checkbox" name="group-news" id="both-subscription" value="1" checked="">                                       <span class="checkmark"></span>
                        </label>
                    </div>
                    
                    <div class="col-lg-3  padding-top10px">
                        <input type="button" name="newsletter_subscription" id="newsletter-subscription" class="btn btn-primary btn-lg btn-newsletter-subscribe" value="Subscribe">           
                    </div>
                    
                    
                       
                    </div>
                    
              
                  

                

            <!--NEWSLETTER SUBSCRIPTION-->


        </div></form>
</div>
</div>        
<app-footer-links></app-footer-links>
        </div>
    </div>
</section>