import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalconfigService {
  baseurl =environment.api;
  constructor(private http:HttpClient) { }

  getGlobalConfig() {
   let configURL = `${this.baseurl}/company-setup/1`;
   return this.http.get(configURL);
  }

}
