<section id="feature" style="background:#ededed; margin-top:10px;" >
       
    <div class="left wow fadeInDown reviewbg">
     <div class="container">
         <div class="col-sm-6">
         <h2 style="margin-top:15px; color:#fff;">{{hotelDataObj.HotelName}}
            <ng-container *ngFor="let i of showStarRating(hotelDataObj.StarRating)" >
                <label><img src="assets/images/icons/starnew.jpg"></label>
              </ng-container>
              <ng-container *ngFor="let i of showStarRating(5 -hotelDataObj.StarRating)">
                   <label><img src="assets/images/icons/star_blank.jpg"></label></ng-container>
         <span style="font-size:13px;">{{hotelDataObj.HotelLocation}}</span></h2>
              </div>
        
         <div class="col-sm-2">
         <h2 style="margin-top:15px; color:#fff;">Check-in<br>
         <span style="font-size:13px;">{{searchDataObj.CheckInDate}}</span>
         </h2>
         </div>
          <div class="col-sm-2">
         <h2 style="margin-top:15px; color:#fff;">Check-out <br>
         <span style="font-size:13px;">{{searchDataObj.checkOutDate}}</span>
         </h2>
         </div>
         
         <div class="col-sm-2">
         <h2 style="margin-top:15px; color:#fff;">Guest/Room <br>
         <span style="font-size:13px;">{{searchDataObj.RoomGuests[0].NoOfAdults}}/{{searchDataObj.NoOfRooms}}</span>
         </h2>
         </div>
         
         </div>
     </div>
<div class="container">
     <div class="row">
         <div class="features col-sm-12">
            <div class="col-sm-12" align="center" style="background:#fff; margin-bottom:10px;">
                <img src="assets/images/traveldetail.jpg" width="100%">
            </div>
            
             <div class="col-md-9 col-sm-11 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="600ms">
                  <div class="">
                  <div class="row">
                     
                     
                     <div class="col-sm-12 col-md-12 col-xl-12 padding0" style="background:#fff; padding-top:10px; margin-top:5px;" >
                     
                    
                         <div class="col-xs-12 col-sm-12">
            
             <div class="accordion">
                 <div class="panel-group" id="accordion1">
                   

                            <div class="panel panel-default">
                                                      
                                 <div class="row">
                                     <div class="col-sm-12">
                                         <div style="height:auto;width:100%;">                      


     <table class="table table-bordered" border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
         <tr>
             <td colspan="0">
                 <table border="1" align="center" cellpadding="0" cellspacing="0" width="100%">
                     <tr>

                         <td align="left" class="paddinT5R0L5B5" colspan="2">
                             <table width="100%" cellpadding="0" cellspacing="0">
                                 <tr>
                                     <td class="invoice-td-class">
                                         <div class="col-sm-3 vertical-align-top">
                                             
                                           <img src="assets/images/logo.png" height="55px" width="!important 55px"/>
                                         </div>



                                         <div class="col-sm-4  pull-right new1" >

<!--                                                <strong>Booking No. :</strong> <br />
                                             <strong>Booking Date :</strong> 16/06/2016<br />
                                             <strong>Booking Time :</strong> 06:20:44 PM-->

                                         </div>
                                     </td>


                                 </tr>
                                 <tr>
                                     <td>
                                         <div class="col-sm-12 no-padding center new1" >                                                  
                                             <h5><strong>Last Cancellation Date :</strong>{{roomDataObj.LastCancellationDate  | date: 'dd/MM/yyyy'}}</h5><br />
                                         
                                         </div>
                                     </td>


                                 </tr>
                             </table>

                 <div class="col-sm6 no-padding ">
                
                 </div>
                 <div class="col-sm3 pull-right ">
                
                 </div>
                            
                         </td>

                     </tr>
                     <br/>
                     <tr>
                         <td colspan="2">
                             <div class="box-body table-responsive min-hieght250px">
                                 <table id="example1" class="table table-bordered table-striped">
                                     <thead>
                                         <tr>
                                             <th>S.No</th>
                                             <th>Destination</th>
                                             <th>Hotel</th>
                                             <th>Check-in</th>
                                             <th>Check-out</th>
                                             <th>Nights</th>
                                             <th>Rooms</th>
                                             <th>Adult</th>
                                             <th>Child</th>
                                             
                                            
                                         </tr>
                                         
                                     </thead>

                                     <tbody>
                                         <tr>
                                             <td>01</td>
                                             <td>{{searchDataObj.CityId}}</td>
                                             <td>{{hotelDataObj.HotelName}}</td>
                                             <td>{{searchDataObj.CheckInDate}}</td>
                                             <td>{{searchDataObj.CheckOutDate}}</td>                                                  
                                              <td center>{{searchDataObj.NoOfNights}} Nights</td>
                                             <td center>{{searchDataObj.NoOfRooms}}</td>
                                             <td center>{{searchDataObj.RoomGuests[0].NoOfAdults}}</td>
                                             <td center>{{searchDataObj.RoomGuests[0].NoOfChild}}</td> 
                                             
                                             
                                         </tr>
                                     </tbody>

                                 </table>
                             </div>
                         </td>
                     </tr>
<tr>
                         <td colspan="2">
                             <table class="table table-bordered" width="100%" cellpadding="0" cellspacing="0">

                                 <tr>
                                     <td class="tdclass1">
                                         <div class="col-sm-6 no-padding vertical-align-top">                                                  
                                              <div class="col-sm-4">Hotel Name</div><div class="col-sm-8">:  {{hotelDataObj.HotelName}}</div>
                                                                                                
                                             <div class="col-sm-4">Check In Date</div> <div class="col-sm-8">: {{searchDataObj.CheckInDate}}</div>
                                             
                                             <div class="col-sm-4">No of Noghts</div> <div class="col-sm-8">: {{searchDataObj.NoOfNights}} Nights</div>
                                             
                                             <div class="col-sm-4">Guest</div> <div class="col-sm-8">: {{searchDataObj.RoomGuests[0].NoOfAdults}} Adult/{{searchDataObj.RoomGuests[0].NoOfChild}} Child</div>
                                             
                                                                                 
                                         </div>



                                         <div class="col-sm-6 no-padding pull-right new1" >

                                             <div class="col-sm-4">Destination</div> <div class="col-sm-8">:  {{searchDataObj.CityId}}</div>
                                            <div class="col-sm-4">Check Out Date</div> <div class="col-sm-8">:{{searchDataObj.CheckInDate}}</div>
                                             <div class="col-sm-4">No of Rooms</div> <div class="col-sm-8">: {{searchDataObj.NoOfRooms}} Rooms</div>                                     
                                             
                                         </div>
                                     </td>


                                 </tr>
                             </table>
                         </td>
                     </tr>
                     
                     <tr>
                         <table id="example1" class="table table-bordered table-striped">
                                     <thead>
                                         <tr>
                                             <th>S.No</th>
                                             <th>Travel Date/Time</th>
                                             <th>Particular</th>
                                             <th>Details</th>
                                             <th>Adult</th>
                                             <th>Child</th>
                                             <th>Infant</th>
                                             <th>Luggage</th>
                                            
                                         </tr>
                                     </thead>

                                     <tbody>
                                         <tr>
                                             <td>01</td>
                                             <td>21/03/2021:12:20</td>
                                             <td>Red Fort, Lal Qila</td>
                                             <td>Economy (Hatch Back)</td>
                                             <td>25/03/2021</td>                                                  
                                              <td center>1</td>
                                             <td center>0</td>
                                             <td center>0</td>
                                              
                                             
                                             
                                         </tr>
                                     </tbody>

                                 </table>
                     
                     </tr>
                     <!--<tr>
                         <td class="widthpersent100 vertical-align-top" colspan="2">

                             <table width="100%" border="0" cellpadding="0" cellspacing="1">
                                 <tr><td></td></tr>
                                 <tr>                                            
                                    <td>
                                         <div class="col-sm-12 no-padding center new1" >                                                   

                                         </div>
                                     </td>

                                 </tr>





                             </table>

                         </td>

                     </tr>-->

                 </table>
             </td>
         </tr>


        
     </table>


                                         </div>
                                         </div>
                                         </div>
                                                                                 
                                         </div>                
                   

                   
                   
                   <div class="panel panel-default" style="margin-bottom:10px;">
                     <div class="panel-heading active" style="border-bottom:1px solid #999;">
                       <h3 class="panel-title">
                         <a class="" data-toggle="collapse" data-parent="#accordion1" href="#collapseThree1">
                         Passenger Details                                  
                         </a>
                       </h3>
                     </div>
                     <div id="collapseThree2" class="">
                       <div class="panel-body bordreveew">
                        <table id="example1" class="table table-bordered table-striped">
                            <tr>
                                <th>Name</th>
                                <th>Passenger Type</th>
                                <th>Is Lead Passenger</th>
                                <th>Age</th>
                                <th>Passport Number</th>
                                <th>Pan</th>
                            </tr>
                            <tr *ngFor="let passenger of passengerDetails">
                                <td>{{passenger.Title}} {{passenger.FirstName}} {{passenger.Middlename}} {{passenger.LastName}}</td>
                                <td>{{passenger.PaxType}}</td>
                                <td>{{passenger.LeadPassenger}}</td>
                                <td>{{passenger.Age}}</td>
                                <td>{{passenger.PassportNo}}</td>
                                <td>{{passenger.PAN}}</td>
                            </tr>
                        </table>
                        
                        <div class="col-sm-12 padding0">
                                                           
                        </div>    
                        </div>
                        
                     
                          
                          <!--<div class="col-sm-12" align="right" style="margin-top:10px;"><a href="#" class="btn-primary">Continue</a> </div>-->
                    </div>
                   </div>
                   
                   <br/>
<div class="panel panel-default" style="border:1px solid #ccc; padding:15px;">
                         <div class="destinations-grid-info bgnone padding-left0 ">
                             <div class="mapcantiner_inner1 no-padding">

                                 <div class="row">
                                     <div class="col-sm-12">
                                         
                                         <h4><strong>Fare Rule</strong></h4>
                                         <div>
             <p>
             <ul style="list-style:circle; margin-left:10px;">
             <li>Approx Distance ( As Per Quote)</li>
             <li>Minimum Charged Distance <strong></strong> ( As per Min Distance )</li>
             <li>Per Km Rates  <strong></strong> ( As Per Per Km Rates )</li>

             <li>Per Hour Rates <strong></strong> ( As Per Per Hrs Rates )</li>
            
             <li>Driver Allowance <strong></strong> ( Per Day)</li>
             </ul>
             <strong>Extra Charges  :- </strong>

             Distance :- 
             Time     :- If you drop the Vehicle after 2000 Hrs extra Driver Allowance will be Applicable<br/>
             <strong>Note :- </strong>
             <ul style="list-style:circle; margin-left:10px;">
                 <li>One Day Means One Calendar day from Midnight .</li>
                 <li>Kilometers ( Km) and Hours will be Calculated from Garage to Garage or Specified</li>
                 <li>Air Con will be switch off in Hill Areas</li>

             </ul>
             <strong>Inclusions & Exclusions</strong><br/>
             <strong>Inclusions</strong>
             <ul style="list-style:circle; margin-left:10px;">

                 <li>No Route Deviation allowed Unless Listed in Itinerary </li>
                 <li>GST</li>
               
             </ul>
             <strong>Exclusion</strong>
             <ul style="list-style:circle; margin-left:10px;">
                 <li>Toll Taxes ( As Per Actual)</li>
                 <li>Parking ( As Per Actual) </li>
                 <li>Night Pickup Allowance excluded</li>
                 <li>Night Drop off Allowance excluded</li>
                 <li>Peak Charges & Waiting Charges as per Tariff</li>
                 <li>Driver Allowance (Night Charges) Applicable is the Vehicle</li>
             </ul>

         </div>
                                         
                                     </div>
         </div>
     </div>
 </div>
</div>
<br/>

                     <div class="panel panel-default" style="border:1px solid #ccc; padding:15px;">
                         <div class="destinations-grid-info bgnone padding-left0 ">
                             <div class="mapcantiner_inner1 no-padding">

                                 <div class="row">
                                     <div class="col-sm-12">
                                         <h4><strong>Cancellation Rule</strong></h4>
                                         <div style="height:auto;width:100%;">
                                             <table class="table table-bordered">    
<!--                                                        <tr>
                                                     <th colspan="2" >Cancellation Rule</th>
                                                 </tr>-->
                                                
                                                         <tr>
                                                             <td>Late Cancel</td> 
                                                    
                                                             <td><i class="fa fa-inr"></i> {{roomDataObj.LastCancellationDate  | date: 'dd/MM/yyyy'}}</td> 
                                                    
                                                             <td> </td>
                                                       
                                                     </tr>    
                                                 
                                             </table>  
                                         </div>    
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>

                    <br/>
                          <div class="panel panel-default" style="padding:15px; border:1px solid #ccc;">
                         <div class="destinations-grid-info bgnone padding-left0 ">
                             <div class="mapcantiner_inner1 no-padding">

                                 <div class="row">
                                     <div class="col-md-12">
                                        <textarea class="form-control"></textarea>
                                     </div>
                                 </div>
                                 </div>
                                 </div>
                                 </div>
                    
                      <div class="col-sm-12 no-padding padding_margin0">
                         <div class="left-div-form margin-bot10px no-padding">
                             <label class="booking-check"><a href="javascript: void(0);" class="terms-color" onclick="termsCondition()">Accept the Terms and Conditions</a>
                                
                                 <span class="checkmark"></span>
                             </label>

                         
                             
                         </div>
                     </div>
                     <div class="col-md-12 no-padding sightmargin pull-left" align="left" style="">
                                                                         
                            <div class="col-sm-3  pull-right new1" >
                                <!-- <div class="col-sm-12" align="left" style="margin-top:20px;"><a  class="processbtn btn-primary">Process Booking</a>  </div> -->
                               <input name="hold_booking" (click)="onHoldBooking()" id="hold-booking" class="btn btn-primary btn-lg" value="Hold Booking" type="submit">

                               <input name="voucher" id="" (click)="onGenerateVoucher()"class="btn btn-primary btn-lg" value="Voucher" type="submit">
                             

                             </div>
                     </div>
                   
                 </div><!--/#accordion1-->
             </div>
             
             
             <div class="accordion">
                 <div class="panel-group" id="accordion1">
                   <!--<div class="panel panel-default" style="margin-bottom:10px;">
                     <div class="panel-heading active" style="border-bottom:1px solid #999;">
                       <h3 class="panel-title">
                         <a class="" data-toggle="collapse" data-parent="#accordion1" href="#collapseThree1">
                         Travel Details                                  
                         </a>
                       </h3>
                     </div>
                     <div id="collapseThree2" class="">
                       <div class="panel-body bordreveew">
                       <div class="col-sm-12 padding0">
                            
                             <div class="col-sm-3 padding0">
                             
                              <label class="checkcontainer" style="color:#333;">By Road
                           <input type="radio" id="byroad" name="hotel-cat">
                           <span class="radiobtn"></span>
                         </label>
    
     
     </div>
     
                                 <div class="col-sm-6">
                              <label class="checkcontainer" style="color:#333;">By Air 
                           <input type="radio" checked="checked" id="byair" name="hotel-cat">
                           <span class="radiobtn"></span>
                         </label>
                              
     
      
     </div>
                             </div>
                       
                       
                       <div class="col-sm-12 padding0">
                      
                        <div class="col-sm-12 holidaytextbot">
                        
                        
                         <div class="col-sm-12 padding0 padding5topbot" id="by-air">
                         
                         <div class="col-sm-3 margin-bot5px">
                            
                         Arrival Flight Date
                         </div>
                         
                         <div class="col-sm-3 margin-bot5px">
                            
                         <input type="date" class="form-control bokingtextinput" placeholder="27/09/2018">
                         </div>
                         <div class="col-sm-3 margin-bot5px">
                            
                              Departure Flight Date
                         </div>
                         
                         <div class="col-sm-3 margin-bot5px">
                            
                         <input type="date" class="form-control bokingtextinput" placeholder="28/09/2018">
                         </div>
                       
                         
                         <div class="form-group form-group-lg form-group-icon-left">
                         <div class="col-sm-3 margin-bot5px">
                            
                         Arrival Flight Time
                         </div>
                         
                         
                         
                         <div class="col-sm-3 margin-bot5px">
                            
                         <input type="time" class="form-control bokingtextinput" placeholder="27/09/2018">
                         </div>
                         <div class="col-sm-3 margin-bot5px">
                            Departure Flight Time
                         </div>
                         
                         <div class="col-sm-3 margin-bot5px">
                            
                         <input type="time" class="form-control bokingtextinput" placeholder="28/09/2018">
                         </div>
                         </div>
                         
                         
                         <div class="form-group form-group-lg form-group-icon-left">
                         <div class="col-sm-3 margin-bot5px">
                            
                         Flight Number
                         </div>
                         
                         
                         
                         <div class="col-sm-3 margin-bot5px">
                            
                         <input type="text" class="form-control bokingtextinput" placeholder="">
                         </div>
                         <div class="col-sm-3 margin-bot5px">
                            Agent Reference
                         </div>
                         
                         <div class="col-sm-3 margin-bot5px">
                            
                         <input type="text" class="form-control bokingtextinput" placeholder="">
                         </div>
                         </div>
                         
                         
                         
                         
                         </div>
                         
                         
                         <div class="col-sm-12 padding0 padding5topbot" id="by-road" style="display:none;">
                         
                         <div class="col-sm-3 margin-bot5px">
                            
                         Arrival Date
                         </div>
                         
                         <div class="col-sm-3 margin-bot5px">
                            
                         <input type="date" class="form-control bokingtextinput" placeholder="27/09/2018">
                         </div>
                         <div class="col-sm-3 margin-bot5px">
                            
                              Arrival Time
                         </div>
                         
                         <div class="col-sm-3 margin-bot5px">
                            
                         <input type="date" class="form-control bokingtextinput" placeholder="28/09/2018">
                         </div>
                       
                         
                         
                         </div>
                                                        
                         
                                                         
                        </div>
                        
                        
                        
                        </div>
                        
                         
                          <div class="col-sm-12" align="right" style="margin-top:10px;"><a href="payment-type.html" class="btn-primary">Continue</a> </div>
                       </div>
                     </div>
                   </div>-->
                   
                 </div><!--/#accordion1-->
             </div>
         </div>


                           </div>
                     
                     
                  
                     </div>
                    
                 </div>
             </div>
             
             
             <div class="col-md-3 col-sm-5 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="600ms" style="padding-right:0;">
                     
                  <div class="col-sm-12 col-md-12 col-xl-12 margin5" style="background:#fff; color:#333;" >
                              <h2> Summary </h2>              
                   <label class="packagelabel">  <h2 style="margin:2px; line-height:6px;"> <i class="fa fa-inr"></i>{{hotelRoomDetails?.Price?.PublishedPriceRoundedOff}}/-<br>

                   <span style="font-size:11px;">Per Room Per Night</span>
                   </h2></label>

                        <label class="packagelabel"> 
                     <div class="form-group form-group-lg form-group-icon-left form-group-filled" style="margin-bottom:0;">
                                             
                        <input placeholder="{{searchDataObj?.CheckInDate}}" class="form-control regencydatepicar bokingtextinput" name="from" id="checkin" type="text" readonly>
                                             </div>
                     </label>

                     <label class="packagelabel"> 
                     <div class="form-group form-group-lg form-group-icon-left form-group-filled" style="margin-bottom:0;">
                                             
                        <input placeholder="{{searchDataObj?.checkOutDate}}" class="form-control regencydatepicar bokingtextinput" name="from" id="checkin" type="text"  readonly>
                                             </div>
                     </label>
                     <label class="packagelabel"> 
                         <div class="form-group form-group-lg form-group-select-plus" style="margin-bottom:0;">
                                                
                                                 
                                                 <div class="select-style">
                                                     <select class="form-control">
                                                      <option>{{searchDataObj.RoomGuests[0].NoOfAdults}} Guest/{{searchDataObj.NoOfRooms}} Rooms</option>
                                                     
                                                     </select>
                                                 </div>
                                                 
                                                
                                             </div>
                     </label>
                     
                      <label class="packagelabel"> 
                         <div class="form-group form-group-lg form-group-select-plus" style="margin-bottom:0;">
                                                
                                                 
                                                 <div class="select-style">
                                                     <select class="form-control">
                                                      <option>{{roomDataObj.RoomDescription}}</option>
                                                     
                                                     </select>
                                                 </div>
                                                 
                                                
                                             </div>
                     </label>
                                                 
                 </div>
                 
                 <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                   
                     
                        <label class="packagelabel"> <h2> Inclusions </h2></label>

                 <div class="incluson-icon"><i class="fa fa-hotel"></i> Hotel</div>
                 <div class="incluson-icon"><i class="fa fa-car"></i> Transfers</div>
                 <div class="incluson-icon"><i class="fa fa-building"></i> City tour</div>
                 <div class="incluson-icon"><i class="fa fa-camera"></i> Sightseeing</div>
                 
                  <div class="incluson-icon"><i class="fa fa-coffee"></i> Breakfast</div>	
                     
                 </div>
                 
                 
                 <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff; text-align:center;" >
                   
                     
                        <label class="packagelabel"><h2 style="margin-top:10px; margin-bottom:0;"> <span class="colorred"> <i class="fa fa-inr" aria-hidden="true"></i>
                            {{hotelRoomDetails?.Price?.PublishedPriceRoundedOff * searchDataObj?.NoOfNights}}</span>
                         <br>
                         <span style="font-size:11px;">for {{searchDataObj?.NoOfRooms}} Room {{searchDataObj?.NoOfNights}} nights {{searchDataObj?.RoomGuests[0].NoOfAdults}} Person Excludes Taxes</span>
                         </h2>
                     </label>
<div class="col-sm-12 margin5 padding-bot0">
                                 <a href="#" class="" title="Add Compare"><img src="assets/images/icons/compare-add.png"></a> 
                                 <a href="#" class="" title="Add Wish List"><img src="assets/images/icons/wishlist-add.png"></a>
                             </div>
                     <!--<label class="packagelabel colorred"> <a href="#" class="btn-primary" data-toggle="modal" data-target="#myModal">Book Now</a></label>-->
                     
                     
                 </div>
                 
                 
                 <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                   
                     
                        

               <div class="col-sm-12 padding0">
              <label class="packagelabel"> <h2 style="margin-top:5px;"> Enquiry Now </h2></label>
               <label style="margin-right:5px;">
                                 <a href="#"  class="contact-iccons" title="Send Enquiry"><i class="fa fa-envelope" aria-hidden="true"></i>
</a>
<br>
Send Enquiry
</label>
<label style="margin-right:5px;">
<a href="#"  class="contact-iccons" title="Gat a Call Back"><i class="fa fa-phone" aria-hidden="true"></i>
</a>
<br> Gat a Call Back 
</label>
<label>
<a href="#"  class="contact-iccons" title="Refer a Friend"><i class="fa fa-user-plus"></i>
</a>
<br>
Refer a Friend
</label>
                                 
                             </div>
                     
          
                 
             </div>
             
                 <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                   
                     
                        

               <div class="col-sm-12 padding0">
              <label class="packagelabel"> <h2 style="margin-top:5px;"> Location on Map Now </h2></label>
              
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d113032.64621395394!2d85.25609251320085!3d27.708942727046708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu+44600%2C+Nepal!5e0!3m2!1sen!2sin!4v1537941812283" width="100%" height="200" frameborder="0" style="border:0" allowfullscreen></iframe>
                                 
                             </div>
                     
          
                 
             </div>
             
             
                 <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                   
                     
                        

               <div class="col-sm-12 padding0">
              <label class="packagelabel"> <h2 style="margin-top:5px;"> Near By Hotel </h2></label>
              <div class="col-sm-12 padding0 margin-bot5px">
               <label class="pull-left">
                   <img src="images/hotel_smal/2.jpg" width="70" height="70">
               </label>
               
               <div style="margin-left:10px; float:left;">
                   Shyam Place Guest House<br>
                 
                                 <label><img src="images/icons/starnew.jpg"></label>
                                  <label><img src="images/icons/starnew.jpg"></label>
                                   <label><img src="images/icons/starnew.jpg"></label>
                                    <label><img src="images/icons/starnew.jpg"></label>
                                    <label><img src="images/icons/star_blank.jpg"></label>
               </div>
               
               </div>
               
               <div class="col-sm-12 padding0 margin-bot5px">
               <label class="pull-left">
                   <img src="images/hotel_smal/2.jpg" width="70" height="70">
               </label>
               
               <div style="margin-left:10px; float:left;">
                   Shyam Place Guest House<br>
                 
                                 <label><img src="images/icons/starnew.jpg"></label>
                                  <label><img src="images/icons/starnew.jpg"></label>
                                   <label><img src="images/icons/starnew.jpg"></label>
                                    <label><img src="images/icons/starnew.jpg"></label>
                                    <label><img src="images/icons/star_blank.jpg"></label>
               </div>
               
               </div>
               
               <div class="col-sm-12 padding0 margin-bot5px">
               <label class="pull-left">
                   <img src="images/hotel_smal/2.jpg" width="70" height="70">
               </label>
               
               <div style="margin-left:10px; float:left;">
                   Shyam Place Guest House<br>
                 
                                 <label><img src="images/icons/starnew.jpg"></label>
                                  <label><img src="images/icons/starnew.jpg"></label>
                                   <label><img src="images/icons/starnew.jpg"></label>
                                    <label><img src="images/icons/starnew.jpg"></label>
                                    <label><img src="images/icons/star_blank.jpg"></label>
               </div>
               
               </div>
               
               <div class="col-sm-12 padding0 margin-bot5px">
               <label class="pull-left">
                   <img src="images/hotel_smal/2.jpg" width="70" height="70">
               </label>
               
               <div style="margin-left:10px; float:left;">
                   Shyam Place Guest House<br>
                 
                                 <label><img src="images/icons/starnew.jpg"></label>
                                  <label><img src="images/icons/starnew.jpg"></label>
                                   <label><img src="images/icons/starnew.jpg"></label>
                                    <label><img src="images/icons/starnew.jpg"></label>
                                    <label><img src="images/icons/star_blank.jpg"></label>
               </div>
               
               </div>
               
               
                                 
                             </div>
                     
          
                 
             </div>
             
         </div>
         </div><!--/.services-->
     </div><!--/.row-->    
 </div><!--/.container--><br>


</section>