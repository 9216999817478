// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api:"http://103.248.60.237:3002/api/v1/",
  // api:"demob2b.bookingcabs.com:3002/api/v1",
  localapi:"http://103.248.60.237:3002/api/v1/",
  // localapi:" http://localhost:3000/api/v1/",
  // api:" http://localhost:3000/api/v1/",
  company_setup_id:1,
  api_base_url:"https://demob2b.bookingcabs.com/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
