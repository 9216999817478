import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  baseurl =environment.api;
  constructor(private http:HttpClient) { }

  getCMSpageByID(id:number) {
    let api_url =`${this.baseurl}cms-pages/${id}`
    return this.http.get(api_url);
  }

  getOurServicesPage() {
   let pageDetails= {
      "company_setup_id": environment.company_setup_id,
      "id": 1
    }
    let api_url =`${this.baseurl}cms-pages/getCmsPages`
    return this.http.post(api_url,pageDetails);
  }

  getAboutUsPage() {
    let pageDetails= {
       "company_setup_id": environment.company_setup_id,
       "id": 2
     }
     let api_url =`${this.baseurl}cms-pages/getCmsPages`
     return this.http.post(api_url,pageDetails);
   }

   getTermsPage() {
    let pageDetails= {
       "company_setup_id": environment.company_setup_id,
       "id": 3
     }
     let api_url =`${this.baseurl}cms-pages/getCmsPages`
     return this.http.post(api_url,pageDetails);
   }
   getCopyRightPage() {
    let pageDetails= {
       "company_setup_id": environment.company_setup_id,
       "id": 4
     }
     let api_url =`${this.baseurl}cms-pages/getCmsPages`
     return this.http.post(api_url,pageDetails);
   }

   getPrivacyPage() {
    let pageDetails= {
       "company_setup_id": environment.company_setup_id,
       "id": 5
     }
     let api_url =`${this.baseurl}cms-pages/getCmsPages`
     return this.http.post(api_url,pageDetails);
   }

   getRefundPage() {
    let pageDetails= {
       "company_setup_id": environment.company_setup_id,
       "id": 7
     }
     let api_url =`${this.baseurl}cms-pages/getCmsPages`
     return this.http.post(api_url,pageDetails);
   }

   getDocumentationPage() {
    let pageDetails= {
       "company_setup_id": environment.company_setup_id,
       "id": 8
     }
     let api_url =`${this.baseurl}cms-pages/getCmsPages`
     return this.http.post(api_url,pageDetails);
   }
   
   getBillingPage() {
    let pageDetails= {
       "company_setup_id": environment.company_setup_id,
       "id": 10
     }
     let api_url =`${this.baseurl}cms-pages/getCmsPages`
     return this.http.post(api_url,pageDetails);
   }

   getZeroTolerancePage() {
    let pageDetails= {
       "company_setup_id": environment.company_setup_id,
       "id": 13
     }
     let api_url =`${this.baseurl}cms-pages/getCmsPages`
     return this.http.post(api_url,pageDetails);
   }

}
