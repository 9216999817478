import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CmsService } from '../../services/cms.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  pageDetails: any;
  bannerpath: string;

  constructor(private cmsservices:CmsService) { }

  ngOnInit(): void {
    this.cmsservices.getPrivacyPage().subscribe((data:any) =>{  
      this.pageDetails =data.responsedata[0];
      this.bannerpath = `${environment.api_base_url}upload/cms_image/${this.pageDetails.page_image}`;
      });
  }

}
