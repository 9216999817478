import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,FormControl, Validators } from '@angular/forms';
import { Router, RoutesRecognized } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginService } from '../../services/login.service';
import { filter, pairwise } from 'rxjs/operators';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
loginForm:FormGroup;
  errorMessage: any;
  constructor(private formBuilder:FormBuilder,private loginService:LoginService,private router:Router) {
     this.loginForm = formBuilder.group({
      username:['',Validators.required],
      password:['',Validators.required]
    });
   }

  ngOnInit(): void {
    this.router.events
    .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
    .subscribe((events: RoutesRecognized[]) => {
      console.log('previous url', events[0].urlAfterRedirects);
      if(events[0].urlAfterRedirects !=='/login'){
        localStorage.setItem('previousUrl',events[0].urlAfterRedirects);
      } 
      console.log('current url', events[1].urlAfterRedirects);
      // if(events[0].urlAfterRedirects === '/login') {
      //   window.location.reload
      // }
    });
  }

  onLoginSubmit() {
    let loginUserDetails ={
      "user_type_id": 1,
      "username": this.loginForm.value.username,
      "password": this.loginForm.value.password,
      // "id": 0
    }
    this.loginService.loggedInUser(loginUserDetails).subscribe((logindata:any) =>{
      console.log(logindata.responsedata.status)
      if(logindata.responsedata.status ==='failed') {
        this.errorMessage = logindata.responsedata.error;
        localStorage.setItem('isLoggedIn',"false");
        sessionStorage.removeItem('token');
      } else {
        this.errorMessage ='';
        let loginData11 =logindata.responsedata;
        console.log(loginData11);
        sessionStorage.setItem('token',logindata.responsedata.data.token);
        localStorage.setItem('loginuserData',JSON.stringify(logindata.responsedata.data));
        localStorage.setItem('isLoggedIn',"true");
      let getUrl = window.location;
      let baseUrl = getUrl.origin;
      let previousUrl = localStorage.getItem('previousUrl');
      if(previousUrl) {
      //  this.router.navigate([previousUrl]);
        this.router.navigate([previousUrl])
  .then(() => {
    window.location.reload();
  });
      } else {
        this.router.navigate(['/']) 
        .then(() => {
          window.location.reload();
        });
      }
      
      }
     
    })
  }

}
