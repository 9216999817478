<section id="feature" style="background:#ededed; margin-top:10px;" >
       
    <div class="left wow fadeInDown reviewbg" *ngIf="hotelDataObj">
     <div class="container">
         <div class="col-sm-6">
         <h2 style="margin-top:15px; color:#fff;">{{hotelDataObj.HotelName}}
            <ng-container *ngFor="let i of showStarRating(hotelDataObj.StarRating)" >
                <label><img src="assets/images/icons/starnew.jpg"></label>
              </ng-container>
              <ng-container *ngFor="let i of showStarRating(5 -hotelDataObj.StarRating)">
                   <label><img src="assets/images/icons/star_blank.jpg"></label></ng-container> 
<br>
         <span style="font-size:13px;">{{hotelDataObj.HotelLocation}}</span></h2>
         </div>
         <div class="col-sm-2">
         <h2 style="margin-top:15px; color:#fff;">Check-in<br>
         <span style="font-size:13px;">{{searchDataObj.CheckInDate}}</span>
         </h2>
         </div>
          <div class="col-sm-2">
         <h2 style="margin-top:15px; color:#fff;">Check-out <br>
         <span style="font-size:13px;">{{searchDataObj.checkOutDate}}</span>
         </h2>
         </div>
         
         <div class="col-sm-2">
         <h2 style="margin-top:15px; color:#fff;">Guest/Room <br>
         <span style="font-size:13px;">{{searchDataObj.RoomGuests[0].NoOfAdults}}/{{searchDataObj.NoOfRooms}}</span>
         </h2>
         </div>
         
         </div>
     </div>
<div class="container">
     <div class="">
         <div class="features col-sm-12">
            <div class="col-sm-12" align="center" style="background:#fff; margin-bottom:10px;">
                <!-- <img [src]="hotelDataObj.HotelPicture" width="100%"> -->
            </div>
            
             <div class="col-md-9 col-sm-11 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="600ms">
                  <div class="">
                  <div class="row">
                     
                     
                     <div class="col-sm-12 col-md-12 col-xl-12 padding0" style="background:#fff; padding-top:10px; margin-top:5px;" >
                     
                    
                         <div class="col-xs-12 col-sm-12">
            
             <div class="accordion">
                 <div class="panel-group" id="accordion1">
                   <div class="panel panel-default" style="margin-bottom:10px;">
                     <div class="panel-heading active">
                       <h3 class="panel-title">
                         <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion1" >
                           Review Your Booking
                           </a>
                         
                       </h3>
                     </div>

                     <div id="collapseOne1" class="panel-collapse collapse in">
                       <div class="panel-body bordreveew">
                           <div class="media accordion-inner">
                                
                                 <div class="media-body">
                                 <div class="col-sm-3 padding0">
                                     <img [src]="hotelDataObj.HotelPicture" width="100%">
                                 </div>
                                  <div class="col-sm-4">
                                     <!-- <h4>Hotel Dtails: 1Room(s) | 2 Adult(s)</h4>-->											<div class="col-sm-12 padding0"><h2 style="margin:0 !important">Redisson Blu Hotel<br>
                                        <ng-container *ngFor="let i of showStarRating(hotelDataObj.StarRating)" >
                                            <label><img src="assets/images/icons/starnew.jpg"></label>
                                          </ng-container>
                                          <ng-container *ngFor="let i of showStarRating(5 -hotelDataObj.StarRating)">
                                               <label><img src="assets/images/icons/star_blank.jpg"></label></ng-container> 
                                         </h2>
                                          </div>
                                          <div class="col-sm-12 padding0">
                                         <span>
                                             {{searchDataObj.RoomGuests[0].NoOfAdults}}:{{searchDataObj.NoOfRooms}}
                                        </span>
                                         
                                         </div>
                                         <div class="col-sm-12 padding0">
                                         <span class="col-sm-4 padding0">
                                             Room Type:</span>
                                             {{roomDataObj.RoomTypeName}}<br>

                                        <span style="font-size:11px;"><a href="hotel_detailpage.html">Change Room</a></span>
                                         </div>
                                 </div>
                                 
                                  <div class="col-sm-5 padding12top" style="margin-top:35px;">
                                                                                                                            <div class="col-sm-4 padding0">Check-in<br>{{searchDataObj.CheckInDate}}</div>
                                        
                                         <div class="col-sm-4 padding0">Chack-out<br>{{searchDataObj.checkOutDate}}</div>
                                         <div class="col-sm-4 padding0">No. of Nights<br>{{searchDataObj.NoOfNights}} Nights</div>
                                         
                                     

                                      </div>
                                 
                                 
                                          <div class="col-sm-12 padding0">
                                         Rate Includes:- {{roomDataObj.RoomDescription}} 
                                         </div>
                                 </div>
                                 
                           </div>
                       </div>
                     </div>
                     <div class="col-sm-12 margin-bot5px padding0">
                     <div class="col-sm-2 padding0"><a class="btn-primary addon_but active" id="sightseeing_but" style="cursor:pointer;">Add sightseeing</a> </div>
                      <div class="col-sm-2 padding0"><a class="btn-primary addon_but" id="transfer_but">Add Transport</a> </div>
                       <div class="col-sm-2 padding0"><a class="btn-primary addon_but" id="flight_but">Add Flight</a> </div>
                       <div class="col-sm-2 padding0"><a class="btn-primary addon_but" id="markup_but">Add Markup</a> </div>
                     </div>
                   </div>
                   
<div class="panel panel-default" id="add-sightseeing" style="margin-bottom:10px;">
                     <div class="panel-heading active" style="border-bottom:1px solid #999;">
                       <h3 class="panel-title">
                         <a class="" data-toggle="collapse" data-parent="#accordion1">
                     Add Sightseeing
                           
                         </a>
                       </h3>
                     </div>
                     <div id="collapseThree2" class="">
                       <div class="panel-body bordreveew">
                       <p class="red">All rate are in INR and are average price per person. please click on the relevant option to add these to the cart</p>
                       
                       <div class="col-sm-7 pull-right">
                           <div class="col-sm-4">Select Markup Type</div>
                         <div class="col-sm-8 padding0">
                          <div class="col-sm-6">
                                                  <label class="checkcontainer" style="color:#333;">Percent (%)
                           <input type="radio" checked="checked" id="MarkupPercent" name="markup-type">
                           <span class="radiobtn"></span>
                         </label>
                           </div>    
                           
                            <div class="col-sm-6">
                                                  <label class="checkcontainer" style="color:#333;">Fixed
                           <input type="radio"  id="markupFixed" name="markup-type">
                           <span class="radiobtn"></span>
                         </label>
                           </div>      
                         </div>
                       </div>
                       <div class="table-responsive">          
<table class="table" >
<thead>
<tr>
<th width="2%"></th>
 <th>Sightseeing Name</th>
 
  <th>Price</th>
  
  <th width="10%">Markup</th>
 <th>More Detail</th>

</tr>
</thead>
<tbody>
<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
     Add 2 Lunches at Casuarina Hotel (total cost for 2 lunches)
 </td>

 <td>
 <i class="fa fa-inr"></i> 500
  </td>
  
  <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
<td>
 <a class="pointer" data-toggle="modal" data-target="#sightseeing-detail">More Info</a>
 </td>
</tr>

<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
     Add 3 Lunches at Casuarina Hotel (total cost for 3 lunches)
 </td>

 <td>
 <i class="fa fa-inr"></i> 500
  </td>
   <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
<td>
  <a class="pointer" data-toggle="modal" data-target="#sightseeing-detail">More Info</a>
 </td>
</tr>

<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
          Air Fare BOMMRUBOM (on request- min 6 nights stay) Flight blocks available
 </td>

 <td>
 <i class="fa fa-inr"></i> 500
  </td>
   <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
<td>
  <a class="pointer" data-toggle="modal" data-target="#sightseeing-detail">More Info</a>
 </td>
</tr>

<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
     Air Fare DELMRUDEL (on request- min 6 nights stay) Flight blocks available.
 </td>

 <td>
 <i class="fa fa-inr"></i> 500
  </td>
   <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
<td>
  <a class="pointer" data-toggle="modal" data-target="#sightseeing-detail">More Info</a>
 </td>
</tr>

<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
     Blue Safari Submarine with SIC Transfers North Hotels
 </td>

 <td>
 <i class="fa fa-inr"></i> 500
  </td>
   <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
<td>
  <a class="pointer" data-toggle="modal" data-target="#sightseeing-detail">More Info</a>
 </td>
</tr>

<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
     Casela Adventure Park Tour on SIC Basis Including Casela Entrance (Tue,Thu, Sat only)
 </td>

 <td>
 <i class="fa fa-inr"></i> 500
  </td>
   <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
<td>
  <a class="pointer" data-toggle="modal" data-target="#sightseeing-detail">More Info</a>
 </td>
</tr>


</tbody>
</table>
</div>
     <div class="col-sm-2 pull-right" style="padding-right:29px;" align="right"><a href="#" class="btn-primary">Add</a> </div>                   </div>
                     </div>
                   </div>
                   
                   
                   <div class="panel panel-default" id="add-transfer" style="margin-bottom:10px; display:none;">
                     <div class="panel-heading active" style="border-bottom:1px solid #999;">
                       <h3 class="panel-title">
                         <a class="" data-toggle="collapse" data-parent="#accordion1">
                     Add Transfer
                           
                         </a>
                       </h3>
                     </div>
                     <div id="collapseThree2" class="">
                       <div class="panel-body bordreveew">
                       <p class="red">All rate are in INR and are average price per person. please click on the relevant option to add these to the cart</p>
                       <div class="col-sm-7 pull-right">
                           <div class="col-sm-4">Select Markup Type</div>
                         <div class="col-sm-8 padding0">
                          <div class="col-sm-6">
                                                  <label class="checkcontainer" style="color:#333;">Percent (%)
                           <input type="radio" checked="checked" id="MarkupPercent" name="markup-type">
                           <span class="radiobtn"></span>
                         </label>
                           </div>    
                           
                            <div class="col-sm-6">
                                                  <label class="checkcontainer" style="color:#333;">Fixed
                           <input type="radio"  id="markupFixed" name="markup-type">
                           <span class="radiobtn"></span>
                         </label>
                           </div>      
                         </div>
                       </div>
                       <div class="table-responsive">          
<table class="table" >
<thead>
<tr>
<th width="2%"></th>
 <th width="62%">Transports</th>
 
  <th>By Coach</th>
 <th>By Car</th>
  <th width="10%">Markup</th>

</tr>
</thead>
<tbody>
<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
     Air Port Pickup
 </td>

 <td>
 <i class="fa fa-inr"></i> 1200
  </td>
<td>
  <i class="fa fa-inr"></i> 3000
 </td>
   <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
</tr>

<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
     Air Port Drop
 </td>

 <td>
 <i class="fa fa-inr"></i> 2000
  </td>
<td>
  <i class="fa fa-inr"></i> 3000
 </td>
   <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
</tr>

<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
     Bus Staion Pickup
 </td>

 <td>
 <i class="fa fa-inr"></i> 1200
  </td>
<td>
  <i class="fa fa-inr"></i> 3000
 </td>
   <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
</tr>

<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
     Bus Staion Drop
 </td>

 <td>
 <i class="fa fa-inr"></i> 1200
  </td>
<td>
  <i class="fa fa-inr"></i> 3000
 </td>
   <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
</tr>

<tr>
 <th>
  
 </th>
 <td>
     <strong>Inter State Transport</strong>
 </td>

 <td>
 <strong>By Coach</strong>
  </td>
<td>
 <strong>By Car</strong>
 </td>
   <td>
 Markup
  </td>
</tr>

<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
     Kathmandu	-	Pokhra
 </td>

 <td>
 <i class="fa fa-inr"></i> 700
  </td>
<td>
  <i class="fa fa-inr"></i> 1700
 </td>
   <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
</tr>

<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
     Kathmandu	-	Chitwan
 </td>

 <td>
 <i class="fa fa-inr"></i> 700
  </td>
<td>
  <i class="fa fa-inr"></i> 1700
 </td>
   <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
</tr>

<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
     Kathmandu	-	Dhulikhel
 </td>

 <td>
 <i class="fa fa-inr"></i> 700
  </td>
<td>
  <i class="fa fa-inr"></i> 1700
 </td>
   <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
</tr>

<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
     Kathmandu	-	Janakpur
 </td>

 <td>
 <i class="fa fa-inr"></i> 700
  </td>
<td>
  <i class="fa fa-inr"></i> 1700
 </td>
   <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
</tr>

<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
     Kathmandu	-	Biratnagar
 </td>

 <td>
 <i class="fa fa-inr"></i> 700
  </td>
<td>
  <i class="fa fa-inr"></i> 1700
 </td>
   <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
</tr>


</tbody>
</table>
</div>
                     <div class="col-sm-2 pull-right" style="padding-right:8px;" align="right"><a href="#" class="btn-primary">Add</a> </div>
                    
                       </div>
                       
                       
                     </div>
                   </div>
                   
                   
                   <div class="panel panel-default" id="add-flight" style="margin-bottom:10px; display:none;">
                     <div class="panel-heading active" style="border-bottom:1px solid #999;">
                       <h3 class="panel-title">
                         <a class="" data-toggle="collapse" data-parent="#accordion1">
                     Add Flight
                           
                         </a>
                       </h3>
                     </div>
                     <div id="collapseThree2" class="">
                       <div class="panel-body bordreveew">
                       <p class="red">All rate are in INR and are average price per person. please click on the relevant option to add these to the cart</p>
                       <div class="col-sm-7 pull-right">
                           <div class="col-sm-4">Select Markup Type</div>
                         <div class="col-sm-8 padding0">
                          <div class="col-sm-6">
                                                  <label class="checkcontainer" style="color:#333;">Percent (%)
                           <input type="radio" checked="checked" id="MarkupPercent" name="markup-type">
                           <span class="radiobtn"></span>
                         </label>
                           </div>    
                           
                            <div class="col-sm-6">
                                                  <label class="checkcontainer" style="color:#333;">Fixed
                           <input type="radio"  id="markupFixed" name="markup-type">
                           <span class="radiobtn"></span>
                         </label>
                           </div>      
                         </div>
                       </div>
                       <div class="table-responsive">          
<table class="table" >
<thead>
<tr>
<th width="2%"></th>
 <th width="75%">Flight</th>
 
 
 <th>Price</th>
  <th width="10%">Markup</th>

</tr>
</thead>
<tbody>
<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
     Air Port Pickup
 </td>


<td>
  <i class="fa fa-inr"></i> 3000
 </td>
   <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
</tr>

<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
     Air Port Drop
 </td>


<td>
  <i class="fa fa-inr"></i> 3000
 </td>
   <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
</tr>

<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
     Bus Staion Pickup
 </td>


<td>
  <i class="fa fa-inr"></i> 3000
 </td>
   <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
</tr>

<tr>
 <td>
  <input id="loginhave" value="loginhave" type="checkbox" name="loginhave" ><label for="showwithhotel1" style="font-size:12px;"><span></span> </label>
 </td>
 <td>
     Bus Staion Drop
 </td>


<td>
  <i class="fa fa-inr"></i> 3000
 </td>
   <td>
 <input type="text" class="form-control bokingtextinput">
  </td>
</tr>

</tbody>
</table>
</div> <div class="col-sm-2 pull-right" style="padding-right:8px;" align="right"><a href="#" class="btn-primary">Add</a> </div>
                       </div>
                     </div>
                   </div>
                   
                   <div class="panel panel-default" id="add-markup" style="margin-bottom:10px; display:none;">
                     <div class="panel-heading active" style="border-bottom:1px solid #999;">
                       <h3 class="panel-title">
                         <a class="" data-toggle="collapse" data-parent="#accordion1">
                     Add Markup
                           
                         </a>
                       </h3>
                     </div>
                     <div id="collapseThree2" class="">
                       <div class="panel-body bordreveew">
                       <!--<p class="red">All rate are in INR and are average price per person. please click on the relevant option to add these to the cart</p>-->
                       
                       <div class="form-group form-group-lg form-group-icon-left form-group-filled" style="margin-bottom:0;">
                       <div  class="col-sm-2">Add Markup</div>
                                             <div class="col-sm-4">
                                                 <input placeholder="" class="form-control  bokingtextinput" name="from" id="checkin" type="text"></div>
                                                 
                                                  <div  class="col-sm-2"> Markup Type</div>
                                             <div class="col-sm-4 padding0">
                                             <div class="col-sm-6">
                                                  <label class="checkcontainer" style="color:#333;">Percent (%)
                           <input type="radio" checked="checked" id="MarkupPercent" name="markup-type">
                           <span class="radiobtn"></span>
                         </label>
                           </div>    
                           
                            <div class="col-sm-6">
                                                  <label class="checkcontainer" style="color:#333;">Fixed
                           <input type="radio"  id="markupFixed" name="markup-type">
                           <span class="radiobtn"></span>
                         </label>
                           </div>                     
                                                 </div>
                                                 
                                                  <div class="col-sm-5 pull-right" align="center"><a href="#" class="btn-primary">Add</a> </div>
                                                 
                                                 
                                             </div>
                       </div>
                     </div>
                   </div>
                                            
                   <div class="panel panel-default" style="margin-bottom:10px;" >
                     
                     <div id="collapseThree2" class="">
                       <div class="panel-body bordreveew"  *ngIf="!isLoggedIn;else notLoggedIn">
                        <div class="col-sm-6">
                          <form [formGroup]="guestForm">
                            <div class="row">
                              <div class="col-md-6">
                                <input type="text" formControlName="firstname" class="form-control bokingtextinput" placeholder="Enter FirstName">
                              </div>
                              <div class="col-md-6">
                                <input type="text" formControlName="lastname" class="form-control bokingtextinput" placeholder="Enter LastName">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <input type="text" formControlName="email" class="form-control bokingtextinput" placeholder="Enter Email Address">
                              </div>
                              <div class="col-md-6">
                                <input type="text" formControlName="phone" class="form-control bokingtextinput" placeholder="Enter Phone Number">
                              </div>
                            </div>
                        </form> 
                        <div class="row">
                         <div class="col-sm-6"  style="margin-left: 10px;">
                             <input type="button" class="btn-primary" (click)="onClickBookAsGuest()" value="Book as a Guest">
                         </div>  
                        </div>

                        </div>
                         <div class="col-sm-1"><div style="margin-top:50px; background:#333; border-radius:50%; padding:7px 10px; color:#fff;">OR</div></div>
                          <div class="col-sm-5" align="center" style="margin-top:50px;">
                            <a href="#" class="btn-primary" >SignIn</a> 
                            <a href="#" class="btn-primary"style="margin-left:20px;">SignUp</a> 
                          </div>
                       </div>
                       <ng-template #notLoggedIn>
                        <div class="panel-body bordreveew">
                        <div class="col-sm-12 padding0" id="autoUpdate">

                                               
                                               <div class="col-sm-6 padding0">
                                                   <input type="button" class="btn-primary" (click)="onProceedBooking()" value="Proceed to Book">
                                               </div>
                                               </div>
                                              </div>
                                       </ng-template>
                     </div>
                     
                   </div>
                  
                 </div><!--/#accordion1-->

             </div>
         </div>


                           </div>
                     
                     
                  
                     </div>
                    
                 </div>
             </div>
             
             
             <div class="col-md-3 col-sm-5 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="600ms" style="padding-right:0;">
                     
                  <div class="col-sm-12 col-md-12 col-xl-12 margin5" style="background:#fff; color:#333;" >
                              <h2> Summary </h2>              
                   <label class="packagelabel"> <span class="colorred"> <h2 style="margin:2px; line-height:6px;"> <i class="fa fa-inr"></i>{{hotelRoomDetails?.Price?.PublishedPriceRoundedOff}}/-<br>

                   <span style="font-size:11px;">Per Room Per Night</span>
                   </h2></span></label>

                        <label class="packagelabel"> 
                     <div class="form-group form-group-lg form-group-icon-left form-group-filled" style="margin-bottom:0;">
                                             
                      <input placeholder="{{searchDataObj?.CheckInDate}}" class="form-control regencydatepicar bokingtextinput" name="from" id="checkin" type="text" readonly>
                                             </div>
                     </label>

                     <label class="packagelabel"> 
                     <div class="form-group form-group-lg form-group-icon-left form-group-filled" style="margin-bottom:0;">
                                             
                      <input placeholder="{{searchDataObj?.checkOutDate}}" class="form-control regencydatepicar bokingtextinput" name="from" id="checkin" type="text"  readonly>
                                             </div>
                     </label>
                     <label class="packagelabel"> 
                         <div class="form-group form-group-lg form-group-select-plus" style="margin-bottom:0;">
                                                
                                                 
                                                 <div class="select-style">
                                                     <select class="form-control">
                                                      <option>{{searchDataObj.RoomGuests[0].NoOfAdults}} Guest/{{searchDataObj.NoOfRooms}} Rooms</option>
                                                     
                                                     </select>
                                                 </div>
                                                 
                                                
                                             </div>
                     </label>
                     
                      <label class="packagelabel"> 
                         <div class="form-group form-group-lg form-group-select-plus" style="margin-bottom:0;">
                                                
                                                 
                                                 <div class="select-style">
                                                     <select class="form-control">
                                                      <option>{{roomDataObj.RoomDescription}}</option>
                                                     
                                                     </select>
                                                 </div>
                                                 
                                                
                                             </div>
                     </label>
                                                 
                 </div>
                 
                 <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                   
                     
                        <label class="packagelabel"> <h2> Inclusions </h2></label>

                 <div class="incluson-icon"><i class="fa fa-hotel"></i> Hotel</div>
                 <div class="incluson-icon"><i class="fa fa-car"></i> Transfers</div>
                 <div class="incluson-icon"><i class="fa fa-building"></i> City tour</div>
                 <div class="incluson-icon"><i class="fa fa-camera"></i> Sightseeing</div>
                 
                  <div class="incluson-icon"><i class="fa fa-coffee"></i> Breakfast</div>	
                     
                 </div>
                 
                 
                 <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff; text-align:center;" >
                   
                     
                        <label class="packagelabel"><h2 style="margin-top:10px; margin-bottom:0;"> <span class="colorred"> <i class="fa fa-inr" aria-hidden="true"></i>
                          {{hotelRoomDetails?.Price?.PublishedPriceRoundedOff * searchDataObj?.NoOfNights}}</span>
                         <br>
                         <span style="font-size:11px;">for {{searchDataObj?.NoOfRooms}} Room {{searchDataObj?.NoOfNights}} nights {{searchDataObj?.RoomGuests[0].NoOfAdults}} Person Excludes Taxes</span>
                         </h2>
                     </label>
<div class="col-sm-12 margin5 padding-bot0">
                                 <a href="#" class="" title="Add Compare"><img src="assets/images/icons/compare-add.png"></a> 
                                 <a href="#" class="" title="Add Wish List"><img src="assets/images/icons/wishlist-add.png"></a>
                             </div>
                     <label class="packagelabel colorred"> <a href="B2B-traveller_hotel.html" class="btn-primary" >Book Now</a></label>
                     
                     
                 </div>
                 
                 
                 <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                   
                     
                        

               <div class="col-sm-12 padding0">
              <label class="packagelabel"> <h2 style="margin-top:5px;"> Enquiry Now </h2></label>
               <label style="margin-right:5px;">
                                 <a href="#"  class="contact-iccons" title="Send Enquiry"><i class="fa fa-envelope" aria-hidden="true"></i>
</a>
<br>
Send Enquiry
</label>
<label style="margin-right:5px;">
<a href="#"  class="contact-iccons" title="Gat a Call Back"><i class="fa fa-phone" aria-hidden="true"></i>
</a>
<br> Gat a Call Back 
</label>
<label>
<a href="#"  class="contact-iccons" title="Refer a Friend"><i class="fa fa-user-plus"></i>
</a>
<br>
Refer a Friend
</label>
                                 
                             </div>
                     
          
                 
             </div>
             
                 <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                   
                     
                        

               <div class="col-sm-12 padding0">
              <label class="packagelabel"> <h2 style="margin-top:5px;"> Location on Map Now </h2></label>
              
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d113032.64621395394!2d85.25609251320085!3d27.708942727046708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu+44600%2C+Nepal!5e0!3m2!1sen!2sin!4v1537941812283" width="100%" height="200" frameborder="0" style="border:0" allowfullscreen></iframe>
                                 
                             </div>
                     
          
                 
             </div>
             
             
                 <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                   
                     
                        

               <div class="col-sm-12 padding0">
              <label class="packagelabel"> <h2 style="margin-top:5px;"> Near By Hotel </h2></label>
              <div class="col-sm-12 padding0 margin-bot5px">
               <label class="pull-left">
                   <img src="images/hotel_smal/2.jpg" width="70" height="70">
               </label>
               
               <div style="margin-left:10px; float:left;">
                   Shyam Place Guest House<br>
                 
                                 <label><img src="images/icons/starnew.jpg"></label>
                                  <label><img src="images/icons/starnew.jpg"></label>
                                   <label><img src="images/icons/starnew.jpg"></label>
                                    <label><img src="images/icons/starnew.jpg"></label>
                                    <label><img src="images/icons/star_blank.jpg"></label>
               </div>
               
               </div>
               
               <div class="col-sm-12 padding0 margin-bot5px">
               <label class="pull-left">
                   <img src="images/hotel_smal/2.jpg" width="70" height="70">
               </label>
               
               <div style="margin-left:10px; float:left;">
                   Shyam Place Guest House<br>
                 
                                 <label><img src="images/icons/starnew.jpg"></label>
                                  <label><img src="images/icons/starnew.jpg"></label>
                                   <label><img src="images/icons/starnew.jpg"></label>
                                    <label><img src="images/icons/starnew.jpg"></label>
                                    <label><img src="images/icons/star_blank.jpg"></label>
               </div>
               
               </div>
               
               <div class="col-sm-12 padding0 margin-bot5px">
               <label class="pull-left">
                   <img src="images/hotel_smal/2.jpg" width="70" height="70">
               </label>
               
               <div style="margin-left:10px; float:left;">
                   Shyam Place Guest House<br>
                 
                                 <label><img src="images/icons/starnew.jpg"></label>
                                  <label><img src="images/icons/starnew.jpg"></label>
                                   <label><img src="images/icons/starnew.jpg"></label>
                                    <label><img src="images/icons/starnew.jpg"></label>
                                    <label><img src="images/icons/star_blank.jpg"></label>
               </div>
               
               </div>
               
               <div class="col-sm-12 padding0 margin-bot5px">
               <label class="pull-left">
                   <img src="images/hotel_smal/2.jpg" width="70" height="70">
               </label>
               
               <div style="margin-left:10px; float:left;">
                   Shyam Place Guest House<br>
                 
                                 <label><img src="images/icons/starnew.jpg"></label>
                                  <label><img src="images/icons/starnew.jpg"></label>
                                   <label><img src="images/icons/starnew.jpg"></label>
                                    <label><img src="images/icons/starnew.jpg"></label>
                                    <label><img src="images/icons/star_blank.jpg"></label>
               </div>
               
               </div>
               
               
                                 
                             </div>
                     
          
                 
             </div>
             
         </div>
         </div><!--/.services-->
     </div><!--/.row-->    
 </div><!--/.container--><br>


</section>