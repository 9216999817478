<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h2 align="left">{{pageDetails?.page_title}}</h2>
            </div>
        <div class="center wow fadeInDown animated" id="about" style="visibility: visible; animation-name: fadeInDown;">
           <!-- <img [src]="pageDetails?.page_image">  -->
           <img [src]="bannerpath">        
            <br>
                     <!--/.row-->
                 </div>
    </div>
</div>


<div [innerHTML]="pageDetails?.page_desc"></div>