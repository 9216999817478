import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  userHotelDetail: string;
  hotelDataObj: any;
  roomDataObj: any;

  constructor() { }

  set userSearchData(value: any) {
    this.userHotelDetail = value;
 }

 get userSearchData(): any {
  return this.userHotelDetail;
}

 get hotelData(): any {
     return this.hotelDataObj;
 }

 set hotelData(value: any) {
  this.hotelDataObj = value;
}

get roomData(): any {
  return this.roomDataObj;
}

set roomData(value: any) {
this.roomDataObj = value;
}

}
