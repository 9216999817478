import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContactService } from '../../services/contact.service'
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  contactForm:FormGroup
  ipAddress: any;
  formMessage: string;
  isFormSubmit: boolean =true;
  constructor(private formBuilder:FormBuilder,private contactService:ContactService) { }

  ngOnInit(): void {
      this.contactService.getIPAddress().subscribe((res:any) =>{
      this.ipAddress = res.ip
    });
    this.contactForm = this.formBuilder.group({
      name :[""],
      email:[""],
      mobile:[""],
      subject:[""],
      companyname:[""],
      message:[""]
    })
  }

  onContactSubmit() {
    this.isFormSubmit =false;
    let contactData = {
      name:this.contactForm.value.name,
      email:this.contactForm.value.email,
      mobile :this.contactForm.value.mobile,
      company :this.contactForm.value.companyname,
      subject : this.contactForm.value.subject,
      message : this.contactForm.value.subject,
      created_date: new Date(Date.now()),
      modified_date: new Date(Date.now()),
      status : 0,
      ip : this.ipAddress
      }
    this.contactService.contactSubmit(contactData).subscribe(data =>{
      if(data) {
        this.isFormSubmit =true;
        this.formMessage ="Your Details Save Successfully. We will contact you shortly"
      }
    })
  }

}
