import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment'

@Pipe({
    name: 'dateFormatPipe',
})
export class dateFormatPipe implements PipeTransform {
    transform(value: string) {
       var datePipe = new DatePipe("en-US");
        //value = datePipe.transform(value, 'dd/MM/yyyy');
        //return value;
     let d = new Date(value)
     return moment(d).format('DD/MM/yyyy')
    }
}