import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HotelService } from '../../services/hotel.service';
declare var jssor_1_slider_init:any;
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from '../../services/data.service';
@Component({
  selector: 'app-hotel-details',
  templateUrl: './hotel-details.component.html',
  styleUrls: ['./hotel-details.component.scss']
})
export class HotelDetailsComponent implements OnInit {
  hotelDetail: any;
  showTab: string = 'photos';
  mapaddress:any ="//maps.google.com/maps?q=53.3381768,-6.2613077&z=15&output=embed";
  openAccordianTab: any;
  selectedHotel: any;
  hotelRoomList: any;
  selectedHotelRoom: any;
  selectedRoomIdForCancel: string;
  searchDataObj: any;
  constructor(private route: ActivatedRoute,private hotelservice:HotelService,private cdr:ChangeDetectorRef,private domSanitizer: DomSanitizer,private router:Router,private dataService: DataService) { }

  ngOnInit(): void {
   // this.searchDataObj = this.dataService.userSearchData;
    this.searchDataObj = JSON.parse(localStorage.getItem('userSearchData'));
    this.hotelservice.getHotelRoomDetails(this.route.snapshot.params).subscribe((resdata:any) =>{
    console.log(resdata);
    this.hotelDetail =  resdata.responsedata.data.HotelDetails;
    setTimeout(() => {
      jssor_1_slider_init(); 
    }, 100);
    
    console.log(this.hotelDetail);
    });
    this.getHotelRoomList(this.route.snapshot.params)
  }

  getSantizeUrl(url : string) { 
    return this.domSanitizer.bypassSecurityTrustUrl(url); 
}
  ngAfterViewInit() {
  
}

onTabClick(tabData){
  this.showTab = tabData;
  if(tabData ==='photos') {
    setTimeout(() => {
      jssor_1_slider_init(); 
    }, 100);
  }

  this.cdr.markForCheck();
  this.cdr.detectChanges();
}

  showStarRating(rating) {
    return Array(rating).fill(0).map((x,i)=>i);
  }

  hotelPolicyAccordian(tabValue) {
   this.openAccordianTab = tabValue;
  }

  getHotelRoomList(hotel) {
    
    this.hotelservice.getHotelRoomList(hotel).subscribe((resdata:any) =>{
      this.selectedHotel = hotel.HotelCode;
      this.hotelRoomList = resdata.responsedata.data;
      this.selectedHotelRoom = this.hotelRoomList.HotelRoomsDetails[0];
    });
  }

  showHotelReview(hotelRoom) { 
    this.dataService.roomData = hotelRoom;
    localStorage.setItem('roomData',JSON.stringify(hotelRoom));
    this.router.navigate(['hotel-review']);
  }

  showCancelationPolicy(hotelRoom){
    this.selectedRoomIdForCancel =hotelRoom.RoomIndex;
  }
  
  hideCancelationPolicy(hotelRoom){
    this.selectedRoomIdForCancel =''
  }

  
  onRoomSelection(hotelRoom) {
    this.selectedHotelRoom = hotelRoom
  }

}
