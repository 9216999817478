 
    <!--/#feature-->
    <section id="feature" style="background:#ededed; margin-top:10px;" *ngIf="hotelList.HotelResults; else noresult" >
        <div class="container">
           <div class="left wow fadeInDown">
                <h2>{{selectedCity?.STATEPROVINCE}}, {{selectedCity?.COUNTRY}} : Hotel</h2>
                
            </div>

            <div class="row">
                <div class="features col-sm-12">
                   
                    
                    <div class="col-md-2 col-sm-4 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="600ms">
                    		 <div class="col-sm-12 col-md-12 col-xl-12 padding0" style="margin-top:14px;"  >
                            <h3>Refine by Budget</h3>
                      		</div>
                         <div class="col-sm-12 col-md-12 col-xl-12 margin5" style="background:#fff;" >
                                                     
                             <div class='slider-example'>
      		
      							<div class="well">
      					
                 <ng5-slider [(value)]="minHotelPrice" [(highValue)]="maxHotelPrice" [options]="options"></ng5-slider>
      						</div>
      		
      					</div>
                             
                                                        
                        </div>
                        
                         
                         <div class="col-sm-12 col-md-12 col-xl-12 padding0 "  >
                          <h3>Search by Hotel Name</h3>
                    
                    		</div>
                        
   						 <div class="col-sm-12 col-md-12 col-xl-12 margin5 padding5pxx redio" style="background:#fff;">
                         
    	
        <input [(ngModel)]="currentSearchValue" id="byname" type="text" class="txtinpurname" name="byname" value=""> 
        <span (click) ="filterByHotelName()" class="btn"  class="search-icon"><i class="fa fa-search"></i></span>                         
                        </div>                     
                         <div class="col-sm-12 col-md-12 col-xl-12 padding0 "  >
                          <h3>  Star Rating</h3>
                    		</div>
                        <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;  padding-left:8px; padding-right:3px;" >     
      <div class="col-sm-2 col-sm-2star" (click) ="filterByRating(1)">
      1<br>
      <img  src="assets/images/icons/star.jpg">
      <label style="border-top:1px solid #ccc; margin-top:5px; margin-bottom:1px;">225</label>
      </div>
      
      <div class="col-sm-2 col-sm-2star" (click) ="filterByRating(2)">
      2<br>
      <img src="assets/images/icons/star.jpg">
      <label style="border-top:1px solid #ccc; margin-top:5px; margin-bottom:1px;">225</label>
      </div>
      
      <div class="col-sm-2 col-sm-2star" (click) ="filterByRating(3)">
       3<br>
      <img src="assets/images/icons/star.jpg">
      <label style="border-top:1px solid #ccc; margin-top:5px; margin-bottom:1px;">225</label>
      </div>
      
      <div class="col-sm-2 col-sm-2star" (click) ="filterByRating(4)">
      4<br>
      <img src="assets/images/icons/star.jpg">
      <label style="border-top:1px solid #ccc; margin-top:5px; margin-bottom:1px;">225</label>
      </div>
      
      <div class="col-sm-2 col-sm-2star" style="border-right:1px solid #ccc;" (click) ="filterByRating(5)">
       5<br>
      <img src="assets/images/icons/star.jpg">
      <label style="border-top:1px solid #ccc; margin-top:5px; margin-bottom:1px;">225</label>
      </div>                      
      </div>
                        
                        
                         <div class="col-sm-12 col-md-12 col-xl-12 padding0 "  >
                          <h3> TripAdvisor Rating</h3>
                    
                    		</div>
                        <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;  padding-left:8px; padding-right:3px;" >
                          
    	      
      <div class="col-sm-2 col-sm-2star">
      0-1<br>
      <img src="assets/images/icons/hoteladviser.png">
      <label style="border-top:1px solid #ccc; margin-top:5px; margin-bottom:1px;">225</label>
      </div>
      
      <div class="col-sm-2 col-sm-2star">
      1-2<br>
      <img src="assets/images/icons/hoteladviser.png">
      <label style="border-top:1px solid #ccc; margin-top:5px; margin-bottom:1px;">225</label>
      </div>
      
      <div class="col-sm-2 col-sm-2star">
       2-3<br>
      <img src="assets/images/icons/hoteladviser.png">
      <label style="border-top:1px solid #ccc; margin-top:5px; margin-bottom:1px;">225</label>
      </div>
      
      <div class="col-sm-2 col-sm-2star">
      3-4<br>
      <img src="assets/images/icons/hoteladviser.png">
      <label style="border-top:1px solid #ccc; margin-top:5px; margin-bottom:1px;">225</label>
      </div>
      
      <div class="col-sm-2 col-sm-2star" style="border-right:1px solid #ccc;">
       4-5<br>
      <img src="assets/images/icons/hoteladviser.png">
      <label style="border-top:1px solid #ccc; margin-top:5px; margin-bottom:1px;">225</label>
      </div>
    
   <!-- <div class="check"></div>-->
 
                            
                        </div>
                        
                        
                         <div class="col-sm-12 col-md-12 col-xl-12 padding0 "  >
                          <h3>Location on Map</h3>
                    
                    		</div>
                        
    					 <div class="col-sm-12 col-md-12 col-xl-12 margin5 padding5pxx redio" style="background:#fff;">
                         
    	
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d113032.64621395394!2d85.25609251320085!3d27.708942727046708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu+44600%2C+Nepal!5e0!3m2!1sen!2sin!4v1537941812283" width="100%" height="200" frameborder="0" style="border:0" allowfullscreen></iframe>
     
    
   <!-- <div class="check"></div>-->
  
                            
                        </div>
                        
                         <div class="col-sm-12 col-md-12 col-xl-12 padding0 "  >
                           <h3>Show Hotels With</h3>
                    
                    		</div>
                        <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                          <ul>
  <li>
    	<div>
        <input id="showwithhotel" type="checkbox" name="showwithhotel" value="1" checked="checked">
        <label for="showwithhotel"><span></span>Free Cancellation</label>
      </div>
    
   <!-- <div class="check"></div>-->
  </li>
  
  <li>
   <div>
        <input id="showwithhotel1" type="checkbox" name="showwithhotel1" value="2" checked="checked"><label for="showwithhotel1"><span></span>Pay at Hotel </label>
      </div>
    
    
  </li>
  
  <li>
    <div>
        <input id="showwithhotel2" type="checkbox" name="showwithhotel2" value="3" checked="checked"><label for="showwithhotel2"><span></span>Free WiFi</label>
      </div>
  </li>
  
  <li>
    <div>
        <input id="showwithhotel3" type="checkbox" name="showwithhotel3" value="4" checked="checked"><label for="showwithhotel3"><span></span>Free Airport Drop</label>
      </div>
  </li>
  
  <li>
    <div>
        <input id="showwithhotel4" type="checkbox" name="showwithhotel4" value="5" checked="checked"><label for="showwithhotel4"><span></span>Breakfast Buffet </label>
      </div>
  </li>
  
  <li>
    <div>
        <input id="showwithhotel5" type="checkbox" name="showwithhotel5" value="6" checked="checked"><label for="showwithhotel5"><span></span>Free WiFi</label>
      </div>
  </li>
</ul>
                            
                        </div>
                        
                         <div class="col-sm-12 col-md-12 col-xl-12 padding0"  >
                             <h3>Offer</h3>
                    
                    		</div>
                        <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                          <ul>
  <li>
    	<div>
        <input id="offer" type="checkbox" name="offer" value="1" checked="checked"><label for="offer"><span></span>Option 1</label>
      </div>
    
   <!-- <div class="check"></div>-->
  </li>
  
  <li>
   <div>
        <input id="offer1" type="checkbox" name="offer1" value="1" checked="offer"><label for="offer1"><span></span>Option 1</label>
      </div>
    
    
  </li>
  
  <li>
    <div>
        <input id="offer2" type="checkbox" name="offer2" value="1" checked="checked"><label for="offer2"><span></span>Option 1</label>
      </div>
  </li>
</ul>
                            
                        </div>
                        
                         <div class="col-sm-12 col-md-12 col-xl-12 padding0"  >
                             <h3>Hotel Property</h3>
                    
                    		</div>
                        <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                          <ul>
  <li>
    	<div>
        <input id="hotalproperty" type="checkbox" name="hotalproperty" value="1" checked="checked"><label for="hotalproperty"><span></span>Option 1</label>
      </div>
    
   <!-- <div class="check"></div>-->
  </li>
  
  <li>
   <div>
        <input id="hotalproperty1" type="checkbox" name="hotalproperty1" value="1" checked="offer"><label for="hotalproperty1"><span></span>Option 1</label>
      </div>
    
    
  </li>
  
  <li>
    <div>
        <input id="hotalproperty2" type="checkbox" name="hotalproperty2" value="1" checked="checked"><label for="hotalproperty2"><span></span>Option 1</label>
      </div>
  </li>
</ul>
                            
                        </div>
                        
                        
                         <div class="col-sm-12 col-md-12 col-xl-12 padding0"  >
                             <h3>Hotel Aminities </h3>
                    
                    		</div>
                        <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                          <ul>
  <li>
    	<div>
        <input id="aminities" type="checkbox" name="aminities" value="1" checked="checked"><label for="aminities"><span></span>Bar </label>
      </div>
    
   <!-- <div class="check"></div>-->
  </li>
  
  <li>
   <div>
        <input id="aminities1" type="checkbox" name="aminities1" value="1" checked="offer"><label for="aminities1"><span></span>Breakfast Services </label>
      </div>
    
    
  </li>
  
  <li>
    <div>
        <input id="aminities3" type="checkbox" name="aminities3" value="1" checked="checked"><label for="aminities3"><span></span>Swimming pool </label>
      </div>
  </li>
</ul>
                            
                        </div>
                        
                        
                         <div class="col-sm-12 col-md-12 col-xl-12 padding0"  >
                             <h3>Hotel CHAINS</h3>
                    
                    		</div>
                        <div class="col-sm-12 col-md-12 col-xl-12 margin5 redio" style="background:#fff;" >
                          <ul>
  <li>
    	<div>
        <input id="hotelchains" type="checkbox" name="hotelchains" value="1" checked="hotelchains"><label for="hotelchains"><span></span>Accor Hotels</label>
      </div>
    
   <!-- <div class="check"></div>-->
  </li>
  
  <li>
   <div>
        <input id="hotelchains1" type="checkbox" name="hotelchains1" value="1" checked="offer"><label for="hotelchains1"><span></span>Carlson Hotels </label>
      </div>
    
    
  </li>
  
  <li>
    <div>
        <input id="hotelchains2" type="checkbox" name="hotelchains2" value="1" checked="checked"><label for="hotelchains2"><span></span>Choice Hotels</label>
      </div>
  </li>
</ul>
                            
                        </div>
                        
                    </div>
                    
                    <div class="col-md-10 col-sm-12 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="600ms">
                         <div class="" style="margin-top:32px;">
                         <div class="">
                            <div class="col-sm-12 redio borderregency padding5topbot" style="background:#fff;" >
                            	<div class="col-sm-2 padding0">Sort Results By:</div>
                                <a (click)="onNameShorting()" class="btn shortingbutton"><div class="col-sm-1 padding0">Name</div></a>
                                 <a (click)="onPriceShorting()" class="btn shortingbutton"><div class="col-sm-1 padding0">Price</div></a>
                                 <!-- <a href="#"><div class="col-sm-1 padding0">Duration</div></a> -->
                                   <div class="col-sm-2 pull-right padding0" align="left">
                                   	<div class="select-style">
															<select class="form-control">
                                                             <option>Select Currency</option>
															 <option>INR</option>
                                                            <option>USD</option>
                                                            <option>ERO</option>
                                                            
															</select>
														</div>
                                   </div>
                               
                                  <div class="col-sm-1 col-sm-1new pull-right padding0" align="left"><a href="#" id="list"><img src="assets/images/listicon.png"></a></div>
                                   <div class="col-sm-1 pull-right col-sm-1new padding0" align="left"><a href="#" id="grid"><img src="assets/images/gridicon.png"></a></div>
                                 
                            </div>
                            <ng-container *ngFor="let hotel of finalhotelList| paginate: config">
                            <div class="col-sm-12 col-md-12 col-xl-12 padding0 redio borderregency list" style="background:#fff;" >
                            <h4 class="padding-left10px">{{selectedCity?.STATEPROVINCE}}</h4>
                            	<div class="col-sm-3 col-sm-3img listpaddingboot"><a href="B2bhotel_detailpage.html"><img [src]="hotel.HotelPicture" width="100%"></a>
                                <div class="zoom-icon-new " style="display: block;">
                         <a href="#" class="add_wishlist" ><i class="offer_img" title="Add to Wishlist" ></i></a>
                         <div class="offer_img1" align="center">With Flight</div>
						
<!--<div style="width:60px; float:right;" class="img item_add">
<a href="" productid="1" productqty="1" productprice="" class="add_to_Cart" style="width:60px; margin-right:0; margin-top:15px;">
<i class="glyphicon glyphicons-shopping-cart icon"><img src="/cart.png" title="Add to cart" alt="Add to cart" style="width:20px !important; height:20px !important;"></i></a>
</div>-->
						</div>
                                
                                </div>
                                <div class="col-sm-7">
                                	<div class="col-sm-12 padding0">
                                    	<div class="col-sm-5 padding0">
                                    	<h3 class="h3new">{{hotel.HotelName}}</h3>
                                       <ng-container *ngFor="let i of showStarRating(hotel.StarRating)" >
                                        <label><img src="assets/images/icons/starnew.jpg"></label>
                                      </ng-container>
                                      <ng-container *ngFor="let i of showStarRating(5 -hotel.StarRating)">
                                           <label><img src="assets/images/icons/star_blank.jpg"></label></ng-container>  &nbsp;&nbsp;
                                            <label><img src="assets/images/icons/hoteladviser1.png"></label> Excellent <span style="color:green;">{{hotel.StarRating}}</span>/5
                                          
                                           </div>
                                           <div class="col-sm-5 padding0">
                                           <div class="padding0" align="left"><a href="#" id="map"><img src="assets/images/icons/mapicon.jpg"></a></div>
                                           <div class="padding0" align="left"><a href="#" id="map">Covid Facility</a></div>  
                                           </div>

                                        <div class="col-sm-12 padding0 holidaytext">
                                       {{hotel.HotelDescription}}
                                        </div>
                                        
                                        <div class="col-sm-7 padding0">
                                        <div class="col-sm-6 padding0">
                                           <label class="col-sm-12 padding0">
                                           <strong>Highlight</strong>
                                           </label>
                                           <div class="col-sm-12 padding0" >
                                           <label class="lablecalsicon"><i class="fa fa-wifi" aria-hidden="true" title="Wifi"></i></label>
                                           <label class="lablecalsicon"><i class="fa fa-cutlery" aria-hidden="true" title="Meal"></i></label>
                                           <label class="lablecalsicon"><i class="fa hrestaurant" aria-hidden="true" title="Restaurant"></i></label>
                                           <label class="lablecalsicon"><i class="fa hroomservice" aria-hidden="true" title="Room Services"></i></label>
                                             
                                           </div>
                                          </div>
                                          <div class="col-sm-6 padding0">
                                           <label>
                                           <strong>Inclusions</strong>
                                           </label>
                                           <div class="col-sm-12 padding0" >
                                             <label class="lablecalsicon"><i class="fa fa-hotel" aria-hidden="true" title="Hotel"></i></label>
                                           <label class="lablecalsicon"><i class="fa fa-car" aria-hidden="true" title="Transfer"></i></label>
                                            <label class="lablecalsicon"><i class="fa fa-building" aria-hidden="true" title="City Tour"></i></label>
                                           <label class="lablecalsicon"><i class="fa fa-camera" aria-hidden="true" title="Sightseeing"></i></label>
                                           <label class="lablecalsicon"><i class="fa fa-coffee" aria-hidden="true" title="Breakfast"></i></label>
                                             
                                           </div>
                                           
                                           </div>
                                           </div>
                                       
                                    </div>
                                </div>
                                
                                <div class="col-sm-2 padding0 mobileprice border-left margin5 padding-bot0" align="center">
                                 	<div class="col-sm-12 padding0">
                                    <label>Room Price<br></label>
                                    	<p style="margin-bottom:0;" class="priceheader">
                                        
                                        <i class="fa fa-inr" aria-hidden="true"></i>{{hotel.Price.OfferedPriceRoundedOff}}/-<br>
                                     <!--  <span style="font-size:9px;"> (Per Night Price)</span>-->
                                        </p>
                                        <!-- <label>Offered Price<br></label>
                                        <p style="margin-bottom:0;" class="priceheader">
                                          
                                          <i class="fa fa-inr" aria-hidden="true"></i>{{hotel.Price.OfferedPriceRoundedOff}}/-<br> -->
                                       <!--  <span style="font-size:9px;"> (Per Night Price)</span>-->
                                          <!-- </p> -->

                                    </div>
                                   <div class="col-sm-12 margin5 padding-bot0">
                                    	<a href="#" class="" title="Add Compare"><img src="assets/images/icons/compare-add.png"></a> 
                                        <a href="#" class="" title="Add Wish List"><img src="assets/images/icons/wishlist-add.png"></a>
                                    </div>


                                    <div class="col-sm-12 padding0 " style="padding-bottom:5px;">
                                    	<a (click)="getHotelRoomList(hotel)"  class="btn select_but">Select</a>
                                      <a (click)="getHotelDetails(hotel)"  class="btn select_but">Hotel Details</a> 
                                    </div>
                                    
                                    <div class="col-sm-12 margin5 padding-bot0">
                                    	<a href="#"  class="contact-iccons" title="Send Enquiry"><i class="fa fa-envelope" aria-hidden="true"></i>
</a>
<a href="#"  class="contact-iccons" title="Gat a Call Back"><i class="fa fa-phone" aria-hidden="true"></i>
</a>
<a href="#"  class="contact-iccons" title="Refer a Friend"><i class="fa fa-user-plus"></i>
</a>
                                        
                                    </div>
                                    
                                 
                                 </div>
                                 <div class="padding0" *ngIf="selectedHotel === hotel.HotelCode && hotelRoomList">
                                 	 <div class="table-responsive">          
  <table class="table" >
    <thead>
      <tr style="background:#6f021a; color:#fff;">
        <th>Room Category</th>
        
         <th>Meals</th>
        <th>Offer</th>
        <th >Status </th>
        
        <th>Price</th>
            <th>T&C</th>
             <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let hotelRoom of hotelRoomList.HotelRoomsDetails">
        <td>
        <label class="checkcontainer">{{hotelRoom.RoomTypeName}}<span class="offer-text">offer</span>
  								<input type="radio" checked="checked" id="onewaycity" name="hotel-cat">
  								<span class="radiobtn"></span>
								</label>
        </td>
        <td>
        	 HB
        </td>
       
        <td>
        <div >
          <span *ngFor="let inclusion of hotelRoom.Amenities">{{inclusion}},</span></div>
        
        </td>
        <td>         
       <div>{{hotelRoom.AvailabilityType}}</div>
        </td>
       
        <td>         
       <div (mouseenter)="showDetailPrice(hotelRoom)" (mouseleave)="hideDetailPrice(hotelRoom)">{{hotelRoom.Price.OfferedPriceRoundedOff}}</div>
       <ng-continer *ngIf="selectedRoomId === hotelRoom.RoomIndex">
       <table border="1" class="db_table">
        <tbody>
            <tr >
                <th></th>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th>Sat</th>
                <th>Sun</th>
            </tr>
            
            <tr *ngFor="let weeksamount of chunkweekWithAmont;let i=index;">
                <th>Week {{i+1}}</th>  
                <td *ngFor="let weekamount of weeksamount">{{weekamount.amount}}</td>  
            </tr>
            
            <tr>
                <th colspan="8">Rate Summary</th>
            </tr>
            <tr>
                <td colspan="4" class="bold tboleft">Currency</td>
                <td colspan="4" id="currency_1">{{hotelRoom.Price.CurrencyCode}}</td>
            </tr>
            <tr >
                <td colspan="4" class="bold tboleft">Total</td>
                <td colspan="4" id="totalFare_1">{{hotelRoom.Price.RoomPrice}}</td>
            </tr>
            <tr >
                <td colspan="4" class="bold tboleft">Tax(+)</td>
                <td colspan="4" id="tax_1">{{hotelRoom.Price?.GST?.TaxableAmount | number : '1.2-2'+ hotelRoom.Price?.Tax | number : '1.2-2'}}</td>
            </tr>
            <tr >
                <td colspan="4" class="bold tboleft">Discount(-)</td>
                <td colspan="4" id="discount_1">{{hotelRoom.Price.Discount| number : '1.2-2'}}</td>
            </tr>
            <tr >
                <td colspan="4" class="bold tboleft">Extra Guest Charge(+)</td>
                <td colspan="4" id="extraGuestRequest_1">{{hotelRoom.Price.ExtraGuestCharge | number : '1.2-2'}}</td>
            </tr>
            <tr >
                <td colspan="4" class="bold tboleft">Service Charges(+)</td>
                <td colspan="4" id="serviceCharges_1">{{hotelRoom.Price.ServiceCharge | number : '1.2-2'}}</td>
            </tr>
            <tr >
                <td colspan="4" class="bold tboleft">No. of Rooms</td>
                <td colspan="4" id="noOfRooms_1">1</td>
            </tr>
            <tr >
                <td colspan="4" class="bold tboleft">Total Price</td>
                <td colspan="4" id="totalPrice_1">{{hotelRoom.Price.PublishedPriceRoundedOff}}</td>
            </tr>
        </tbody>
      </table>
      </ng-continer>
         </td>
        
       
       
        <td>         
       <div> <a (mouseenter)="showCancelationPolicy(hotelRoom)" (mouseleave)="hideCancelationPolicy(hotelRoom)">Cancellation Policy</a></div>
       <div class="cancellation_module">
    
       <ng-container  *ngIf="selectedRoomIdForCancel === hotelRoom.RoomIndex">
        
         <table border="1" class="db_table">
            <tbody>
                <tr>
                    <th align="left">Cancelled on or After</th>
                    <th align="left">Cancelled on or Before</th>
                    <th align="left">Cancellation Charges</th>
                </tr>
                    <tr *ngFor="let cancelCharge of hotelRoom.CancellationPolicies">
                      
                            <td align="left">{{cancelCharge.FromDate | date: 'dd/MM/yyyy'}}</td>
                            <td align="left">{{cancelCharge.ToDate | date: 'dd/MM/yyyy'}}</td>
                            <td align="left">
                              {{cancelCharge.Charge}}%
                            </td>                         
                    </tr>  
            </tbody>
        </table>
      </ng-container>
</div>
        </td>
        <td><a href="" class="select_but">Book</a></td>
        
      </tr>
    
    </tbody>
  </table>
  </div>
                                 
                                 </div>
                                 
                            </div>
                          </ng-container>
                          <pagination-controls *ngIf="this.finalhotelList?.length>0"(pageChange)="pageChanged($event)"></pagination-controls>
                                                        
                            <div class="col-sm-3 col-md-3 col-xl-3 col-md-3grid redio grid">
                            		<div class="col-sm-12 padding12top">
                                <div class="col-sm-12 padding0"><img src="assets/images/hotelimage.png" width="100%"></div>
                                <div class="col-sm-12 padding0">
                                	<div class="col-sm-12 padding0">
                                    	<DIV class="col-sm-12 padding0" style="line-height:3px;">
                                    	<h3 class="h3new gridh3">Radisson Blu Hotel</h3>
                                        <label class="">New Delhi Dwarka</label>
                                        </DIV>
                                       <div class="col-sm-12 padding0 ">
                                        
                                        <label><img src="assets/images/icons/starnew.jpg"></label>
                                         <label><img src="assets/images/icons/starnew.jpg"></label>
                                          <label><img src="assets/images/icons/starnew.jpg"></label>
                                           <label><img src="assets/images/icons/starnew.jpg"></label>
                                           <label><img src="assets/images/icons/star_blank.jpg"></label> <br>
                                            <label><img src="assets/images/icons/hoteladviser1.png"></label> Excellent <span style="color:green;">4.5</span>/5 <span style="padding-left:5px;"><a href="#">Map</a></span>
                                          
 										
                                        </div>
                                         <div class="col-sm-12 padding0" >
                                             <label class="lablecalsicon"><i class="fa fa-wifi" aria-hidden="true" title="Wifi"></i></label>
                                           <label class="lablecalsicon"><i class="fa fa-cutlery" aria-hidden="true" title="Meal"></i></label>
                                           <label class="lablecalsicon"><i class="fa hrestaurant" aria-hidden="true" title="Retaurant"></i></label>
                                           <label class="lablecalsicon"><i class="fa hroomservice" aria-hidden="true" title="Room Services"></i></label>
                                             <label class="lablecalsicon"><i class="fa fa-wifi" aria-hidden="true" title="Wifi"></i></label>
                                           
                                          
                                           </div>
                                        
                                        
                                        
                                    </div>
                                </div>
                                 
                                 
                                 <div class="col-sm-6 padding0 priceregencygrid " style="margin-top:10px;">
                                    	<p style="margin-bottom:0; line-height:6px;" class="priceheadergrid">
                                        <i class="fa fa-inr" aria-hidden="true"></i>1500/-
                                       <span style="font-size:10px;"> Tour Price</span>
                                        </p>

                                    </div>
                                    
                                    <div class="col-sm-6 padding0" align="" style="margin-top:5px;">
                                 	
                                  <!--  <div class="col-sm-6 margin5 padding0">
                                    	<a href="#" class="compare_butgrid">Compare</a>
                                    </div>-->


                                    <div class="col-sm-12 margin5 padding0">
                                    	<a href="holiday_detailpage.html" class="select_butgrid">Select</a>
                                    </div>
                                    
                                 
                                 </div>
                                 
                                 
                                 </div>
                            </div>
                            
                           <div class="col-sm-3 col-md-3 col-xl-3 col-md-3grid redio grid">
                            		<div class="col-sm-12 padding12top">
                                <div class="col-sm-12 padding0"><img src="assets/images/hotelimage.png" width="100%"></div>
                                <div class="col-sm-12 padding0">
                                	<div class="col-sm-12 padding0">
                                    	<DIV class="col-sm-12 padding0" style="line-height:3px;">
                                    	<h3 class="h3new gridh3">Radisson Blu Hotel</h3>
                                        <label class="">New Delhi Dwarka</label>
                                        </DIV>
                                       <div class="col-sm-12 padding0 ">
                                        
                                        <label><img src="assets/images/icons/starnew.jpg"></label>
                                         <label><img src="assets/images/icons/starnew.jpg"></label>
                                          <label><img src="assets/images/icons/starnew.jpg"></label>
                                           <label><img src="assets/images/icons/starnew.jpg"></label>
                                           <label><img src="assets/images/icons/star_blank.jpg"></label> <br>
                                            <label><img src="assets/images/icons/hoteladviser1.png"></label> Excellent <span style="color:green;">4.5</span>/5 <span style="padding-left:5px;"><a href="#">Map</a></span>
                                          
 										
                                        </div>
                                         <div class="col-sm-12 padding0" >
                                             <label class="lablecalsicon"><i class="fa fa-wifi" aria-hidden="true" title="Wifi"></i></label>
                                           <label class="lablecalsicon"><i class="fa fa-cutlery" aria-hidden="true" title="Meal"></i></label>
                                           <label class="lablecalsicon"><i class="fa hrestaurant" aria-hidden="true" title="Retaurant"></i></label>
                                           <label class="lablecalsicon"><i class="fa hroomservice" aria-hidden="true" title="Room Services"></i></label>
                                             <label class="lablecalsicon"><i class="fa fa-wifi" aria-hidden="true" title="Wifi"></i></label>
                                           
                                          
                                           </div>
                                        
                                        
                                        
                                    </div>
                                </div>
                                 
                                 
                                 <div class="col-sm-6 padding0 priceregencygrid " style="margin-top:10px;">
                                    	<p style="margin-bottom:0; line-height:6px;" class="priceheadergrid">
                                        <i class="fa fa-inr" aria-hidden="true"></i>1500/-
                                       <span style="font-size:10px;"> Tour Price</span>
                                        </p>

                                    </div>
                                    
                                    <div class="col-sm-6 padding0" align="" style="margin-top:5px;">
                                 	
                                  <!--  <div class="col-sm-6 margin5 padding0">
                                    	<a href="#" class="compare_butgrid">Compare</a>
                                    </div>-->


                                    <div class="col-sm-12 margin5 padding0">
                                    	<a href="holiday_detailpage.html" class="select_butgrid">Select</a>
                                    </div>
                                    
                                 
                                 </div>
                                 
                                 
                                 </div>
                            </div>
                            
                            <div class="col-sm-3 col-md-3 col-xl-3 col-md-3grid redio grid">
                            		<div class="col-sm-12 padding12top">
                                <div class="col-sm-12 padding0"><img src="assets/images/hotelimage.png" width="100%"></div>
                                <div class="col-sm-12 padding0">
                                	<div class="col-sm-12 padding0">
                                    	<DIV class="col-sm-12 padding0" style="line-height:3px;">
                                    	<h3 class="h3new gridh3">Radisson Blu Hotel</h3>
                                        <label class="">New Delhi Dwarka</label>
                                        </DIV>
                                       <div class="col-sm-12 padding0 ">
                                        
                                        <label><img src="assets/images/icons/starnew.jpg"></label>
                                         <label><img src="assets/images/icons/starnew.jpg"></label>
                                          <label><img src="assets/images/icons/starnew.jpg"></label>
                                           <label><img src="assets/images/icons/starnew.jpg"></label>
                                           <label><img src="assets/images/icons/star_blank.jpg"></label> <br>
                                            <label><img src="assets/images/icons/hoteladviser1.png"></label> Excellent <span style="color:green;">4.5</span>/5 <span style="padding-left:5px;"><a href="#">Map</a></span>
                                          
 										
                                        </div>
                                         <div class="col-sm-12 padding0" >
                                             <label class="lablecalsicon"><i class="fa fa-wifi" aria-hidden="true" title="Wifi"></i></label>
                                           <label class="lablecalsicon"><i class="fa fa-cutlery" aria-hidden="true" title="Meal"></i></label>
                                           <label class="lablecalsicon"><i class="fa hrestaurant" aria-hidden="true" title="Retaurant"></i></label>
                                           <label class="lablecalsicon"><i class="fa hroomservice" aria-hidden="true" title="Room Services"></i></label>
                                             <label class="lablecalsicon"><i class="fa fa-wifi" aria-hidden="true" title="Wifi"></i></label>
                                           
                                          
                                           </div>
                                        
                                        
                                        
                                    </div>
                                </div>
                                 
                                 
                                 <div class="col-sm-6 padding0 priceregencygrid " style="margin-top:10px;">
                                    	<p style="margin-bottom:0; line-height:6px;" class="priceheadergrid">
                                        <i class="fa fa-inr" aria-hidden="true"></i>1500/-
                                       <span style="font-size:10px;"> Tour Price</span>
                                        </p>

                                    </div>
                                    
                                    <div class="col-sm-6 padding0" align="" style="margin-top:5px;">
                                 	
                                  <!--  <div class="col-sm-6 margin5 padding0">
                                    	<a href="#" class="compare_butgrid">Compare</a>
                                    </div>-->


                                    <div class="col-sm-12 margin5 padding0">
                                    	<a href="holiday_detailpage.html" class="select_butgrid">Select</a>
                                    </div>
                                    
                                 
                                 </div>
                                 
                                 
                                 </div>
                            </div>
                            
                            <div class="col-sm-3 col-md-3 col-xl-3 col-md-3grid redio grid">
                            		<div class="col-sm-12 padding12top">
                                <div class="col-sm-12 padding0"><img src="assets/images/hotelimage.png" width="100%"></div>
                                <div class="col-sm-12 padding0">
                                	<div class="col-sm-12 padding0">
                                    	<DIV class="col-sm-12 padding0" style="line-height:3px;">
                                    	<h3 class="h3new gridh3">Radisson Blu Hotel</h3>
                                        <label class="">New Delhi Dwarka</label>
                                        </DIV>
                                       <div class="col-sm-12 padding0 ">
                                        
                                        <label><img src="assets/images/icons/starnew.jpg"></label>
                                         <label><img src="assets/images/icons/starnew.jpg"></label>
                                          <label><img src="assets/images/icons/starnew.jpg"></label>
                                           <label><img src="assets/images/icons/starnew.jpg"></label>
                                           <label><img src="assets/images/icons/star_blank.jpg"></label> <br>
                                            <label><img src="assets/images/icons/hoteladviser1.png"></label> Excellent <span style="color:green;">4.5</span>/5 <span style="padding-left:5px;"><a href="#">Map</a></span>
                                          
 										
                                        </div>
                                         <div class="col-sm-12 padding0" >
                                             <label class="lablecalsicon"><i class="fa fa-wifi" aria-hidden="true" title="Wifi"></i></label>
                                           <label class="lablecalsicon"><i class="fa fa-cutlery" aria-hidden="true" title="Meal"></i></label>
                                           <label class="lablecalsicon"><i class="fa hrestaurant" aria-hidden="true" title="Retaurant"></i></label>
                                           <label class="lablecalsicon"><i class="fa hroomservice" aria-hidden="true" title="Room Services"></i></label>
                                             <label class="lablecalsicon"><i class="fa fa-wifi" aria-hidden="true" title="Wifi"></i></label>
                                           
                                          
                                           </div>
                                        
                                        
                                        
                                    </div>
                                </div>
                                 
                                 
                                 <div class="col-sm-6 padding0 priceregencygrid " style="margin-top:10px;">
                                    	<p style="margin-bottom:0; line-height:6px;" class="priceheadergrid">
                                        <i class="fa fa-inr" aria-hidden="true"></i>1500/-
                                       <span style="font-size:10px;"> Tour Price</span>
                                        </p>

                                    </div>
                                    
                                    <div class="col-sm-6 padding0" align="" style="margin-top:5px;">
                                 	
                                  <!--  <div class="col-sm-6 margin5 padding0">
                                    	<a href="#" class="compare_butgrid">Compare</a>
                                    </div>-->


                                    <div class="col-sm-12 margin5 padding0">
                                    	<a href="holiday_detailpage.html" class="select_butgrid">Select</a>
                                    </div>
                                    
                                 
                                 </div>
                                 
                                 
                                 </div>
                            </div>
                            
                            <div class="col-sm-3 col-md-3 col-xl-3 col-md-3grid redio grid">
                            		<div class="col-sm-12 padding12top">
                                <div class="col-sm-12 padding0"><img src="assets/images/hotelimage.png" width="100%"></div>
                                <div class="col-sm-12 padding0">
                                	<div class="col-sm-12 padding0">
                                    	<DIV class="col-sm-12 padding0" style="line-height:3px;">
                                    	<h3 class="h3new gridh3">Radisson Blu Hotel</h3>
                                        <label class="">New Delhi Dwarka</label>
                                        </DIV>
                                       <div class="col-sm-12 padding0 ">
                                        
                                        <label><img src="assets/images/icons/starnew.jpg"></label>
                                         <label><img src="assets/images/icons/starnew.jpg"></label>
                                          <label><img src="assets/images/icons/starnew.jpg"></label>
                                           <label><img src="assets/images/icons/starnew.jpg"></label>
                                           <label><img src="assets/images/icons/star_blank.jpg"></label> <br>
                                            <label><img src="assets/images/icons/hoteladviser1.png"></label> Excellent <span style="color:green;">4.5</span>/5 <span style="padding-left:5px;"><a href="#">Map</a></span>
                                          
 										
                                        </div>
                                         <div class="col-sm-12 padding0" >
                                             <label class="lablecalsicon"><i class="fa fa-wifi" aria-hidden="true" title="Wifi"></i></label>
                                           <label class="lablecalsicon"><i class="fa fa-cutlery" aria-hidden="true" title="Meal"></i></label>
                                           <label class="lablecalsicon"><i class="fa hrestaurant" aria-hidden="true" title="Retaurant"></i></label>
                                           <label class="lablecalsicon"><i class="fa hroomservice" aria-hidden="true" title="Room Services"></i></label>
                                             <label class="lablecalsicon"><i class="fa fa-wifi" aria-hidden="true" title="Wifi"></i></label>
                                           
                                          
                                           </div>
                                        
                                        
                                        
                                    </div>
                                </div>
                                 
                                 
                                 <div class="col-sm-6 padding0 priceregencygrid " style="margin-top:10px;">
                                    	<p style="margin-bottom:0; line-height:6px;" class="priceheadergrid">
                                        <i class="fa fa-inr" aria-hidden="true"></i>1500/-
                                       <span style="font-size:10px;"> Tour Price</span>
                                        </p>

                                    </div>
                                    
                                    <div class="col-sm-6 padding0" align="" style="margin-top:5px;">
                                 	
                                  <!--  <div class="col-sm-6 margin5 padding0">
                                    	<a href="#" class="compare_butgrid">Compare</a>
                                    </div>-->


                                    <div class="col-sm-12 margin5 padding0">
                                    	<a href="holiday_detailpage.html" class="select_butgrid">Select</a>
                                    </div>
                                    
                                 
                                 </div>
                                 
                                 
                                 </div>
                            </div>
                            </div>
                           
                        </div>
                    </div>
                </div><!--/.services-->
            </div><!--/.row-->    
        </div><!--/.container--><br>


    </section>

    <ng-template #noresult>
      <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="error-template">
                    <h1>
                        Oops!</h1>
                    <h2>
                      No Hotel Available For This Search</h2>
                    
                </div>
            </div>
        </div>
    </div>
    </ng-template>