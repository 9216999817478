import { Component, OnInit } from '@angular/core';
import { ContactService } from '../../services/contact.service';
import { HotelService } from '../../services/hotel.service';
declare var $: any;

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  searchDataObj: any;
  hotelDataObj: any;
  roomDataObj: any;
  hotelRoomDetails: any;
  passengerDetails: any;
  bookingData: any;
  ipAddress: any;
  leadPassengerDetails: any;
  isShowInvoce: boolean;
  hotelListData: any;
  loginuserData: any;
  isShowError: boolean;

  constructor(private hotelService : HotelService,private contactService:ContactService,) { }

  ngOnInit(): void {
    this.hotelListData = JSON.parse(localStorage.getItem('hotelListData'));
    this.loginuserData = JSON.parse(localStorage.getItem('loginuserData'));
    this.searchDataObj = JSON.parse(localStorage.getItem('userSearchData'));
    this.hotelDataObj = JSON.parse(localStorage.getItem('hotelData'));
    this.roomDataObj =  JSON.parse(localStorage.getItem('roomData'));
    this.hotelRoomDetails =  JSON.parse(localStorage.getItem('roomData'));
    this.passengerDetails  =  JSON.parse(localStorage.getItem('passengerDetails'));
    this.bookingData  =   JSON.parse(localStorage.getItem('BookingData'));
    this.contactService.getIPAddress().subscribe((res:any) =>{
      this.ipAddress = res.ip;
      let voucherDetails ={
        'EndUserIp':this.ipAddress,
        'BookingId':  this.bookingData.BookResult && this.bookingData.BookResult.BookingId
      }
      console.log(this.passengerDetails);
      // this.hotelService.generateVoucher(voucherDetails).subscribe((data:any) =>{
      //   if(data.responsedata.data.GenerateVoucherResult.HotelBookingStatus ==="Confirmed"){
      //     this.isShowInvoce =true;
      //   } else {
      //     this.isShowInvoce =false;
      //   }
      // });
      let roomBookingDetails =[
        {
          "RoomIndex": this.hotelRoomDetails.RoomIndex,
          "RoomTypeCode": this.hotelRoomDetails.RoomTypeCode,
          "RoomTypeName": this.hotelRoomDetails.RoomTypeName,
          "RatePlanCode": this.hotelRoomDetails.RatePlanCode,
          "BedTypeCode": null,
          "SmokingPreference": 0,
          "Supplements": this.hotelRoomDetails.Supplements,
          "Price": {
            "CurrencyCode": this.hotelRoomDetails.Price.CurrencyCode,
            "RoomPrice": this.hotelRoomDetails.Price.RoomPrice,
            "Tax":this.hotelRoomDetails.Price.Tax,
            "ExtraGuestCharge":this.hotelRoomDetails.Price.ExtraGuestCharge,
            "ChildCharge":this.hotelRoomDetails.Price.ChildCharge,
            "OtherCharges": this.hotelRoomDetails.Price.OtherCharges,
            "Discount":this.hotelRoomDetails.Price.Discount,
            "PublishedPrice": this.hotelRoomDetails.Price.PublishedPrice,
            "PublishedPriceRoundedOff": this.hotelRoomDetails.Price.PublishedPriceRoundedOff,
            "OfferedPrice": this.hotelRoomDetails.Price.OfferedPrice,
            "OfferedPriceRoundedOff": this.hotelRoomDetails.Price.OfferedPriceRoundedOff,
            "AgentCommission": this.hotelRoomDetails.Price.AgentCommission,
            "AgentMarkUp":this.hotelRoomDetails.Price.AgentMarkUp,
            "ServiceTax": this.hotelRoomDetails.Price.ServiceTax,
            "TDS": this.hotelRoomDetails.Price.TDS
          },
          "HotelPassenger": this.passengerDetails
        }
      ]
      let bookingData ={
        'ResultIndex': this.hotelDataObj.ResultIndex,
        'HotelCode': this.hotelDataObj.HotelCode,
        'HotelName':  this.hotelDataObj.HotelName,
        'GuestNationality': "IN",
        'NoOfRooms': this.searchDataObj.NoOfRooms,
        'ClientReferenceNo': "0",
        'IsVoucherBooking': "true",
        'HotelRoomsDetails': roomBookingDetails,
        'EndUserIp': this.ipAddress,
        'CheckInDate': this.searchDataObj.CheckInDate,
        'CheckOutDate': this.searchDataObj.checkOutDate,
        'NoOfNights': this.searchDataObj.NoOfNights,
        'LastCancellationDate': this.roomDataObj.LastCancellationDate,
        'User':this.loginuserData.user_id,
        'TraceId': this.hotelListData.TraceId,
      }
    console.log(bookingData);
      this.hotelService.hotelBooking(bookingData).subscribe((data:any) =>{
        debugger;
       localStorage.setItem('BookingData',JSON.stringify(data.responsedata.data));
       if(data.responsedata.data.BookResult.Error.ErrorCode === 0){
        this.isShowInvoce =true;
       } else {
        this.isShowInvoce =true;
        this.isShowError =true;
         alert('Can not book this hotel due to some issue.');
       }
       
      });
    });
    this.leadPassengerDetails = this.passengerDetails.filter(passenger =>{
      if(passenger.LeadPassenger) {
        return passenger;
      }
    });

  }

  showStarRating(rating) {
    return Array(rating).fill(0).map((x,i)=>i);
  }

  showInvoice() {
    $("#myModal-invoice").modal('show');
  }

}
