<section id="main-slider" style="padding:0 0;">
    <div class="formbgnepal">

        <div class="container">
            <div class="row login-div margin-top10">
                <div class="col-sm-12">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-4 margin-auto">
                        <div class=" box-body table-responsive box-shadow col-sm-12 no-padding">
                            <div class="col-lg-12" style="color: red; padding:5px;"></div>
                            <div class="col-sm-12">
                                <form [formGroup]="makepaymentform" name="ticket-form" id="ticket-form">
                                    <input type="hidden" name="ticket_type" value="">         
                                    
                                    <div class="col-sm-12 no-padding my-account">
                                    <h2>Find Your Booking</h2>
                                    <div class="form-group widthpercent100 pull-left">
                                        <label class="col-sm-12 margin-bot5px">Booking ID</label>
                                        <div class="col-sm-12 margin-top-5">
                                            <input type="text" name="booking_no" id="booking-no" placeholder="Ticket No" class="form-control" formControlName="bookingid" maxlength="35" [ngClass]="{ 'is-invalid': f.bookingid.touched && f.bookingid.errors }">
                                            <div *ngIf="f.bookingid.touched && f.bookingid.errors" class="invalid-feedback">
                                                <div *ngIf="f.bookingid.errors.required">Booking ID is required</div>
                                            </div>
                                        </div>
                                     </div>
    
                                    <div class="form-group widthpercent100 pull-left">
                                        <label class="col-sm-12">Mobile No.</label>
                                        <div class="col-sm-12 margin-top-5"><input type="text" name="mobile_no" id="mobile-no" placeholder="Mobile No." class="form-control" maxlength="10"formControlName="mobilenumber"  [ngClass]="{ 'is-invalid': f.mobilenumber.touched && f.mobilenumber.errors }">
                                            <div *ngIf="f.mobilenumber.touched && f.mobilenumber.errors" class="invalid-feedback">
                                                <div *ngIf="f.mobilenumber.errors.required">Mobile Number is required</div>
                                            </div>
                                        </div>
                                       </div>
    
                                   
    
                                    <div class="form-group widthpercent100 pull-left">
                                        <div class="col-sm-12 margin-top-5">
                                            <input type="submit" [disabled]="makepaymentform.invalid" name="ticket_submit" id="ticket-submit" class="publish_all btn btn-danger text-white" value="Submit"(click)="onSubmit()">                                    </div>
                                    </div>
                                </div>
    
                                </form>                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </section>
